import React from 'react';
import PropTypes from 'prop-types';
import classNameCompose from '@utils/classname-compose';
import styles from './UiTag.module.scss';

export enum UiTagType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEFAULT = 'default',
  DEFAULT_DARKER = 'defaultDarker',
  DASHED = 'dashed',
  JOB = 'job',
  PURPLE = 'purple',
  WHITE = 'white',
}

export interface UiTagProps {
  type: `${UiTagType}`;
  icon?: React.ReactNode;
  className?: string;
  children?: string | React.ReactNode;
  fontTransform?: string;
}

const UiTag: React.FC<UiTagProps> = ({ type, icon, children, className, fontTransform = 'uppercase' }) => {
  const Icon = icon as any;

  return (
    <div className={classNameCompose(styles[type], className!)} data-testid={`ui-tag-${type}`}>
      {icon && <Icon className={styles.icon} data-testid={`ui-tag-icon-${type}`} />}
      <span className={`${fontTransform} main-body-text ${styles.text}`}>{children || 'N/A'}</span>
    </div>
  );
};

UiTag.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.any,
  type: PropTypes.oneOf(Object.values(UiTagType)).isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

UiTag.defaultProps = {
  icon: undefined,
  className: '',
  children: undefined,
};

export default UiTag;
