import React from 'react';
import { Alert, AlertProps } from 'antd';
import PropTypes from 'prop-types';
import SuccessIcon from '@assets/svgs/success-icon-with-background.svg';
import ErrorIcon from '@assets/svgs/error-icon-with-background.svg';
import InfoIcon from '@assets/svgs/info-icon-with-background.svg';
import WarningIcon from '@assets/svgs/warning-icon-with-background.svg';
import StateEnum from '@constants/shared/state.enum';

export interface UiAlertProps extends AlertProps {
  type?: 'success' | 'info' | 'warning' | 'error';
  className?: string;
}

const UiAlert: React.FC<UiAlertProps> = ({ type, className, ...rest }) => {
  const icon =
    (type === StateEnum.SUCCESS && <SuccessIcon />) ||
    (type === StateEnum.ERROR && <ErrorIcon />) ||
    (type === StateEnum.INFO && <InfoIcon />) ||
    (type === StateEnum.WARNING && <WarningIcon />);
  return <Alert {...rest} className={`ui-alert ${className}`} showIcon icon={icon} type={type} />;
};

UiAlert.propTypes = {
  type: PropTypes.oneOf(Object.values(StateEnum)),
  className: PropTypes.string,
};

UiAlert.defaultProps = {
  type: 'info',
  className: '',
};

export default UiAlert;
