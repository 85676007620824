import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import KarieraMiniIcon from '@assets/svgs/kariera_mini_logo.svg';
import KarieraFullIcon from '@assets/svgs/people.svg';
import BurgerMenuIcon from '@assets/svgs/burger-menu-icon.svg';
import SearchIcon from '@assets/svgs/search-icon.svg';
import NavDropdown from '@components/header/nav-dropdown/NavDropdown';
import UiDrawer from '@components/ui/drawer/UiDrawer';
import NavMenuOptions from '@components/header/nav-menu-options/NavMenuOptions';
import { useRouter } from 'next/router';
import EmployeeSearchBar from '@components/ka-ui/employee-search-bar/EmployeeSearchBar';
import usePath from '@hooks/usePath';
import RoutesEnum from '@constants/routes.enum';
import classNameCompose from '@utils/classname-compose';
import useOnClickOutside from '@hooks/useOnClickOutside';
import GettingStartedMenu from '@components/header/getting-started-menu/GettingStartedMenu';
import useAccountSuspended from '@hooks/useAccountSuspended';
import { AppContext } from '@contexts/AppContext';
import ContactDropdown from '../contact-dropdown/ContactDropdown';
import styles from './AdminNavBar.module.scss';
import NotificationsDropdown from '../notifications-dropdown/NotificationsDropdown';
import QuickActions from './quick-actions/QuickActions';

type AdminNavBarProps = {
  setVisibleMenu?: (value: boolean) => void;
};

const AdminNavBar: React.FC<AdminNavBarProps> = ({ setVisibleMenu }) => {
  const { appState } = useContext(AppContext);
  const { bannerIsVisible } = appState;
  const drawerBackgroundColor = '#F7F9FC';

  const accountSuspended = useAccountSuspended();

  const [searchVisible, setSearchVisible] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const router = useRouter();
  const { getPathWithPrefix } = usePath();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const goToHomePage = () => {
    if (accountSuspended) return;
    router.push(
      {
        pathname: getPathWithPrefix(RoutesEnum.AMDIN_HOME),
      },
      undefined,
      { shallow: true },
    );
  };

  const searchBarRef = React.createRef<HTMLDivElement>();
  const onClickOutside = useCallback(() => setSearchVisible(false), []);
  useOnClickOutside(searchBarRef, onClickOutside);

  const mobileView = () => {
    return (
      <>
        {searchVisible && (
          <div className={classNameCompose(styles.searchContainer, styles.mobile)}>
            <EmployeeSearchBar
              className={styles.search}
              inputSize="small"
              ref={searchBarRef}
              onItemSelect={() => setSearchVisible(false)}
            />
          </div>
        )}
        {!searchVisible && (
          <div className={`${styles.container} ${styles.mobile}`}>
            <>
              <div className={styles.logos}>
                <KarieraMiniIcon onClick={goToHomePage} className={styles.logo} data-testid="mobile-logo" />
                <BurgerMenuIcon
                  className={styles.burgerLogo}
                  data-testid="burger-button"
                  onClick={() => setVisibleMenu?.(true)}
                />
                <button
                  onClick={() => setSearchVisible(true)}
                  className={classNameCompose(styles.searchBtn, 'no-button-styles')}
                  type="button"
                >
                  <SearchIcon className={styles.searchIcon} />
                </button>
              </div>
              <div className={styles.actions}>
                <QuickActions />
                <ContactDropdown />
                <GettingStartedMenu />
                <NotificationsDropdown />
                <NavDropdown />
              </div>
            </>
          </div>
        )}
      </>
    );
  };

  const desktopView = () => {
    return (
      <div className={`${styles.container} ${styles.desktop}`}>
        <div className={styles.logos}>
          <KarieraMiniIcon onClick={goToHomePage} className={styles.logo} data-testid="mobile-logo" />
          <KarieraFullIcon onClick={goToHomePage} className={styles.logoDesktop} data-testid="desktop-logo" />
          <BurgerMenuIcon
            className={classNameCompose(styles.burgerLogo, styles.burgerLogoDesktop)}
            data-testid="burger-button"
            onClick={() => setVisibleMenu?.(true)}
          />
        </div>
        <div className={styles.searchContainer}>
          <EmployeeSearchBar inputSize="small" className={styles.search} />
        </div>
        <div className={styles.actions}>
          <QuickActions />
          <ContactDropdown />
          <GettingStartedMenu />
          <NotificationsDropdown />
          <NavDropdown />
        </div>
      </div>
    );
  };

  return (
    <header className={classNameCompose(styles.header, bannerIsVisible ? styles.withBanner : '')}>
      <div className={styles.compWrap}>
        {mobileView()}
        {desktopView()}
        <UiDrawer
          open={openDrawer}
          bodyStyle={{ backgroundColor: drawerBackgroundColor }}
          closable={false}
          placement="top"
          onClose={toggleDrawer}
          height="auto"
          zIndex={3}
          rootClassName={`${styles.drawer} ${openDrawer ? styles.openDrawer : ''}`}
          data-testid="mobile-drawer"
        >
          <NavMenuOptions />
        </UiDrawer>
      </div>
    </header>
  );
};

AdminNavBar.propTypes = {
  setVisibleMenu: PropTypes.func,
};

AdminNavBar.defaultProps = {
  setVisibleMenu: () => {},
};

export default AdminNavBar;
