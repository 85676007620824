/* eslint-disable no-nested-ternary */
import * as React from 'react';

type UserColleaguesContextProps = {
  employeeStack?: string[];
  lastOfStack?: string;
  popEmployee: () => string | undefined;
  pushEmployee: (employeeId: string) => void;
  clearStack: () => void;
};

const UserColleaguesContext = React.createContext<UserColleaguesContextProps>({
  employeeStack: [],
  lastOfStack: undefined,
  popEmployee: () => undefined,
  pushEmployee: () => {},
  clearStack: () => {},
});

const UserColleaguesProvider = ({ children }: { children: JSX.Element }) => {
  const [employeeStack, setEmployeeStack] = React.useState<string[]>([]);

  const popEmployee = () => {
    const tempStack = employeeStack;
    const popValue = tempStack.pop();
    setEmployeeStack([...tempStack]);
    return popValue;
  };
  const pushEmployee = (employeeId: string) => setEmployeeStack([...employeeStack, employeeId]);
  const clearStack = () => setEmployeeStack([]);
  const lastOfStack = employeeStack.length > 0 ? employeeStack[employeeStack.length - 1] : undefined;

  return (
    <UserColleaguesContext.Provider value={{ employeeStack, popEmployee, pushEmployee, clearStack, lastOfStack }}>
      {children}
    </UserColleaguesContext.Provider>
  );
};

export { UserColleaguesProvider, UserColleaguesContext };
