import { Dispatch, DispatchWithoutAction, SetStateAction, useEffect, useReducer, useState } from 'react';
import useNotification from '@hooks/notification/useNotification';
import { useTranslation } from 'next-i18next';
import StateEnum from '@constants/shared/state.enum';

type FetchDataProps<T> =
  | {
      data: T;
      loading: false;
      firstLoad: boolean;
      toggleRevalidate: DispatchWithoutAction;
      mutate: Dispatch<SetStateAction<T | undefined>>;
    }
  | {
      data: T | undefined;
      loading: true;
      firstLoad: boolean;
      toggleRevalidate: DispatchWithoutAction;
      mutate: Dispatch<SetStateAction<T | undefined>>;
    };

function useFetchData<T extends any>(fetcher: any, onError?: Function): FetchDataProps<T> {
  const [data, setData] = useState<T>();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [revalidate, toggleRevalidate] = useReducer((p) => !p, true);
  const { t: translate } = useTranslation();

  const [generalErrorNotification] = useNotification(
    StateEnum.ERROR,
    translate('notification:status.error'),
    translate('common:messages.error.something_went_wrong'),
  );

  useEffect(() => {
    let active: boolean = true;
    setLoading(true);
    setFirstLoad(!data);
    const fetchData = async () => {
      try {
        const response = await fetcher();

        if (active) {
          setData(response);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        if (onError) {
          onError();
        } else {
          generalErrorNotification();
        }
      }
    };
    fetchData();
    return () => {
      active = false;
    };
  }, [fetcher, revalidate]);

  return { data, loading, firstLoad, toggleRevalidate, mutate: setData } as FetchDataProps<T>;
}

export default useFetchData;
