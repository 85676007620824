import { hrmsInstance, hrmsBackEndUrl } from '@utils/http-client';
import {
  EmployeePersonalDetails,
  UpdateEmployeePersonalDetails,
  EmployeeEmploymentDetails,
  EmployeeSendOnboardingResponse,
} from './type';

const onboardingBaseUrl = `${hrmsBackEndUrl}/users/employees`;
const employeesBaseUrl = `${hrmsBackEndUrl}/employees`;

export const finishOnboardingDocuments = (): Promise<void> => {
  return hrmsInstance.post(`${onboardingBaseUrl}/finished-documents`);
};

export const getPersonalDetails = (): Promise<EmployeePersonalDetails> => {
  return hrmsInstance.get(`${onboardingBaseUrl}/personal-details`);
};

export const updatePersonalDetails = (body: UpdateEmployeePersonalDetails): Promise<UpdateEmployeePersonalDetails> => {
  return hrmsInstance.put(`${onboardingBaseUrl}/personal-details`, body);
};

export const getEmploymentDetails = (): Promise<EmployeeEmploymentDetails> => {
  return hrmsInstance.get(`${onboardingBaseUrl}/employment-details`);
};

export const updateEmploymentDetails = (body: EmployeeEmploymentDetails): Promise<EmployeePersonalDetails> => {
  return hrmsInstance.put(`${onboardingBaseUrl}/employment-details`, body);
};

export const sendOnboardingEmail = (employeeId: string): Promise<EmployeeSendOnboardingResponse> => {
  return hrmsInstance.post(`${employeesBaseUrl}/${employeeId}/onboarding`);
};

export const updateOnboardingWelcomeStep = (): Promise<void> => {
  return hrmsInstance.post(`${onboardingBaseUrl}/read-welcome-message`);
};

export const updateOnboardingFinishedStep = (): Promise<void> => {
  return hrmsInstance.post(`${onboardingBaseUrl}/finished-onboarding`);
};
