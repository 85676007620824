enum EmployeeStatus {
  'pending' = 0,
  'onboarding' = 1,
  'active' = 2,
  'inactive' = 3,
  'deleted' = 4,
}

export const employeeStatusNamesWithOrder = (order: string[]) => {
  return Object.keys(EmployeeStatus)
    .filter((key) => key !== '4')
    .map((key: any) => EmployeeStatus[key])
    .sort((a, b) => order.indexOf(a) - order.indexOf(b))
    .filter((value) => typeof value === 'string') as string[];
};

export const employeeStatusValue = (status: string) =>
  Object.values(EmployeeStatus)[Object.keys(EmployeeStatus).indexOf(status)].toString();

export const employeeStatusKey = (status: number) =>
  Object.keys(EmployeeStatus)[Object.values(EmployeeStatus).indexOf(status)];

export default EmployeeStatus;
