import GroupPositions from '@models/position/GroupPositions';
import { hrmsInstance, hrmsBackEndUrl } from '@utils/http-client';
import { AutocompletePositions, CheckAvailability } from '@models/position';

const positions = `${hrmsBackEndUrl}/positions`;

export const createNewPosition = (title: string, reports_to?: string, department_id?: string): Promise<void> => {
  return hrmsInstance.post(`${positions}`, { title, reports_to, department_id });
};

export const getAllPositions = (page: number, psize: number): Promise<GroupPositions> => {
  return hrmsInstance.get(`${positions}/title-groups-list`, { params: { page, psize } });
};

export const reportToAutocomplete = (q: string): Promise<AutocompletePositions> => {
  return hrmsInstance.get(`${hrmsBackEndUrl}/employees/autocomplete-search`, { params: { q } });
};

export const checkPositionAvailability = (
  title: string,
  start_date: string,
  did?: string,
): Promise<CheckAvailability> => {
  return hrmsInstance.get(`${positions}/check-availability`, { params: { title, start_date, did } });
};
