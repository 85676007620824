import { AuthContext } from '@contexts/AuthContext';
import { SubscriptionStatus } from '@services/tenants/type';
import { useContext, useMemo } from 'react';

const useAccountSuspended = () => {
  const { user } = useContext(AuthContext);
  const accountSuspended = useMemo(
    () => user?.sub_status === SubscriptionStatus.SUSPENDED || user?.sub_status === SubscriptionStatus.TRIAL_SUSPENDED,
    [user],
  );

  return accountSuspended;
};

export default useAccountSuspended;
