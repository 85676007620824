import React, { useContext } from 'react';
import UiButton from '@components/ui/button/UiButton';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { AuthContext } from '@contexts/AuthContext';
import usePath from '@hooks/usePath';
import { useRouter } from 'next/router';
import useNotification from '@hooks/notification/useNotification';
import { Modal } from 'antd';
import WarningIconWithBackground from '@assets/svgs/warning-icon-with-background.svg';
import styles from '@components/layout/subscription-banner/SubscriptionBanner.module.scss';
import { getHighlightedText } from '@utils/text-highlighter';
import UiModalSizesEnum from '@constants/modal/ui-modal.sizes.enum';
import { startFresh } from '@services/tenants/tenants-http.service';
import { UserTypeEnum } from '@models/user/auth';
import RoutesEnum from '@constants/routes.enum';
import eventBus, { CustomEventTypes } from '@services/EventBus';

const FreeTrialCountdown = () => {
  const { useTypedTranslation } = makeTypedTranslation('common', 'notification');
  const { t: translate } = useTypedTranslation('common');
  const { t: translateNotification } = useTypedTranslation('notification');
  const { user, setUser } = useContext(AuthContext);

  const { getPathWithPrefix } = usePath();
  const router = useRouter();

  const [successNotification] = useNotification('success', translateNotification('status.success'));

  const startFreshConfirm = () => {
    Modal.confirm({
      className: styles.modal,
      autoFocusButton: null,
      title: translate('subscription.start_fresh.title'),
      icon: <WarningIconWithBackground className={styles.warningIcon} />,
      content: getHighlightedText(
        translate('subscription.start_fresh.content').replace(
          '{bold}',
          translate('subscription.start_fresh.bold_content'),
        ),
        translate('subscription.start_fresh.bold_content'),
      ),
      okText: translate('subscription.start_fresh.ok_button'),
      cancelText: translate('subscription.start_fresh.cancel_button'),
      width: UiModalSizesEnum.SMALL,
      okButtonProps: { size: 'small', className: 'ui-button' },
      cancelButtonProps: { size: 'small', className: 'ui-button' },
      onOk: async () => {
        await startFresh();
        setUser({ ...user, uses_demo_data: false, type: UserTypeEnum.EMPLOYER });
        successNotification(undefined, translate('subscription.start_fresh.data_deleted'));
        router.push(getPathWithPrefix(RoutesEnum.ADMIN_GETTING_STARTED));
        eventBus.getInstance().dispatch(CustomEventTypes.EMPLOYEES_LIST_UPDATED);
      },
    });
  };

  return (
    <>
      {user?.uses_demo_data ? (
        <h5 className="h5">{translate('subscription.play_with_our_data')}</h5>
      ) : (
        <h5 className="h5">{translate('subscription.delete_existing_data')}</h5>
      )}
      <UiButton type="primary" size="xsmall" onClick={startFreshConfirm} data-testid="start-fresh-btn">
        {translate('subscription.start_fresh.title')}
      </UiButton>
      {user?.subscription?.days_remaining === 0 ? (
        <h5 className="h5">{translate('subscription.free_trial_today')}</h5>
      ) : (
        <h5 className="h5">{translate('subscription.free_trial', { number: user?.subscription?.days_remaining })}</h5>
      )}
    </>
  );
};

export default FreeTrialCountdown;
