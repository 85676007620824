import React from 'react';
import Avatar from '@components/ka-ui/avatar/Avatar';
import EmployeeModel from '@models/employee/model';
import PropTypes from 'prop-types';
import EmployeeStatus from '@constants/employer-status/employer-status.enum';
import { getHighlightedText } from '@utils/text-highlighter';
import styles from './EmployeeAutoCompleteResult.module.scss';

type EmployeeAutoCompleteResultProps = {
  employee: EmployeeModel;
  searchTerm: string;
};

const EmployeeAutoCompleteResult: React.FC<EmployeeAutoCompleteResultProps> = ({ employee, searchTerm }) => {
  const result = getHighlightedText(
    [employee.fullname, employee.position_title, employee.department_title]
      .filter((str) => typeof str === 'string' && str.length > 0)
      .join(' | '),
    searchTerm,
  );
  return (
    <div className={styles.componentWrap} data-testid={employee.id}>
      <span className={styles.avatarWrap}>
        <Avatar image={employee.img_url} status={employee.status} className={styles.avatar} />
      </span>
      {result}
    </div>
  );
};

EmployeeAutoCompleteResult.propTypes = {
  // @ts-ignore
  employee: PropTypes.shape({
    id: PropTypes.string,
    fullname: PropTypes.string,
    email: PropTypes.string,
    position_id: PropTypes.string,
    position_title: PropTypes.string,
    department_id: PropTypes.string,
    department_title: PropTypes.string,
    hire_date: PropTypes.string,
    end_date: PropTypes.string,
    img_url: PropTypes.string,
    status: PropTypes.oneOf<EmployeeStatus>([
      EmployeeStatus.pending,
      EmployeeStatus.onboarding,
      EmployeeStatus.active,
      EmployeeStatus.inactive,
    ]),
  }).isRequired,
  searchTerm: PropTypes.string.isRequired,
};

export default EmployeeAutoCompleteResult;
