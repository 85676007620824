import React from 'react';
import KarieraMiniIcon from '@assets/svgs/kariera_mini_logo.svg';
import KarieraFullIcon from '@assets/svgs/people.svg';
import NavDropdown from '@components/header/nav-dropdown/NavDropdown';
import { useRouter } from 'next/router';
import usePath from '@hooks/usePath';
import RoutesEnum from '@constants/routes.enum';
import NotificationsDropdown from '../notifications-dropdown/NotificationsDropdown';
import styles from './EmployeeNavBar.module.scss';

const EmployeeNavBar: React.FC = () => {
  const router = useRouter();
  const { getPathWithPrefix } = usePath();

  const goToHomePage = () => {
    if (router.pathname.includes('onboarding')) return;
    router.push(
      {
        pathname: getPathWithPrefix(RoutesEnum.EMPLOYEE_HOME),
      },
      undefined,
      { shallow: true },
    );
  };

  const mobileView = () => {
    return (
      <>
        <div
          className={`${styles.container} ${
            router.pathname.includes('onboarding') ? styles.onboardingMobile : styles.mobile
          }`}
        >
          <div className={styles.logos}>
            <KarieraMiniIcon onClick={goToHomePage} className={styles.logo} data-testid="mobile-logo" />
          </div>
          <div id="searchbar-portal-mobile" className={styles.colleaguesSearchContainer} />
          <div className={styles.actions}>
            {!router.pathname.includes('onboarding') && <NotificationsDropdown />}
            <NavDropdown />
          </div>
        </div>
      </>
    );
  };

  const desktopView = () => {
    return (
      <div
        className={`${styles.container} ${
          router.pathname.includes('onboarding') ? styles.onboardingDesktop : styles.desktop
        }`}
      >
        <div className={styles.logos}>
          <KarieraMiniIcon onClick={goToHomePage} className={styles.logo} data-testid="mobile-logo" />
          <KarieraFullIcon onClick={goToHomePage} className={styles.logoDesktop} data-testid="desktop-logo" />
        </div>
        <div id="searchbar-portal" className={styles.colleaguesSearchContainer} />
        <div className={styles.actions}>
          {!router.pathname.includes('onboarding') && <NotificationsDropdown />}
          <NavDropdown />
        </div>
      </div>
    );
  };

  return (
    <header className={styles.header}>
      <div className={styles.compWrap}>
        {mobileView()}
        {desktopView()}
      </div>
    </header>
  );
};

export default EmployeeNavBar;
