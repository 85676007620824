import React from 'react';
import { Layout } from 'antd';
import LogoWhite from '@assets/svgs/logo-white.svg';
import { AuthLayoutProvider } from '@contexts/AuthLayoutContext';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { useRouter } from 'next/router';
import usePath from '@hooks/usePath';
import AccountSetupLayout from '@components/layout/auth-layout/account-setup-layout/AccountSetupLayout';
import RoutesEnum from '@constants/routes.enum';
import styles from './AuthLayout.module.scss';
import AuthLayoutError from './auth-layout-error/AuthLayoutError';

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const router = useRouter();
  const { appPathPrefix } = usePath();
  const path = router.asPath.replace(appPathPrefix, '');

  return (
    <AuthLayoutProvider>
      <Layout className={styles.authLayoutWrap}>
        {path === RoutesEnum.ADMIN_ACCOUNT_SETUP || path === RoutesEnum.ADMIN_SIGNUP ? (
          <AccountSetupLayout>{children}</AccountSetupLayout>
        ) : (
          <div className={styles.layoutInfoContainer}>
            <section className={styles.layoutContentWrap}>
              <div className={styles.infoWrap}>
                <LogoWhite className={styles.logo} />
                <span className="h2">{translate('authLayout.slogan_part_1')}</span>
                <span className="h4">{translate('authLayout.slogan_part_2')}</span>
              </div>
              <div className={styles.layoutContent}>
                <AuthLayoutError />
                {children}
              </div>
            </section>
          </div>
        )}
      </Layout>
    </AuthLayoutProvider>
  );
};

export default AuthLayout;
