/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { UserAuthType, UserTypeEnum } from '@models/user/auth';
import { EmployeeLoginDto } from '@models/user';
import { useRouter } from 'next/router';
import { login, logout as adminLogout, userLogin, userLogout } from '@services/auth/auth-http-service';
import CompanyType from '@models/company';
import { LoginDto } from '@services/auth/type';
import RoutesEnum from '@constants/routes.enum';
import usePath from '@hooks/usePath';
import { AuthLayoutContext, AuthLayoutErrorTypes } from './AuthLayoutContext';

export type AuthContextProps = {
  user: UserAuthType | null;
  setUser: (user: UserAuthType) => void;
  setCompany: (user: CompanyType) => void;
  loginEmployee: Function;
  logout: Function;
  adminLogin: Function;
  company?: CompanyType;
};

const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  setUser: () => {},
  setCompany: () => {},
  loginEmployee: () => {},
  logout: () => {},
  adminLogin: () => {},
  company: { name: '', domain: '' },
});

const AuthProvider = ({ children, userProfile }: { children: JSX.Element; userProfile: UserAuthType }) => {
  const [user, setUser] = React.useState<UserAuthType | null>(userProfile);
  const [company, setCompany] = React.useState<CompanyType>({
    domain: userProfile?.domain,
    name: userProfile?.company_name,
  });
  const router = useRouter();
  const { domain } = router.query;

  const { getPathWithPrefix } = usePath();

  const { setErrorMessage } = React.useContext(AuthLayoutContext);

  const adminLogin = async (values: LoginDto, onError?: Function) => {
    try {
      await login({
        email: values.email,
        password: values.password,
        is_employee: values.is_employee,
      });
      setUser(user);
      window.location.assign(
        router.query.from
          ? decodeURIComponent(router.query.from as string)
          : getPathWithPrefix(values.is_employee ? RoutesEnum.EMPLOYEE_HOME : RoutesEnum.AMDIN_HOME),
      );
    } catch (e: any) {
      const statusCode = e?.response?.data?.statusCode;
      if (statusCode === 401) {
        onError?.();
      }
    }
  };

  const loginEmployee = async (values: EmployeeLoginDto, onError?: Function) => {
    try {
      await userLogin({
        email: values.email,
        password: values.password,
        domain: domain as string,
      });
      setUser(user);
      window.location.assign(
        router.query.from
          ? decodeURIComponent(router.query.from as string)
          : getPathWithPrefix(RoutesEnum.EMPLOYEE_HOME),
      );
    } catch (e: any) {
      const statusCode = e?.response?.data?.statusCode;
      if (statusCode === 401) {
        onError?.();
      }
    }
  };

  const logout = async () => {
    try {
      if (user?.type === UserTypeEnum.EMPLOYEE) await userLogout();
      if (user?.type === UserTypeEnum.EMPLOYER) await adminLogout();
      setUser(null);
      router.reload();
    } catch (e: any) {
      setErrorMessage(AuthLayoutErrorTypes.GENERIC_ERROR);
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, company, setCompany, loginEmployee, logout, adminLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth, AuthContext };
