import React from 'react';
import Script from 'next/script';
import { UserAuthType } from '@models/user/auth';

const isProduction = process.env.NEXT_PUBLIC_ENV === 'production';
const isStaging = process.env.NEXT_PUBLIC_ENV === 'staging';
const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

/**
 * Third party scripts used on staging
 * and production environments
 * - Google tag manager
 * - Google analytics gtag
 * @constructor
 */

const ThirdParty = ({ userProfile }: { userProfile: UserAuthType }) => {
  const user = userProfile || {};
  if (isProduction || isStaging) {
    const { id, first_name: firstName, last_name: lastName, email } = user;
    const fullName = `${firstName} ${lastName}`;
    return (
      <>
        {!!user.id && (
          <Script
            id="datalayer"
            strategy="afterInteractive"
            data-cookieconsent="ignore"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({user_id: '${id}',name: '${fullName}',email:'${email}'});`,
            }}
          />
        )}
        <Script
          id="gtm"
          strategy="afterInteractive"
          data-cookieconsent="ignore"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');`,
          }}
        />
      </>
    );
  }

  return null;
};

export default ThirdParty;
