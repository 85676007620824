import { hrmsInstance, hrmsBackEndUrl } from '@utils/http-client';
import { SettingsType } from '@models/settings/employee-profile-settings';

const baseUrl = `${hrmsBackEndUrl}/users/view-settings`;

export const getUserViewSettings = (): Promise<SettingsType> => {
  return hrmsInstance.get(baseUrl, {
    headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
  });
};
