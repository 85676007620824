import { AdminNotification, UserNotification } from '@components/notfications-list/type';
import { hrmsInstance, hrmsBackEndUrl } from '@utils/http-client';
import { AdminUnseen } from './type';

const baseUrl = `${hrmsBackEndUrl}/notifications`;
const userBaseUrl = `${hrmsBackEndUrl}/users/notifications`;

/** Admin requests */

export const getAdminNotifications = (
  limit: number,
  last_id?: string,
  skip_reset: boolean = true,
): Promise<AdminNotification[]> => {
  return hrmsInstance.get(`${baseUrl}/list`, { params: { limit, last_id, skip_reset } });
};

export const getAdminUnseen = (): Promise<AdminUnseen> => {
  return hrmsInstance.get(`${baseUrl}/unseen`);
};

export const markAdminNotificationSeen = (id: string): Promise<void> => {
  return hrmsInstance.post(`${baseUrl}/${id}/mark-seen`);
};

/** User requests */

export const getUserNotifications = (
  limit: number,
  last_id?: string,
  skip_reset: boolean = true,
): Promise<UserNotification[]> => {
  return hrmsInstance.get(`${userBaseUrl}/list`, { params: { limit, last_id, skip_reset } });
};

export const getUserUnseen = (): Promise<AdminUnseen> => {
  return hrmsInstance.get(`${userBaseUrl}/unseen`);
};

export const markUserNotificationSeen = (id: string): Promise<void> => {
  return hrmsInstance.post(`${userBaseUrl}/${id}/mark-seen`);
};
