import { DepartmentNamesModel, DepartmentsModel } from '@models/department/departmentsModel';
import { hrmsInstance, hrmsBackEndUrl } from '@utils/http-client';
import { CreateDepartmentDto, GetDepartmentEmployees, UpdateDepartmentDto } from '@models/department';

const departments = `${hrmsBackEndUrl}/departments`;
const userDepartments = `${hrmsBackEndUrl}/users/departments`;

export const createDepartment = (body: CreateDepartmentDto): Promise<void> => {
  return hrmsInstance.post(`${departments}`, body);
};

export const updateDepartment = (departmentId: string, body: UpdateDepartmentDto): Promise<void> => {
  return hrmsInstance.put(`${departments}/${departmentId}`, body);
};

export const getAllDepartments = (page: number, psize: number): Promise<DepartmentsModel> => {
  return hrmsInstance.get(`${departments}/employees-group-list`, { params: { page, psize } });
};

export const getDepartmentNames = (): Promise<DepartmentNamesModel> => {
  return hrmsInstance.get(`${departments}/names-list`);
};

export const getUserDepartmentNames = (): Promise<DepartmentNamesModel> => {
  return hrmsInstance.get(`${userDepartments}/names-list`);
};

export const getDepartmentEmployees = (departmentId: string): Promise<GetDepartmentEmployees[]> => {
  return hrmsInstance.get(`${departments}/${departmentId}/employees`);
};

export const addEmployeesToDepartment = (departmentId: string, employees: string[]): Promise<void> => {
  return hrmsInstance.post(`${departments}/${departmentId}/employees/add`, { employees });
};

export const deleteDepartment = (departmentId: string): Promise<void> => {
  return hrmsInstance.delete(`${departments}/${departmentId}`);
};
