import EmployeesFileValidation, { EmployeesImportFile } from '@models/employee/employeesImport';
import EmployeesModel, {
  GetAdminEmployeeList,
  GetEmployeesByHiringInfoList,
  GetEmployeesCompactList,
  TeamEmployeesModel,
} from '@models/employee/employeesModel';
import EmployeeModel, {
  GetBasicInfoDto,
  EmployeeContactInfo,
  GetEmployeeDto,
  GetGeneralInfosDto,
  EmployeePersonalInfo,
  UpdateGeneralInfosDto,
  EmployeePositionDto,
  GetOtherInfosDto,
  UpdateOtherInfosDto,
  EmployeeChildrenDto,
  EmployeeChildrenInfo,
  UpdateHiringInfoDto,
  EmployeeAccount,
  UpdateEmployeeAccount,
} from '@models/employee/model';
import EmployeePositionsType from '@models/employee/positions';
import { hrmsInstance, hrmsBackEndUrl } from '@utils/http-client';
import { RcFile } from 'antd/lib/upload';
import {
  EmployeeSendInviteResponse,
  GetColleagueDetails,
  GetColleaguesList,
  GetEmployeeDocuments,
  GetEmployeeTimeOffBalance,
  GetEmployeeTimeOffRequest,
} from '@services/employee/type';
import EmployeeStatus from '@constants/employer-status/employer-status.enum';

const hires = `${hrmsBackEndUrl}/hires`;
const employees = `${hrmsBackEndUrl}/employees`;
const employeeProfiles = `${hrmsBackEndUrl}/employee-profiles`;
const users = `${hrmsBackEndUrl}/users`;

export const getAllEmployees = (
  page: number,
  psize: number,
  status?: string,
  sortBy?: string,
  orderBy?: string,
  query?: string,
  departmentsIds?: string,
  locationsIds?: string,
): Promise<EmployeesModel> => {
  const params = Object.entries({
    page,
    psize,
    status,
    sortby: sortBy,
    orderby: orderBy,
    name: query,
    deps: departmentsIds,
    locations: locationsIds,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (typeof value !== 'number' && !value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${employees}/hiring-info-list`, {
    params,
  });
};

export const getEmployeesCompactList = (props: {
  workingHour?: string;
  department?: string;
  location?: string;
  positionTitle?: string;
}): Promise<GetEmployeesCompactList[]> => {
  const params = Object.entries({
    working_hour: props.workingHour,
    department: props.department,
    location: props.location,
    position_title: props.positionTitle,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (!value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${employees}/compact-list`, {
    params,
  });
};

export const getAllEmployeesByStatus = (status: EmployeeStatus): Promise<GetAdminEmployeeList> => {
  return hrmsInstance.get(`${employees}/full-list`, {
    params: { status },
  });
};

export const getAllEmployeesByHiringStatus = (): Promise<GetEmployeesByHiringInfoList> => {
  return hrmsInstance.get(`${employees}/hiring-info-totals`);
};

export const getTeamList = (page: number, psize: number): Promise<TeamEmployeesModel> => {
  return hrmsInstance.get(`${users}/employees/team-list`, { params: { page, psize } });
};

export const getColleaguesList = (): Promise<GetColleaguesList[]> => {
  return hrmsInstance.get(`${users}/employees/list`);
};

export const getColleagueDetails = (id: string): Promise<GetColleagueDetails> => {
  return hrmsInstance.get(`${users}/employees/${id}`);
};

export const getEmployeesWithQuery = (
  page: number,
  psize: number,
  status?: string,
  query?: string,
  sortBy?: string,
  orderBy?: string,
): Promise<EmployeesModel> => {
  return hrmsInstance.get(`${employees}/hiring-info-list`, {
    params: { page, psize, status, sortby: sortBy, orderby: orderBy, q: query },
  });
};

export const deleteEmployee = (employeeId: string): Promise<EmployeesModel> => {
  return hrmsInstance.delete(`${employees}/${employeeId}`);
};

export const getEmployeeContactInfo = (employeeId: string): Promise<EmployeeContactInfo> => {
  return hrmsInstance.get(`${employeeProfiles}/${employeeId}/contact-info`);
};

export const getEmployeePersonalInfo = (employeeId: string): Promise<EmployeePersonalInfo> => {
  return hrmsInstance.get(`${employeeProfiles}/${employeeId}/personal-info`);
};

export const getEmployeePositionsHistory = (employeeId: string): Promise<EmployeePositionsType> => {
  return hrmsInstance.get(`${hires}/latest/history`, { params: { employee_id: employeeId } });
};

export const getEmployeeTimeOffBalance = (employeeId: string): Promise<GetEmployeeTimeOffBalance[]> => {
  return hrmsInstance.get(`${employeeProfiles}/${employeeId}/time-off/balance`);
};

export const getEmployeeTimeOffRequests = (employeeId: string, year: number): Promise<GetEmployeeTimeOffRequest[]> => {
  return hrmsInstance.get(`${employeeProfiles}/${employeeId}/time-off/leaves?year=${year}`);
};

export const updateEmployeeContactInfo = (
  employeeId: string,
  body: EmployeeContactInfo,
): Promise<EmployeeContactInfo> => {
  return hrmsInstance.put(`${employeeProfiles}/${employeeId}/contact-info`, body);
};

export const updateEmployeePersonalInfo = (
  employeeId: string,
  body: EmployeePersonalInfo,
): Promise<EmployeePersonalInfo> => {
  return hrmsInstance.put(`${employeeProfiles}/${employeeId}/personal-info`, body);
};

export const updateEmployeeGeneralInfo = (
  employeeId: string,
  body: UpdateGeneralInfosDto,
): Promise<UpdateGeneralInfosDto> => {
  return hrmsInstance.put(`${employeeProfiles}/${employeeId}/general-info`, body);
};

export const updateEmployeeHiringInfo = (
  employeeId: string,
  body: UpdateHiringInfoDto,
): Promise<UpdateHiringInfoDto> => {
  return hrmsInstance.post(`${employeeProfiles}/${employeeId}/hiring-info`, body);
};

export const createNewEmployee = (first_name: string, last_name: string, email: string): Promise<EmployeeModel> => {
  return hrmsInstance.post(`${employees}`, { first_name, last_name, email });
};

export const getEmployee = (id: string): Promise<GetEmployeeDto> => {
  return hrmsInstance.get(`${employees}/${id}`);
};

export const getBasicInfo = (id: string): Promise<GetBasicInfoDto> => {
  return hrmsInstance.get(`${hrmsBackEndUrl}/employee-profiles/${id}/basic-info`);
};

export const getGeneralInfo = (id: string): Promise<GetGeneralInfosDto> => {
  return hrmsInstance.get(`${hrmsBackEndUrl}/employee-profiles/${id}/general-info`);
};

export const getOtherInfo = (id: string): Promise<GetOtherInfosDto> => {
  return hrmsInstance.get(`${hrmsBackEndUrl}/employee-profiles/${id}/other-info`);
};

export const updateEmployeeOtherInfo = (
  employeeId: string,
  body: UpdateOtherInfosDto,
): Promise<UpdateOtherInfosDto> => {
  return hrmsInstance.put(`${employeeProfiles}/${employeeId}/other-info`, body);
};

export const createNewEmployeePosition = (hireId: string, body: EmployeePositionDto): Promise<EmployeePositionDto> => {
  return hrmsInstance.post(`${hrmsBackEndUrl}/hires/${hireId}/position`, body);
};

export const editEmployeePosition = (
  hireId: string,
  positionId: string,
  body: EmployeePositionDto,
): Promise<EmployeePositionDto> => {
  return hrmsInstance.put(`${hrmsBackEndUrl}/hires/${hireId}/position/${positionId}`, body);
};

export const deleteEmployeePosition = (hireId: string, positionId: string): Promise<EmployeePositionDto> => {
  return hrmsInstance.delete(`${hrmsBackEndUrl}/hires/${hireId}/position/${positionId}`);
};

export const validateEmployeesFile = (file: RcFile): Promise<EmployeesFileValidation> => {
  const formData = new FormData();
  formData.append('file', file);
  return hrmsInstance.post(`${hrmsBackEndUrl}/imports/validate/employees`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const importEmployees = (file: RcFile, skips: number): Promise<EmployeesImportFile> => {
  const formData = new FormData();
  formData.append('file', file);
  return hrmsInstance.post(`${hrmsBackEndUrl}/imports/employees?skips=${skips}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

/** Employee login */

export const sendInviteEmail = (employeeId: string): Promise<EmployeeSendInviteResponse> => {
  return hrmsInstance.post(`${employees}/${employeeId}/invite`);
};

/** Employee children */

export const getEmployeeChildrenInfo = (employeeId: string): Promise<Array<EmployeeChildrenInfo>> => {
  return hrmsInstance.get(`${employeeProfiles}/${employeeId}/children`);
};

export const createEmployeeChildrenInfo = (
  employeeId: string,
  body: EmployeeChildrenDto,
): Promise<EmployeeChildrenDto> => {
  return hrmsInstance.post(`${employeeProfiles}/${employeeId}/children`, body);
};

export const updateEmployeeChildrenInfo = (
  employeeId: string,
  id: string,
  body: EmployeeChildrenDto,
): Promise<EmployeeChildrenDto> => {
  return hrmsInstance.put(`${employeeProfiles}/${employeeId}/children/${id}`, body);
};

export const deleteEmployeeChildrenInfo = (employeeId: string, id: string): Promise<EmployeeChildrenDto> => {
  return hrmsInstance.delete(`${employeeProfiles}/${employeeId}/children/${id}`);
};

/** Employee account */

export const getEmployeeAccount = (employeeId: string): Promise<EmployeeAccount> => {
  return hrmsInstance.get(`${employeeProfiles}/${employeeId}/account_email`);
};

export const updateEmployeeAccount = (employeeId: string, body: UpdateEmployeeAccount) => {
  return hrmsInstance.post(`${employeeProfiles}/${employeeId}/account_email`, body);
};

export const getEmployeeDocuments = (employeeId: string): Promise<GetEmployeeDocuments[]> => {
  return hrmsInstance.get(`${employeeProfiles}/${employeeId}/documents/list`);
};

export const uploadEmployeeDocuments = (file: RcFile, employeeId: string) => {
  const formData = new FormData();
  formData.append('file', file);
  return hrmsInstance.post(`${employeeProfiles}/${employeeId}/documents`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const deleteEmployeeDocument = (employeeId: string, id: string) => {
  return hrmsInstance.delete(`${employeeProfiles}/${employeeId}/documents/${id}`);
};

export const sendEmployeesInviteEmail = () => {
  return hrmsInstance.post(`${employees}/invite`);
};
