import React from 'react';
import QuestionMarkIcon from '@assets/svgs/questionmark-icon.svg';
import { Dropdown } from 'antd';
import ContactDropdownContent from '@components/header/contact-dropdown/contact-dropdown-content/ContactDropdownContent';
import styles from './ContactDropdown.module.scss';

const ContactDropdown: React.FC = () => {
  return (
    <Dropdown
      dropdownRender={() => <ContactDropdownContent />}
      overlayClassName="dropdown-overlay"
      trigger={['click']}
      placement="bottomRight"
    >
      <div className={styles.compWrap} data-testid="contact-dropdown">
        <QuestionMarkIcon className={styles.questionMarkIcon} />
      </div>
    </Dropdown>
  );
};

export default ContactDropdown;
