import React, { useContext } from 'react';
import classNameCompose from '@utils/classname-compose';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { Trans } from 'next-i18next';
import dayjs from 'dayjs';
import RocketIcon from '@assets/svgs/rocket.svg';
import RoutesEnum from '@constants/routes.enum';
import usePath from '@hooks/usePath';
import UiButton from '@components/ui/button/UiButton';
import { useRouter } from 'next/router';
import { AuthContext } from '@contexts/AuthContext';
import { SubscriptionStatus } from '@services/tenants/type';
import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');
  const { user } = useContext(AuthContext);
  const router = useRouter();
  const { getPathWithPrefix } = usePath();

  return (
    <footer className={classNameCompose(styles.footer)}>
      {user?.sub_status === SubscriptionStatus.FREE && (
        <UiButton
          data-testid="start-your-plan-btn"
          className={styles.startPlanBtn}
          size="small"
          type="link"
          ghost
          onClick={() => router.push(getPathWithPrefix(`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=SUBSCRIPTION`))}
        >
          <RocketIcon className={styles.icon} />
          <span className={styles.upgradeLink}>{translate('footer_start_your_plan')}</span>
        </UiButton>
      )}
      <div />
      <Trans t={translate} i18nKey="all_rights_reserved" values={{ year: dayjs().year() }}>
        <h6 className={`small-caps-bold subheading ${styles.allRightsReserved}`}>
          _<span className={styles.logoBasicColor}>_</span>
          <span className={styles.logoSecondaryColor}>_</span>
          <span className={styles.logoBasicColor}>_</span>_
        </h6>
      </Trans>
    </footer>
  );
};

export default Footer;
