import React, { useContext } from 'react';
import LogoutIcon from '@assets/svgs/logout-icon.svg';
import SettingsIcon from '@assets/svgs/settings.svg';
import RocketIcon from '@assets/svgs/rocket.svg';
import SubscriptionIcon from '@assets/svgs/subscription-icon.svg';
import CreditCardIcon from '@assets/svgs/credit-card-icon.svg';
import ReceiptIcon from '@assets/svgs/receipt.svg';
import FolderIcon from '@assets/svgs/mail-settings.svg';
import { Menu } from 'antd';
import { AuthContext } from '@contexts/AuthContext';
import RoutesEnum from '@constants/routes.enum';
import { useRouter } from 'next/router';
import usePath from '@hooks/usePath';
import { UserTypeEnum } from '@models/user/auth';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import useAccountSuspended from '@hooks/useAccountSuspended';
import styles from './NavMenuOptions.module.scss';

const NavMenuOptions: React.FC = () => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');
  const { user, logout } = useContext(AuthContext);
  const router = useRouter();
  const { getPathWithPrefix, appPathPrefix } = usePath();
  const path = router.asPath.replace(appPathPrefix, '');

  const accountSuspended = useAccountSuspended();

  const handleLogout = async () => {
    localStorage.clear();
    await logout();
  };

  const goTo = (route: RoutesEnum | string) => {
    if (accountSuspended && route === RoutesEnum.ADMIN_GETTING_STARTED) return;
    router.push(getPathWithPrefix(route));
  };

  return (
    <Menu className={styles.compWrap} data-testid="nav-menu-options" selectedKeys={[path]}>
      {user?.type === UserTypeEnum.EMPLOYER && (
        <>
          <Menu.Item
            className={styles.options}
            key={RoutesEnum.ADMIN_GETTING_STARTED}
            onClick={() => goTo(RoutesEnum.ADMIN_GETTING_STARTED)}
            data-testid="getting-started-menu-option"
          >
            <div className={styles.item}>
              <RocketIcon className={styles.icon} />
              <span className={styles.text} data-testid="menu-option">
                {translate('menuOptions.getting_started')}
              </span>
            </div>
          </Menu.Item>
          <Menu.Item
            className={styles.options}
            key={`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=GENERAL`}
            onClick={() => goTo(`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=GENERAL`)}
            data-testid="account-menu-option"
          >
            <div className={styles.item}>
              <SettingsIcon className={styles.icon} />
              <span className={styles.text} data-testid="menu-option">
                {translate('menuOptions.account_settings')}
              </span>
            </div>
          </Menu.Item>
          <Menu.Item
            className={styles.options}
            key={`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=SUBSCRIPTION`}
            onClick={() => goTo(`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=SUBSCRIPTION`)}
            data-testid="subscription-menu-option"
          >
            <div className={styles.item}>
              <SubscriptionIcon className={styles.icon} />
              <span className={styles.text} data-testid="menu-option">
                {translate('menuOptions.subscription')}
              </span>
            </div>
          </Menu.Item>
          <Menu.Item
            className={styles.options}
            key={`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=BILLING_INFORMATION`}
            onClick={() => goTo(`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=BILLING_INFORMATION`)}
            data-testid="billing-info-menu-option"
          >
            <div className={styles.item}>
              <CreditCardIcon className={styles.icon} />
              <span className={styles.text} data-testid="menu-option">
                {translate('menuOptions.billing_info')}
              </span>
            </div>
          </Menu.Item>
          <Menu.Item
            className={styles.options}
            key={`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=PAYMENT_HISTORY`}
            onClick={() => goTo(`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=PAYMENT_HISTORY`)}
            data-testid="payment-history-menu-option"
          >
            <div className={styles.item}>
              <ReceiptIcon className={styles.icon} />
              <span className={styles.text} data-testid="menu-option">
                {translate('menuOptions.payment_history')}
              </span>
            </div>
          </Menu.Item>
        </>
      )}
      {user?.type === UserTypeEnum.EMPLOYEE && !router.pathname.includes('onboarding') && (
        <Menu.Item
          className={styles.options}
          key={RoutesEnum.EMPLOYEE_EMAIL_NOTIFICATION_SETTINGS}
          onClick={() => goTo(RoutesEnum.EMPLOYEE_EMAIL_NOTIFICATION_SETTINGS)}
          data-testid="email-settings-menu-option"
        >
          <div className={styles.item}>
            <FolderIcon className={styles.icon} />
            <span className={styles.text} data-testid="menu-option">
              {translate('menuOptions.email_settings')}
            </span>
          </div>
        </Menu.Item>
      )}
      <Menu.Item className={styles.options} key="logout" onClick={handleLogout} data-testid="logout-menu-option">
        <div className={styles.item}>
          <LogoutIcon className={styles.icon} />
          <span className={styles.text} data-testid="menu-option">
            {translate('menuOptions.logout')}
          </span>
        </div>
      </Menu.Item>
    </Menu>
  );
};

export default NavMenuOptions;
