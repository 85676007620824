import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Divider, Form, Input, Select, Tooltip } from 'antd';
import UiButton from '@components/ui/button/UiButton';
import useNotification from '@hooks/notification/useNotification';
import eventBus, { CustomEventTypes } from '@services/EventBus';
import ErrorMessagesEnum from '@constants/error-messages.enum';
import { createHoliday, updateHoliday } from '@services/holidays/admin-holidays.service';
import { HolidayTableDataType } from '@components/settings/admin/holidays/type';
import InfoTooltipIcon from '@assets/svgs/info-tooltip-icon.svg';
import dayjs from 'dayjs';
import yearsOptions from '@utils/years-options';
import MONTHS_OPTIONS, { monthsOptionsKeys } from '@constants/months-options';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { Trans } from 'next-i18next';
import { getAllDepartments } from '@services/department/department-http.service';
import useFetchData from '@hooks/useFetchData';
import { getLocations } from '@services/locations/admin-locations-http.service';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import styles from './HolidayForm.module.scss';

const { TextArea } = Input;
const { Option } = Select;

export type HolidayFormProps = {
  setVisible: (visible: boolean) => void;
  initialValues?: HolidayTableDataType;
};

const HolidayForm: React.FC<HolidayFormProps> = ({ setVisible, initialValues }) => {
  const [form] = Form.useForm();
  const { useTypedTranslation } = makeTypedTranslation('common', 'notification', 'forms');
  const { t: translate } = useTypedTranslation('common');
  const { t: translateForms } = useTypedTranslation('forms');
  const { t: translateNotification } = useTypedTranslation('notification');
  const [activeMonth, setActiveMonth] = useState('');
  const [activeYear, setActiveYear] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [filterByDept, setFilterByDept] = useState(false);
  const [filterByLocation, setFilterByLocation] = useState(false);

  const [errorNotification] = useNotification('error', translateNotification('status.error'));
  const [successNotification] = useNotification('success', translateNotification('status.success'));

  const daysOptions = useMemo(() => {
    const currentMonth = dayjs().month(Number(activeMonth));
    const currentYear = dayjs().year(Number(activeYear));
    const daysInMonth = dayjs(
      `${activeYear ? currentYear.format('YYYY') : dayjs().year()}-${currentMonth.format('MM')}`,
    ).daysInMonth();

    const currentDate =
      form.getFieldValue('date') ||
      (initialValues &&
        dayjs(
          `${initialValues?.year ? initialValues?.year : dayjs().format('YYYY')}-${initialValues?.year_date}`,
        ).format('D'));

    form.setFieldsValue({
      date: currentDate > daysInMonth ? null : currentDate,
    });

    const days = Array.from(new Array(daysInMonth), (v, idx) => 1 + idx);
    return days;
  }, [activeMonth, activeYear]);

  const formValues = useMemo(() => {
    if (!initialValues) return {};
    const selectedDepts = initialValues ? initialValues.departments?.map((department) => department.id) : [];
    const selectedLocations = initialValues ? initialValues.locations?.map((location) => location.id) : [];
    setFilterByDept(!!selectedDepts?.length);
    setFilterByLocation(!!selectedLocations?.length);

    return {
      ...initialValues,
      dep_filter: selectedDepts?.length,
      location_filter: selectedLocations?.length,
      departments: selectedDepts,
      locations: selectedLocations,
      repeats_annually: initialValues && !initialValues?.year,
      year: initialValues && initialValues?.year,
      date:
        initialValues &&
        dayjs(
          `${initialValues?.year ? initialValues?.year : dayjs().format('YYYY')}-${initialValues?.year_date}`,
        ).format('D'),
      month:
        initialValues &&
        dayjs(
          `${initialValues?.year ? initialValues?.year : dayjs().format('YYYY')}-${initialValues?.year_date}`,
        ).format('MMMM'),
    };
  }, [initialValues]);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSubmit(hasErrors);
  };

  const handleDynamicInputConditions = () => {
    setFilterByDept(Boolean(form.getFieldValue('dep_filter')));
    setFilterByLocation(Boolean(form.getFieldValue('location_filter')));
  };

  const departmentsFetcher = useCallback(async () => {
    const departments = await getAllDepartments(1, 700);

    return departments.data.reduce((acc: { value: string; label: string; active: boolean }[], department) => {
      acc.push({ value: department.id, label: department.title, active: department.active! });
      return acc;
    }, []);
  }, []);
  const { data: departments } = useFetchData<{ value: string; label: string; active: boolean }[]>(departmentsFetcher);

  const locationsFetcher = useCallback(async () => {
    const locations = await getLocations(1, 500);

    return locations.data.reduce((acc: { value: string; label: string; active: boolean }[], location) => {
      acc.push({ value: location.id, label: location.title, active: location.active! });
      return acc;
    }, []);
  }, []);
  const { data: locations } = useFetchData<{ value: string; label: string; active: boolean }[]>(locationsFetcher);

  useEffect(() => {
    const selectedLocations =
      initialValues && initialValues.locations
        ? initialValues.locations?.map((location) => {
            const current = locations?.find((entry) => entry.value === location.id);
            if (!current?.active) {
              return current?.label;
            }
            return current.value;
          })
        : [];
    setFilterByLocation(!!selectedLocations?.length);
    form.setFieldsValue({
      locations: selectedLocations,
      location_filter: selectedLocations?.length,
    });
  }, [locations]);

  useEffect(() => {
    const selectedDepartments =
      initialValues && initialValues.departments
        ? initialValues.departments?.map((department) => {
            const current = departments?.find((entry) => entry.value === department.id);
            if (!current?.active) {
              return current?.label;
            }
            return current.value;
          })
        : [];
    setFilterByLocation(!!selectedDepartments?.length);
    form.setFieldsValue({
      dep_filter: selectedDepartments?.length,
      departments: selectedDepartments,
    });
  }, [departments]);

  const onFinish = (onFinishAction: any) => async (values: any) => {
    try {
      await onFinishAction(values);
      setVisible(false);
      eventBus.getInstance().dispatch(CustomEventTypes.HOLIDAYS_UPDATED);
    } catch (e: any) {
      if (e.message === ErrorMessagesEnum.HOLIDAY_NAME_EXISTS) {
        errorNotification(undefined, translate('create_holiday.holiday_name_exists'));
      } else if (e.message === ErrorMessagesEnum.DUPLICATE_DATE) {
        errorNotification(undefined, translate('create_holiday.holiday_date_exists'));
      } else {
        errorNotification(undefined, translate('messages.error.something_went_wrong'));
      }
    }
  };

  const onCreate = onFinish(async (values: any) => {
    const monthValue = dayjs().month(values.month).format('MM');
    const dateValue = values.date !== 31 ? dayjs().date(values.date).format('DD') : values.date;

    await createHoliday({
      name: values.name,
      year: values.year,
      year_date: `${monthValue}-${dateValue}`,
      description: values.description,
      departments: values.departments,
      locations: values.locations,
    });
    successNotification(undefined, translate('create_holiday.holiday_created'));
  });

  const onUpdate = onFinish(async (values: any) => {
    const monthValue =
      typeof values.month === 'number'
        ? dayjs().month(values.month).format('MM')
        : dayjs()
            .month(Number(monthsOptionsKeys(values.month)))
            .format('MM');
    const dateValue = values.date !== 31 ? dayjs().date(values.date).format('DD') : values.date;

    const transformedDepartments = departments?.reduce((acc: string[], department) => {
      if (values?.departments && values?.departments.includes(department.value)) {
        acc.push(department.value);
      }
      if (values?.departments && values?.departments.includes(department.label)) {
        acc.push(department.value);
      }
      return acc;
    }, []);

    const transformedLocations = locations?.reduce((acc: string[], location) => {
      if (values?.locations && values?.locations.includes(location.value)) {
        acc.push(location.value);
      }
      if (values?.locations && values?.locations.includes(location.label)) {
        acc.push(location.value);
      }
      return acc;
    }, []);

    await updateHoliday(initialValues?.key!, {
      name: values.name,
      year: values.year ? values.year : null,
      year_date: `${monthValue}-${dateValue}`,
      description: values.description,
      departments: transformedDepartments,
      locations: transformedLocations,
    });
    successNotification(undefined, translate('create_holiday.holiday_updated'));
  });

  const onMonthSelect = (value: string) => {
    setActiveMonth(value);
  };

  const onYearSelect = (value: string) => {
    setActiveYear(value);
  };

  const handleInChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      // eslint-disable-next-line no-void
      void form.validateFields();
    }
  };

  return (
    <Form
      form={form}
      name="holidayForm"
      layout="vertical"
      size="small"
      className={styles.form}
      onFieldsChange={handleFormChange}
      initialValues={formValues}
      onFinish={initialValues ? onUpdate : onCreate}
      autoComplete="off"
    >
      <h5 className="h5 uppercase">
        {initialValues ? translate('create_holiday.edit_holiday') : translate('create_holiday.add_holiday')}
      </h5>
      <Form.Item
        label={translate('create_holiday.name')}
        name="name"
        rules={[{ required: true, message: translateForms('required_minimal') }]}
      >
        <Input placeholder={translate('create_holiday.type_name')} data-testid="holiday-name" maxLength={60} />
      </Form.Item>
      <Form.Item className={styles.checkboxFormItem} valuePropName="checked" name="repeats_annually">
        <Checkbox data-testid="repeats-annually-checkbox" onChange={handleInChange}>
          <div className={styles.checkboxWithTooltip}>
            {translate('create_holiday.repeats_annually')}
            <Tooltip
              placement="topRight"
              mouseEnterDelay={0.3}
              title={translate('create_holiday.repeats_annually_tooltip')}
            >
              <InfoTooltipIcon className="icon-small" />
            </Tooltip>
          </div>
        </Checkbox>
      </Form.Item>
      <Divider className={styles.divider} />
      <Form.Item
        label={translate('create_holiday.date')}
        className={styles.dateFieldsWrap}
        rules={[{ required: true, message: translateForms('required_minimal') }]}
      >
        <div className={styles.dateFormFields}>
          <Form.Item name="month" rules={[{ required: true, message: translateForms('required_minimal') }]}>
            <Select data-testid="month-select" placeholder={translate('create_holiday.month')} onChange={onMonthSelect}>
              {Object.entries(MONTHS_OPTIONS)?.map(([key, value]) => (
                <Option key={Number(key)} value={Number(key)}>
                  {translate(value as any)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="date" rules={[{ required: true, message: translateForms('required_minimal') }]}>
            <Select data-testid="day-select">
              {daysOptions?.map((day: number, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <Option key={index} value={day}>
                  {day}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, currentValues) => prevValues.repeats_annually !== currentValues.repeats_annually}
          >
            {
              ({ getFieldValue }) =>
                !getFieldValue('repeats_annually') ? (
                  <Form.Item name="year" rules={[{ required: true, message: translateForms('required_minimal') }]}>
                    <Select
                      data-testid="year-select"
                      placeholder={translate('create_holiday.year')}
                      onChange={onYearSelect}
                    >
                      {yearsOptions()?.map((year: number, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Option key={index} value={year}>
                          {year}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : null
              // eslint-disable react/jsx-curly-newline
            }
          </Form.Item>
        </div>
      </Form.Item>

      <Divider className={styles.divider} />
      <h6 className="medium-caps-bold title-dark">{translate('create_holiday.apply_to')}</h6>
      <p className="small-body-text title-dark">
        <Trans t={translate} i18nKey="create_holiday.blank_instructions">
          -
        </Trans>
      </p>
      <Form.Item className={styles.checkboxFormItem} valuePropName="checked" name="dep_filter">
        <Checkbox data-testid="departments-checkbox" onChange={handleDynamicInputConditions}>
          <div>{translate('create_holiday.departments_checkbox')}</div>
        </Checkbox>
      </Form.Item>
      {filterByDept && (
        <>
          <Form.Item name="departments">
            <Select
              data-testid="departments-select"
              mode="multiple"
              className={styles.multipleSelect}
              placeholder="Please select"
              options={departments?.filter((department) => department.active)}
              showArrow
            />
          </Form.Item>
          <Divider className={styles.divider} />
        </>
      )}
      <Form.Item className={styles.checkboxFormItem} valuePropName="checked" name="location_filter">
        <Checkbox data-testid="locations-checkbox" onChange={handleDynamicInputConditions}>
          <div>{translate('create_holiday.locations_checkbox')}</div>
        </Checkbox>
      </Form.Item>
      {filterByLocation && (
        <Form.Item name="locations">
          <Select
            data-testid="locations-select"
            mode="multiple"
            className={styles.multipleSelect}
            placeholder="Please select"
            options={locations?.filter((location) => location.active)}
            showArrow
          />
        </Form.Item>
      )}
      <Divider className={styles.divider} />

      <Form.Item
        label={translate('create_holiday.description')}
        name="description"
        className={styles.descriptionField}
        rules={[{ max: 300, message: translateForms('max_300_chars_error') }]}
      >
        <TextArea
          rows={2}
          placeholder={translate('create_holiday.type_description')}
          maxLength={300}
          data-testid="holiday-description"
        />
      </Form.Item>
      <Divider className={styles.divider} />
      <Form.Item>
        <div className={styles.actionButtons}>
          <UiButton
            type="primary"
            size="small"
            htmlType="submit"
            disabled={disabledSubmit}
            className={styles.submitButton}
            data-testid="submit-button"
          >
            {initialValues ? translate('create_holiday.save') : translate('create_holiday.create')}
          </UiButton>
          <UiButton size="small" onClick={() => setVisible(false)}>
            {translate('create_holiday.cancel')}
          </UiButton>
        </div>
      </Form.Item>
    </Form>
  );
};

export default HolidayForm;
