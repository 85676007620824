import React, { useCallback, useEffect, useMemo, useState } from 'react';
import UiButton from '@components/ui/button/UiButton';
import { Divider, Form, Input, Select } from 'antd';
import UiDatePicker from '@components/ui/date-picker/UiDatePicker';
import dateFormat, { emptyDate, inputDateFormat } from '@constants/date-format';
import useFetchData from '@hooks/useFetchData';
import dayjs from 'dayjs';
import {
  createUserLeaveRequest,
  getEmployeeTimeOffTypes,
  getUserLeaveRangeInfo,
  getUserTimeOffAvailableDays,
} from '@services/leaves/leaves-http.service';
import { CreateUserLeaveRequestDto, LeaveRangeInfo, TimeOffType } from '@models/leaves';
import useNotification from '@hooks/notification/useNotification';
import ErrorMessagesEnum from '@constants/error-messages.enum';
import eventBus, { CustomEventTypes } from '@services/EventBus';
import { GetTimeOffAvailableDays } from '@services/leaves/type';
import classNameCompose from '@utils/classname-compose';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { HalfDaysDuration } from '@constants/leaves/half-days-duration/half-days-duration';
import styles from './UserRequestTimeOff.module.scss';

const { Option } = Select;

type RequestTimeOffProps = {
  setVisible: (visible: boolean) => void;
};

const UserRequestTimeOff: React.FC<RequestTimeOffProps> = ({ setVisible }) => {
  const [form] = Form.useForm();
  const { useTypedTranslation } = makeTypedTranslation('common', 'notification', 'forms');
  const { t: translate } = useTypedTranslation('common');
  const { t: formsTranslate } = useTypedTranslation('forms');
  const { t: notificationTranslate } = useTypedTranslation('notification');
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [mandatoryFieldsExist, setMandatoryFieldsExist] = useState(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [activeLeaveType, setActiveLeaveType] = useState<string | undefined>(undefined);
  const [duration, setDuration] = useState<string | undefined>(undefined);

  const [successNotification] = useNotification('success', notificationTranslate('status.success'));
  const [errorNotification] = useNotification('error', notificationTranslate('status.error'));

  const leaveTypesFetcher = useCallback(async () => {
    const leaveTypes = await getEmployeeTimeOffTypes();
    return leaveTypes;
  }, []);

  const { data: leaveTypes } = useFetchData<Array<TimeOffType>>(leaveTypesFetcher);

  const allowHalfDays = useMemo(() => {
    const matchingLeaveType = leaveTypes?.find((leaveType) => {
      return leaveType.id === activeLeaveType && leaveType.allow_half_day;
    });

    return matchingLeaveType;
  }, [leaveTypes, activeLeaveType]);

  const rangeInfoFetcher = useCallback(() => {
    if (!mandatoryFieldsExist) return null;
    if (allowHalfDays && startDate === endDate && !duration) return null;
    const leaveTypeId = form.getFieldValue('leave_type_id');
    const selectedHalfDayPart =
      allowHalfDays && duration !== HalfDaysDuration.ALL_DAY && startDate === endDate ? duration : undefined;
    const leaveRangeInfo = getUserLeaveRangeInfo(startDate!, endDate!, leaveTypeId, selectedHalfDayPart!);
    return leaveRangeInfo;
  }, [mandatoryFieldsExist, startDate, endDate, duration]);

  const { data: rangeInfo, toggleRevalidate } = useFetchData<LeaveRangeInfo | null>(rangeInfoFetcher);

  const leaveAvailableDaysFetcher = useCallback(async () => {
    if (!activeLeaveType) return null;
    return getUserTimeOffAvailableDays(activeLeaveType as string);
  }, [activeLeaveType]);

  const { data: availableDays } = useFetchData<GetTimeOffAvailableDays>(leaveAvailableDaysFetcher);

  const handleFormChange = () => {
    setMandatoryFieldsExist(
      form.getFieldValue('start_date') && form.getFieldValue('end_date') && form.getFieldValue('leave_type_id'),
    );
    if (form.getFieldValue('start_date')) {
      setStartDate(dayjs(form.getFieldValue('start_date')).format(inputDateFormat));
    }
    if (form.getFieldValue('end_date')) {
      setEndDate(dayjs(form.getFieldValue('end_date')).format(inputDateFormat));
    }
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    if (hasErrors !== disabledSubmit) setDisabledSubmit(hasErrors);
  };

  const onFinish = async (values: CreateUserLeaveRequestDto) => {
    try {
      await createUserLeaveRequest({
        half_day_part: values.half_day_part !== HalfDaysDuration.ALL_DAY ? values.half_day_part : null,
        leave_type_id: values.leave_type_id || '',
        start_date: dayjs(values.start_date).format(inputDateFormat),
        end_date: dayjs(values.end_date).format(inputDateFormat),
        description: values?.description || '',
      });
      setVisible(false);
      eventBus.getInstance().dispatch(CustomEventTypes.USER_TIME_OFF_REQUESTS_UPDATED);
      eventBus.getInstance().dispatch(CustomEventTypes.USER_BALANCE_UPDATED);
      successNotification(undefined, translate('messages.success.request_submitted'));
    } catch (e: any) {
      if (e.message === ErrorMessagesEnum.DATES_CONFLICT) {
        errorNotification(undefined, translate('messages.error.dates_conflict'));
      } else {
        errorNotification(undefined, translate('messages.error.something_went_wrong'));
      }
    }
  };

  const disabledDate = (current: any, value: string) => {
    if (value === 'start_date') return form.getFieldValue(value) && current.isBefore(dayjs(form.getFieldValue(value)));
    return form.getFieldValue(value) && current.isAfter(dayjs(form.getFieldValue(value)));
  };

  const getRangeError = useCallback((errorMessage: string) => {
    switch (errorMessage) {
      case 'DATES_CONFLICT':
        return translate('request_time_off.error_messages.DATES_CONFLICT');
      case 'EXCEEDS_MAX_DAYS':
        return translate('request_time_off.error_messages.EXCEEDS_MAX_DAYS');
      case 'EXCEEDS_MAX_YEARLY_DAYS':
        return translate('request_time_off.error_messages.EXCEEDS_MAX_YEARLY_DAYS');
      case 'EXCEEDS_AVAILABLE_DAYS':
        return translate('request_time_off.error_messages.EXCEEDS_AVAILABLE_DAYS');
      case 'NO_MATCHING_POLICY':
        return translate('request_time_off.error_messages.NO_MATCHING_POLICY');
      case 'PAST_DAYS_NOT_ALLOWED':
        return translate('request_time_off.error_messages.PAST_DAYS_NOT_ALLOWED');
      case 'INVALID_DATES':
        return translate('request_time_off.error_messages.INVALID_DATES');
      case 'NO_DAYS_SELECTED':
        return translate('request_time_off.error_messages.NO_DAYS_SELECTED');
      case 'EXCEEDS_NEXT_YEAR_AVAILABLE_DAYS':
        return translate('request_time_off.error_messages.EXCEEDS_NEXT_YEAR_AVAILABLE_DAYS');
      default:
        return '';
    }
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const error = rangeInfo?.total_days === 0 || rangeInfo?.error;
    return setDisabledSubmit(!!error);
  }, [rangeInfo]);

  const leaveTypeHandleChange = (value: string) => {
    setActiveLeaveType(value);
  };

  const durationHandleChange = (value: string) => {
    setDuration(value);
  };

  return (
    <Form
      form={form}
      name="requestTimeOffForm"
      layout="vertical"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      data-testid="request-time-off-form"
      size="small"
      onFieldsChange={handleFormChange}
      onFinish={onFinish}
    >
      <div className={styles.formWrap}>
        <h5 className="h5 uppercase">{translate('request_time_off.request_time_off')}</h5>
        <Form.Item
          label={translate('request_time_off.time_off_type')}
          name="leave_type_id"
          rules={[{ required: true, message: formsTranslate('required') }]}
        >
          <Select
            value="other"
            notFoundContent={translate('request_time_off.not_found_time_off_types')}
            placeholder={translate('request_time_off.time_off_type_placeholder')}
            filterOption={(input, option) => String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
            data-testid="type-select"
            onChange={leaveTypeHandleChange}
          >
            {leaveTypes?.map((leaveType) => (
              <Select.Option key={leaveType.id} value={leaveType.id}>
                {leaveType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {!!availableDays?.available && (
          <p
            data-testid="available-days"
            className={classNameCompose(styles.availablesDays, 'main-body-text', 'subheading')}
          >
            <span className="h6">{translate('request_time_off.available_days')}: </span>
            {availableDays.available}
          </p>
        )}

        <Divider />
        <div className={styles.dateFields}>
          <Form.Item
            label={translate('request_time_off.from')}
            name="start_date"
            rules={[{ required: true, message: formsTranslate('required') }]}
          >
            {/* @ts-ignore */}
            <UiDatePicker
              onChange={toggleRevalidate}
              placeholder={emptyDate}
              format={dateFormat}
              inputReadOnly
              showToday={false}
              data-testid="off-start-day"
              disabledDate={(current) => disabledDate(current, 'end_date')}
            />
          </Form.Item>
          <Form.Item
            label={translate('request_time_off.to')}
            name="end_date"
            rules={[{ required: true, message: formsTranslate('required') }]}
          >
            {/* @ts-ignore */}
            <UiDatePicker
              key={`${dayjs(form.getFieldValue('start_date'))}`}
              onChange={toggleRevalidate}
              placeholder={emptyDate}
              format={dateFormat}
              inputReadOnly
              showToday={false}
              data-testid="off-end-day"
              disabledDate={(current) => disabledDate(current, 'start_date')}
              defaultPickerValue={form.getFieldValue('start_date') && dayjs(form.getFieldValue('start_date'))}
            />
          </Form.Item>
        </div>
        {allowHalfDays && startDate && endDate && startDate === endDate && (
          <Form.Item
            label={translate('request_time_off.duration')}
            name="half_day_part"
            rules={[{ required: true, message: formsTranslate('required') }]}
          >
            <Select
              size="small"
              className={styles.yearSelect}
              data-testid="duration-select"
              placeholder={translate('request_time_off.duration_placeholder')}
              onChange={durationHandleChange}
            >
              <Option key={HalfDaysDuration.ALL_DAY} value={HalfDaysDuration.ALL_DAY}>
                {translate('request_time_off.duration_all_day')}
              </Option>
              <Option key={HalfDaysDuration.FIRST_HALF} value={HalfDaysDuration.FIRST_HALF}>
                {translate('request_time_off.duration_first_part')}
              </Option>
              <Option key={HalfDaysDuration.SECOND_HALF} value={HalfDaysDuration.SECOND_HALF}>
                {translate('request_time_off.duration_second_part')}
              </Option>
            </Select>
          </Form.Item>
        )}
        <>
          {rangeInfo?.error && <p className={`small-body-text ${styles.error}`}>{getRangeError(rangeInfo?.error)}</p>}
          <p className="main-body-text subheading">
            <span className="h6">{translate('request_time_off.return_day')}: </span>
            {rangeInfo?.return_date ? (
              <>
                <span>{dayjs(rangeInfo?.return_date).format(dateFormat)}</span>
                {allowHalfDays &&
                  startDate === endDate &&
                  form.getFieldValue('half_day_part') === HalfDaysDuration.FIRST_HALF && (
                    <span>{` (${translate('request_time_off.duration_second_part')})`}</span>
                  )}
              </>
            ) : (
              emptyDate
            )}
          </p>
          <p className="main-body-text subheading" data-testid="total-days">
            <span className="h6">{translate('request_time_off.total_days')}: </span>
            {rangeInfo?.total_days ?? 0}
          </p>
        </>
        <Divider />
        <Form.Item
          label={translate('request_time_off.comments')}
          name="description"
          rules={[{ max: 300, message: formsTranslate('max_chars_error') }]}
        >
          <Input.TextArea
            placeholder={translate('request_time_off.comments')}
            rows={2}
            maxLength={500}
            data-testid="leave-comments"
          />
        </Form.Item>
        <Divider />
        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
          <div className={styles.actionButtons}>
            <UiButton
              type="primary"
              size="small"
              htmlType="submit"
              data-testid="submit-button"
              disabled={disabledSubmit}
              className={styles.submitButton}
            >
              {translate('request_time_off.submit')}
            </UiButton>
            <UiButton size="small" className={styles.cancelButton} onClick={() => setVisible(false)}>
              {translate('request_time_off.cancel')}
            </UiButton>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
};

export default UserRequestTimeOff;
