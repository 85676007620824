import React from 'react';
import PropTypes from 'prop-types';
import Linkable from '@components/ka-ui/linkable/Linkable';
import styles from './ColleagueInfo.module.scss';

type ColleagueInfoProps = {
  icon: React.ReactNode;
  content: string;
  actionUrl?: string;
};

const ColleagueInfo: React.FC<ColleagueInfoProps> = ({ icon, content, actionUrl }) => {
  const Icon = icon as any;

  return (
    <Linkable redirectTo={actionUrl}>
      <div className={styles.wrap}>
        <Icon className={styles.icon} />
        <p className={`large-body-text primary-light ${styles.content}`}>{content}</p>
      </div>
    </Linkable>
  );
};

ColleagueInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.any.isRequired,
  content: PropTypes.string.isRequired,
  actionUrl: PropTypes.string,
};

ColleagueInfo.defaultProps = {
  actionUrl: '',
};

export default ColleagueInfo;
