enum LeaveStatusTabs {
  PENDING = 'pending',
  REVIEWED = 'reviewed',
}

export enum LeaveStatus {
  PENDING = 'pending',
  PENDING_CANCELATION = 'pending_cancelation',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}

export const leaveStatusValue = (status: string) =>
  Object.values(LeaveStatus)[Object.keys(LeaveStatus).indexOf(status)];

export default LeaveStatusTabs;
