import { SubscriptionStatus } from '@services/tenants/type';

export enum UserTypeEnum {
  EMPLOYER = 'employer',
  EMPLOYEE = 'employee',
}

export enum SubscriptionType {
  trial = 'trial',
  stripe = 'stripe',
  offline = 'offline',
}

export type UserAuthType = {
  id?: string;
  first_name?: string;
  last_name?: string;
  img_url?: string;
  email?: string;
  onboarding_step?: number;
  type?: UserTypeEnum;
  has_team?: boolean;
  has_tenant?: boolean;
  subscription?: {
    type: SubscriptionType;
    days_remaining: number;
  };
  uses_demo_data?: boolean;
  timezone?: string;
  sub_status?: SubscriptionStatus;
  view_settings_version?: number;
  /** company specific */
  domain?: string;
  company_name?: string;
  ats_enabled?: boolean;
};
