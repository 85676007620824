import EmployeeStatus from '@constants/employer-status/employer-status.enum';

/** Admin notifications */

export enum AdminNotificationTypes {
  TIME_OFF_REQUEST = 'TIME_OFF_REQUEST',
  TIME_OFF_CANCELATION_REQUEST = 'TIME_OFF_CANCELATION_REQUEST',
  EMPLOYEE_END_HIRE = 'EMPLOYEE_END_HIRE',
  EMPLOYEE_END_HIRE_SOON = 'EMPLOYEE_END_HIRE_SOON',
  EMPLOYEE_COMPLETED_ONBOARDING = 'EMPLOYEE_COMPLETED_ONBOARDING',
  EMPLOYEES_NOT_IN_ORG_CHART = 'EMPLOYEES_NOT_IN_ORG_CHART',
  EMPLOYEE_BIRTHDAY = 'EMPLOYEE_BIRTHDAY',
  WORK_ANNIVERSARY = 'WORK_ANNIVERSARY',
  FIRST_PAYMENT_ATTEMPT_FAILED = 'FIRST_PAYMENT_ATTEMPT_FAILED',
  SECOND_PAYMENT_ATTEMPT_FAILED = 'SECOND_PAYMENT_ATTEMPT_FAILED',
  EMPLOYEE_HIRING_STARTED = 'EMPLOYEE_HIRING_STARTED',
  EMPLOYEE_UPLOADED_DOCUMENT = 'EMPLOYEE_UPLOADED_DOCUMENT',
}

type AdminMapType = {
  [AdminNotificationTypes.TIME_OFF_REQUEST]: {
    employee_id: string;
    request_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [AdminNotificationTypes.EMPLOYEE_BIRTHDAY]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    date: string;
  };
  [AdminNotificationTypes.TIME_OFF_CANCELATION_REQUEST]: {
    employee_id: string;
    request_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [AdminNotificationTypes.EMPLOYEE_END_HIRE]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [AdminNotificationTypes.EMPLOYEE_END_HIRE_SOON]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [AdminNotificationTypes.EMPLOYEE_END_HIRE_SOON]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [AdminNotificationTypes.EMPLOYEE_COMPLETED_ONBOARDING]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [AdminNotificationTypes.WORK_ANNIVERSARY]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    start_date: string;
    status: EmployeeStatus;
  };
  [AdminNotificationTypes.EMPLOYEES_NOT_IN_ORG_CHART]: {};
  [AdminNotificationTypes.FIRST_PAYMENT_ATTEMPT_FAILED]: {};
  [AdminNotificationTypes.SECOND_PAYMENT_ATTEMPT_FAILED]: {};
  [AdminNotificationTypes.EMPLOYEE_HIRING_STARTED]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [AdminNotificationTypes.EMPLOYEE_UPLOADED_DOCUMENT]: {
    employee_id: string;
    first_name: string;
    last_name: string;
  };
};

type AdminNotificationType<T extends keyof AdminMapType> = {
  id: string;
  type: T | `${T}`;
  payload: AdminMapType[T];
  created_at: string;
  seen: boolean;
};

export type AdminNotification =
  | AdminNotificationType<AdminNotificationTypes.TIME_OFF_REQUEST>
  | AdminNotificationType<AdminNotificationTypes.TIME_OFF_CANCELATION_REQUEST>
  | AdminNotificationType<AdminNotificationTypes.EMPLOYEE_END_HIRE>
  | AdminNotificationType<AdminNotificationTypes.EMPLOYEE_COMPLETED_ONBOARDING>
  | AdminNotificationType<AdminNotificationTypes.EMPLOYEES_NOT_IN_ORG_CHART>
  | AdminNotificationType<AdminNotificationTypes.EMPLOYEE_END_HIRE_SOON>
  | AdminNotificationType<AdminNotificationTypes.EMPLOYEE_BIRTHDAY>
  | AdminNotificationType<AdminNotificationTypes.FIRST_PAYMENT_ATTEMPT_FAILED>
  | AdminNotificationType<AdminNotificationTypes.SECOND_PAYMENT_ATTEMPT_FAILED>
  | AdminNotificationType<AdminNotificationTypes.EMPLOYEE_HIRING_STARTED>
  | AdminNotificationType<AdminNotificationTypes.WORK_ANNIVERSARY>
  | AdminNotificationType<AdminNotificationTypes.EMPLOYEE_UPLOADED_DOCUMENT>;

/** Employees notifications */

export enum UserNotificationTypes {
  TIME_OFF_REQUEST = 'TIME_OFF_REQUEST',
  NEW_ANNOUNCEMENT = 'NEW_ANNOUNCEMENT',
  APPROVED_TIME_OFF_REQUEST = 'APPROVED_TIME_OFF_REQUEST',
  REJECTED_TIME_OFF_REQUEST = 'REJECTED_TIME_OFF_REQUEST',
  CHANGED_REPORTING_MANAGER = 'CHANGED_REPORTING_MANAGER',
  NEW_TEAM_MEMBER = 'NEW_TEAM_MEMBER',
  EMPLOYEE_END_HIRE = 'EMPLOYEE_END_HIRE',
  EMPLOYEE_END_HIRE_SOON = 'EMPLOYEE_END_HIRE_SOON',
  EMPLOYEE_BIRTHDAY = 'EMPLOYEE_BIRTHDAY',
  WORK_ANNIVERSARY = 'WORK_ANNIVERSARY',
  CANCELED_TIME_OFF_REQUEST = 'CANCELED_TIME_OFF_REQUEST',
  MY_TIME_OFF_CANCELED = 'MY_TIME_OFF_CANCELED',
  MY_TIME_OFF_CANCELATION_REJECTED = 'MY_TIME_OFF_CANCELATION_REJECTED',
  CANCELATION_REQUEST_APPROVED = 'CANCELATION_REQUEST_APPROVED',
  EMPLOYEE_HIRING_STARTED = 'EMPLOYEE_HIRING_STARTED',
  COMPANY_DOCUMENTS_CREATE = 'COMPANY_DOCUMENTS_CREATE',
  MANAGERS_PERFORMANCE_GOALS_SETTING_STARTED = 'MANAGERS_PERFORMANCE_GOALS_SETTING_STARTED',
  MANAGERS_PERFORMANCE_GOALS_SETTING_ENDING_SOON = 'MANAGERS_PERFORMANCE_GOALS_SETTING_ENDING_SOON',
  EMPLOYEES_PERFORMANCE_GOALS_SETTING_STARTED = 'EMPLOYEES_PERFORMANCE_GOALS_SETTING_STARTED',
  EMPLOYEES_PERFORMANCE_GOALS_SETTING_ENDING_SOON = 'EMPLOYEES_PERFORMANCE_GOALS_SETTING_ENDING_SOON',
  PERFORMANCE_GOAL_ASSIGNED = 'PERFORMANCE_GOAL_ASSIGNED',
  ADMIN_UPLOADED_DOCUMENT_IN_YOUR_PROFILE = 'ADMIN_UPLOADED_DOCUMENT_IN_YOUR_PROFILE',
  ADMIN_TIME_OFF_REQUEST_ON_BEHALF = 'ADMIN_TIME_OFF_REQUEST_ON_BEHALF',
}

type UserMapType = {
  [UserNotificationTypes.TIME_OFF_REQUEST]: {
    employee_id: string;
    request_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [UserNotificationTypes.NEW_ANNOUNCEMENT]: {
    announcement_id: string;
    title: string;
  };
  [UserNotificationTypes.APPROVED_TIME_OFF_REQUEST]: {
    request_id: string;
  };
  [UserNotificationTypes.REJECTED_TIME_OFF_REQUEST]: {
    request_id: string;
  };
  [UserNotificationTypes.ADMIN_TIME_OFF_REQUEST_ON_BEHALF]: {
    request_id: string;
  };
  [UserNotificationTypes.CHANGED_REPORTING_MANAGER]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [UserNotificationTypes.NEW_TEAM_MEMBER]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [UserNotificationTypes.EMPLOYEE_END_HIRE]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [UserNotificationTypes.EMPLOYEE_END_HIRE_SOON]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [UserNotificationTypes.WORK_ANNIVERSARY]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    start_date: string;
    status: EmployeeStatus;
  };
  [UserNotificationTypes.CANCELED_TIME_OFF_REQUEST]: {
    request_id: string;
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [UserNotificationTypes.CANCELATION_REQUEST_APPROVED]: {
    request_id: string;
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [UserNotificationTypes.MY_TIME_OFF_CANCELATION_REJECTED]: {
    request_id: string;
  };
  [UserNotificationTypes.MY_TIME_OFF_CANCELED]: {
    request_id: string;
  };
  [UserNotificationTypes.EMPLOYEE_BIRTHDAY]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    date: string;
  };
  [UserNotificationTypes.EMPLOYEE_HIRING_STARTED]: {
    employee_id: string;
    first_name: string;
    last_name: string;
    img_url: string;
    status: EmployeeStatus;
  };
  [UserNotificationTypes.MANAGERS_PERFORMANCE_GOALS_SETTING_STARTED]: {
    cycle_id: string;
  };
  [UserNotificationTypes.MANAGERS_PERFORMANCE_GOALS_SETTING_ENDING_SOON]: {
    cycle_id: string;
  };
  [UserNotificationTypes.COMPANY_DOCUMENTS_CREATE]: {};
  [UserNotificationTypes.EMPLOYEES_PERFORMANCE_GOALS_SETTING_STARTED]: {
    cycle_id: string;
  };
  [UserNotificationTypes.EMPLOYEES_PERFORMANCE_GOALS_SETTING_ENDING_SOON]: {
    cycle_id: string;
  };
  [UserNotificationTypes.ADMIN_UPLOADED_DOCUMENT_IN_YOUR_PROFILE]: {
    employee_id: string;
  };
  [UserNotificationTypes.PERFORMANCE_GOAL_ASSIGNED]: {
    cycle_id: string;
    goal_id: string;
  };
};

type UserNotificationType<T extends keyof UserMapType> = {
  id: string;
  type: T | `${T}`;
  payload: UserMapType[T];
  created_at: string;
  seen: boolean;
};

export type UserNotification =
  | UserNotificationType<UserNotificationTypes.TIME_OFF_REQUEST>
  | UserNotificationType<UserNotificationTypes.NEW_ANNOUNCEMENT>
  | UserNotificationType<UserNotificationTypes.APPROVED_TIME_OFF_REQUEST>
  | UserNotificationType<UserNotificationTypes.REJECTED_TIME_OFF_REQUEST>
  | UserNotificationType<UserNotificationTypes.CHANGED_REPORTING_MANAGER>
  | UserNotificationType<UserNotificationTypes.NEW_TEAM_MEMBER>
  | UserNotificationType<UserNotificationTypes.EMPLOYEE_END_HIRE>
  | UserNotificationType<UserNotificationTypes.EMPLOYEE_END_HIRE_SOON>
  | UserNotificationType<UserNotificationTypes.WORK_ANNIVERSARY>
  | UserNotificationType<UserNotificationTypes.CANCELED_TIME_OFF_REQUEST>
  | UserNotificationType<UserNotificationTypes.CANCELATION_REQUEST_APPROVED>
  | UserNotificationType<UserNotificationTypes.MY_TIME_OFF_CANCELATION_REJECTED>
  | UserNotificationType<UserNotificationTypes.MY_TIME_OFF_CANCELED>
  | UserNotificationType<UserNotificationTypes.EMPLOYEE_HIRING_STARTED>
  | UserNotificationType<UserNotificationTypes.EMPLOYEE_BIRTHDAY>
  | UserNotificationType<UserNotificationTypes.COMPANY_DOCUMENTS_CREATE>
  | UserNotificationType<UserNotificationTypes.PERFORMANCE_GOAL_ASSIGNED>
  | UserNotificationType<UserNotificationTypes.MANAGERS_PERFORMANCE_GOALS_SETTING_STARTED>
  | UserNotificationType<UserNotificationTypes.MANAGERS_PERFORMANCE_GOALS_SETTING_ENDING_SOON>
  | UserNotificationType<UserNotificationTypes.EMPLOYEES_PERFORMANCE_GOALS_SETTING_STARTED>
  | UserNotificationType<UserNotificationTypes.EMPLOYEES_PERFORMANCE_GOALS_SETTING_ENDING_SOON>
  | UserNotificationType<UserNotificationTypes.ADMIN_UPLOADED_DOCUMENT_IN_YOUR_PROFILE>
  | UserNotificationType<UserNotificationTypes.ADMIN_TIME_OFF_REQUEST_ON_BEHALF>;

/** Common */

export type NotificationType = {
  id: string;
  seen: boolean;
  created_at: string;
  content: React.ReactNode | string;
  img: React.ReactNode;
  action?: Function;
};
