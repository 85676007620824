/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import BellIcon from '@assets/svgs/bell.svg';
import AdminNotificationsList from '@components/admin-notifications/admin-notifications-list/AdminNotificationsList';
import OpenNewTabIcon from '@assets/svgs/open-new-tab.svg';
import useOnClickOutside from '@hooks/useOnClickOutside';
import { getAdminUnseen, getUserUnseen } from '@services/notifications/notification-http.service';
import useFetchData from '@hooks/useFetchData';
import { AdminUnseen } from '@services/notifications/type';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import useEventSubscriber from '@hooks/useEventSubscriber';
import { CustomEventTypes } from '@services/EventBus';
import RoutesEnum from '@constants/routes.enum';
import * as Sentry from '@sentry/nextjs';
import useWindowDimensions from '@hooks/useWindowDimentions';
import usePath from '@hooks/usePath';
import { useRouter } from 'next/router';
import { WindowSizeEnum } from '@constants/window-size';
import { UserTypeEnum } from '@models/user/auth';
import { AuthContext } from '@contexts/AuthContext';
import UserNotificationsList from '@components/user-notifications/user-notifications-list/UserNotificationsList';
import useAccountSuspended from '@hooks/useAccountSuspended';
import styles from './NotificationsDropdown.module.scss';

const REQUEST_INTERVAL = 60000;

const NotificationsDropdown: React.FC = () => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const { user } = useContext(AuthContext);
  const accountSuspended = useAccountSuspended();

  const { width } = useWindowDimensions();
  const isMobile = width! < WindowSizeEnum.Mobile;

  const { getPathWithPrefix } = usePath();
  const router = useRouter();

  const [open, toggle] = useReducer((p) => !p, false);
  const [openedSinceLastRevalidate, setOpenedSinceLastRevalidate] = useState<boolean>(false);

  const closeNotificationPanel = useCallback(() => {
    if (open) {
      toggle();
    }
  }, [open]);

  useEventSubscriber(CustomEventTypes.CLOSE_ADMIN_NOTIFICATIONS, () => closeNotificationPanel());

  /** Fetching unseen notifications every minute
   *  Pause polling while open
   */

  const fetcher = useCallback(async () => {
    if (!accountSuspended) {
      const announcements = user?.type === UserTypeEnum.EMPLOYER ? await getAdminUnseen() : await getUserUnseen();
      return announcements;
    }
    return {};
  }, []);

  const { data, toggleRevalidate } = useFetchData<AdminUnseen>(fetcher, () =>
    Sentry.captureMessage('Unseen notifications error'),
  );

  useEffect(() => {
    if (!accountSuspended) {
      const interval = setInterval(() => {
        if (!open) {
          toggleRevalidate();
          setOpenedSinceLastRevalidate(false);
        }
      }, REQUEST_INTERVAL);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [open, accountSuspended]);

  // Close notifications window when clicking outsite

  const ref = useRef<HTMLDivElement>(null);
  const onClickOutside = useCallback(() => closeNotificationPanel(), [closeNotificationPanel]);
  useOnClickOutside(ref, onClickOutside);

  // Remove badge in any when notification window opens

  const handleOnClick = (e: any) => {
    e.preventDefault();
    if (accountSuspended) return;
    toggle();
    setOpenedSinceLastRevalidate(true);
  };

  const handleLinkClick = () => {
    if (user?.type === UserTypeEnum.EMPLOYER) {
      router.push(getPathWithPrefix(RoutesEnum.ADMIN_NOTIFICATIONS));
    } else {
      router.push(getPathWithPrefix(RoutesEnum.EMPLOYEE_NOTIFICATIONS));
    }
    closeNotificationPanel();
  };

  return (
    <div ref={ref}>
      <button
        type="button"
        className={`no-button-styles ${styles.notificationBtn} ${open ? styles.open : ''}`}
        onClick={isMobile ? handleLinkClick : handleOnClick}
        data-testid="nav-dropdown"
      >
        {!!data?.count && !openedSinceLastRevalidate && (
          <span className={styles.notificationBagde} data-testid="red-badge" />
        )}
        <BellIcon className={styles.bellIcon} />
      </button>
      {open && (
        <div className={styles.notificationsWrap} data-testid="notifications-panel">
          <div className={styles.notificationsHeader}>
            <h5 className={`h5 uppercase title-dark ${styles.heading}`}>{translate('notifications.notifications')}</h5>
            <button type="button" className="no-button-styles" onClick={handleLinkClick}>
              <OpenNewTabIcon className={styles.newTabIcon} data-testid="redirect-btn" />
            </button>
          </div>
          <div className={`scroll-bar ${styles.listWrap}`}>
            {user?.type === UserTypeEnum.EMPLOYER ? <AdminNotificationsList /> : <UserNotificationsList />}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsDropdown;
