import GroupPositions from '@models/position-titles/groupPositionTitles';
import { hrmsBackEndUrl, hrmsInstance } from '@utils/http-client';
import { CreatePositionTitleDto, GetPositionTitleEmployees, UpdatePositionTitleDto } from '@models/position-titles';

const positionsTitles = `${hrmsBackEndUrl}/titles`;

export const createNewPositionTitle = (body: CreatePositionTitleDto): Promise<void> => {
  return hrmsInstance.post(`${positionsTitles}`, body);
};

export const updatePositionTitle = (id: string, body: UpdatePositionTitleDto): Promise<void> => {
  return hrmsInstance.put(`${positionsTitles}/${id}`, body);
};

export const getAllPositionsTitles = (page: number, psize: number): Promise<GroupPositions> => {
  return hrmsInstance.get(`${positionsTitles}/list`, { params: { page, psize } });
};

export const getPositionTitleEmployees = (positionTitleId: string): Promise<GetPositionTitleEmployees[]> => {
  return hrmsInstance.get(`${positionsTitles}/${positionTitleId}/employees`);
};

export const addEmployeesToPositionTitle = (positionTitleId: string, employees: string[]): Promise<void> => {
  return hrmsInstance.post(`${positionsTitles}/${positionTitleId}/employees/add`, { employees });
};
