// eslint-disable-next-line import/prefer-default-export
export enum WindowSizeEnum {
  NotSupported = NaN,
  MobileXSmall = 295,
  Mobile = 480,
  Tablet = 576,
  DesktopMedium = 768,
  DesktopLarge = 992,
  DesktopXLarge = 1200,
}
