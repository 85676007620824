import React, { useContext } from 'react';
import AddEmployeeIcon from '@assets/svgs/employee.svg';
import RequestTimeOffIcon from '@assets/svgs/calendar.svg';
import AddAnnouncementIcon from '@assets/svgs/megaphone-icon.svg';
import ImportIcon from '@assets/svgs/upload-icon.svg';
import AddPositionIcon from '@assets/svgs/badge-icon.svg';
import AddDeptIcon from '@assets/svgs/building-icon2.svg';
import AddLocationIcon from '@assets/svgs/location.svg';
import AddTimeOffIcon from '@assets/svgs/time-off-type-2.svg';
import AddHolidayIcon from '@assets/svgs/time-off-calendar.svg';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { ModalContext } from '@contexts/ModalContext';
import CreateEmployeeWizard from '@components/employees/admin/create-employee-form-wizard/CreateEmployeeWizard';
import RequestTimeOff from '@components/leaves/request-time-off/RequestTimeOff';
import AnnouncementForm from '@components/announcements/announcement-form/AnnouncementForm';
import { useRouter } from 'next/router';
import usePath from '@hooks/usePath';
import RoutesEnum from '@constants/routes.enum';
import PositionTitleForm from '@components/position-titles/position-title-form/PositionTitleForm';
import DepartmentForm from '@components/departments/department-form/DepartmentForm';
import LocationForm from '@components/locations/location-form/LocationForm';
import HolidayForm from '@components/settings/admin/holidays/holiday-form/HolidayForm';
import QuickActionBtn from './quick-action-btn/QuickActionBtn';
import styles from './QuickActionsMenu.module.scss';

type QuickActionsMenuProps = {
  onItemClick: Function;
};

const QuickActionsMenu = ({ onItemClick }: QuickActionsMenuProps) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const router = useRouter();
  const { getPathWithPrefix } = usePath();
  const { renderModal, setVisible } = useContext(ModalContext);

  const onItemAction = (action: Function) => () => {
    onItemClick();
    action();
  };

  const redirectToRequests = () => router.push(getPathWithPrefix(RoutesEnum.ADMIN_TIME_OFF_REQUESTS));

  const onAddEmployee = onItemAction(() => renderModal(<CreateEmployeeWizard />));
  const onAddTimeOffRequest = onItemAction(() =>
    renderModal(<RequestTimeOff setVisible={setVisible} onSubmit={redirectToRequests} />),
  );
  const onAddAnnouncement = onItemAction(() => renderModal(<AnnouncementForm setVisible={setVisible} />));
  const importEmployees = onItemAction(() => router.push(getPathWithPrefix(RoutesEnum.ADMIN_EMPLOYEES_IMPORT)));
  const onAddPosition = onItemAction(() => renderModal(<PositionTitleForm setVisible={setVisible} />));
  const onAddDepartment = onItemAction(() => renderModal(<DepartmentForm setVisible={setVisible} />));
  const onAddLocation = onItemAction(() => renderModal(<LocationForm setVisible={setVisible} />));
  const onAddTimeOffType = onItemAction(() =>
    router.push(getPathWithPrefix(`${RoutesEnum.ADMIN_SETTING_TIME_OFF}?open=true`)),
  );
  const onAddHoliday = onItemAction(() => renderModal(<HolidayForm setVisible={setVisible} />));

  return (
    <ul className={styles.quickActionsList} data-testid="quick-actions-menu">
      <QuickActionBtn
        icon={AddEmployeeIcon}
        name={translate('quick_actions.add_employee')}
        onClick={onAddEmployee}
        data-testid="add-employee"
      />
      <QuickActionBtn
        icon={RequestTimeOffIcon}
        name={translate('quick_actions.request_time_of')}
        onClick={onAddTimeOffRequest}
        data-testid="add-request"
      />
      <QuickActionBtn
        icon={AddAnnouncementIcon}
        name={translate('quick_actions.create_post')}
        onClick={onAddAnnouncement}
        data-testid="add-announcement"
      />
      <QuickActionBtn
        icon={ImportIcon}
        name={translate('quick_actions.import_employees')}
        onClick={importEmployees}
        data-testid="import"
      />
      <QuickActionBtn
        icon={AddPositionIcon}
        name={translate('quick_actions.add_position_title')}
        onClick={onAddPosition}
        data-testid="add-position"
      />
      <QuickActionBtn
        icon={AddDeptIcon}
        name={translate('quick_actions.add_dep')}
        onClick={onAddDepartment}
        data-testid="add-dept"
      />
      <QuickActionBtn
        icon={AddLocationIcon}
        name={translate('quick_actions.add_location')}
        onClick={onAddLocation}
        data-testid="add-location"
      />
      <QuickActionBtn
        icon={AddTimeOffIcon}
        name={translate('quick_actions.add_time_off_type')}
        onClick={onAddTimeOffType}
        data-testid="add-time-off-type"
      />
      <QuickActionBtn
        icon={AddHolidayIcon}
        name={translate('quick_actions.add_holiday')}
        onClick={onAddHoliday}
        data-testid="add-holiday"
      />
    </ul>
  );
};

export default QuickActionsMenu;
