import RecaptchaActionsEnum from '@constants/recaptcha-actions.enum';
import getConfig from 'next/config';
import * as Sentry from '@sentry/nextjs';

const {
  publicRuntimeConfig: { GOOGLE_RECAPTCHA_KEY },
} = getConfig();

const requestRecaptchaToken = (action: RecaptchaActionsEnum) => {
  if (!GOOGLE_RECAPTCHA_KEY) {
    return '';
  }
  return new Promise((res, rej) => {
    window.grecaptcha.ready(async () => {
      try {
        const token: string = await window.grecaptcha.execute(GOOGLE_RECAPTCHA_KEY, { action });
        res(token);
      } catch (err) {
        Sentry.captureException(err);
        rej(err);
      }
    });
  });
};

export default requestRecaptchaToken;
