import { DispatchWithoutAction, useCallback, useEffect, useReducer, useState } from 'react';
import useNotification from '@hooks/notification/useNotification';
import { useTranslation } from 'next-i18next';
import StateEnum from '@constants/shared/state.enum';

function useFetchDataInfinite<T extends any | undefined>(
  fetcher: any,
  reachedEnd: boolean,
): {
  data?: T[];
  toggleNextFetch: DispatchWithoutAction;
  loading: boolean;
} {
  const [data, setData] = useState<T[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextFetch, toggleNextFetch] = useReducer((p) => !p, true);
  const { t: translate } = useTranslation();

  const [generalErrorNotification] = useNotification(
    StateEnum.ERROR,
    translate('notification:status.error'),
    translate('common:messages.error.something_went_wrong'),
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response: T[] = await fetcher();

      setData(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      generalErrorNotification();
    }
  }, [fetcher]);

  const fetchMoreData = useCallback(async () => {
    try {
      setLoading(true);
      const response: T[] = await fetcher();

      if (!!data?.length && !!response.length) setData([...data, ...response]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      generalErrorNotification();
    }
  }, [data, fetcher]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!loading && !reachedEnd) {
        fetchMoreData();
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [nextFetch, reachedEnd]);

  return { data, loading, toggleNextFetch };
}

export default useFetchDataInfinite;
