import React from 'react';
import { Button as BaseButton, ButtonProps } from 'antd';
import { ButtonType as BaseButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import PropTypes from 'prop-types';

type ButtonType = BaseButtonType | 'primary-success';
type ButtonSize = SizeType | 'xsmall';
type CustomButtonProps = Omit<ButtonProps, 'type' | 'size'> &
  React.RefAttributes<HTMLElement> & { type?: ButtonType; size?: ButtonSize };

const Button = (props: CustomButtonProps) => {
  return <BaseButton {...(props as any)} />;
};

export interface UiButtonProps extends CustomButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const UiButton: React.FC<UiButtonProps> = ({ children, onClick, className, ...rest }) => {
  return (
    <Button
      onClick={onClick}
      {...rest}
      className={`ui-button ${className} ${rest.size === 'xsmall' ? 'ant-btn-xsm' : ''}`}
    >
      {children}
    </Button>
  );
};

UiButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

UiButton.defaultProps = {
  onClick: () => {},
  children: '',
  className: '',
};

Button.defaultProps = {
  type: '',
  size: '',
};

export default UiButton;
