import React from 'react';
import UiTag, { UiTagType } from '@components/ui/tag/UiTag';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { Tooltip } from 'antd';

type StatusInfoTagProps = {
  status: string;
  tooltip?: string;
};
const StatusInfoTag = ({ status, tooltip }: StatusInfoTagProps) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const getTypeInfo = (type: string): { typeName: string; typeColor: UiTagType } => {
    switch (type) {
      case 'soon':
        return {
          typeName: translate('status_info_tag_soon'),
          typeColor: UiTagType.PURPLE,
        };
      case 'new':
        return {
          typeName: translate('status_info_tag_new'),
          typeColor: UiTagType.SUCCESS,
        };
      case 'beta':
        return {
          typeName: translate('status_info_tag_beta'),
          typeColor: UiTagType.ERROR,
        };
      default:
        return {
          typeName: '',
          typeColor: UiTagType.DEFAULT,
        };
    }
  };

  return (
    <>
      {tooltip ? (
        <Tooltip placement="topLeft" mouseEnterDelay={0.3} title={tooltip}>
          <>
            <UiTag className="uppercase" type={getTypeInfo(status).typeColor}>
              {getTypeInfo(status).typeName}
            </UiTag>
          </>
        </Tooltip>
      ) : (
        <UiTag className="uppercase" type={getTypeInfo(status).typeColor}>
          {getTypeInfo(status).typeName}
        </UiTag>
      )}
    </>
  );
};

export default StatusInfoTag;
