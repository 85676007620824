import React from 'react';

// Don't overuse this

export enum AuthLayoutErrorTypes {
  EXPIRED_TOKEN = 'expired_token',
  GENERIC_ERROR = 'generic_error',
  INVALID_CREDENTIALS = 'invalid_credentials',
  EMAIL_EXISTS = 'email_exists',
  PORTAL_NAME_EXISTS = 'portal_name_exists',
}

type AuthLayoutContextType = {
  errorMessage?: AuthLayoutErrorTypes;
  setErrorMessage: (error?: AuthLayoutErrorTypes) => void;
};

const AuthLayoutContext = React.createContext<AuthLayoutContextType>({
  errorMessage: undefined,
  setErrorMessage: () => {},
});

const AuthLayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [errorMessage, setErrorMessage] = React.useState<AuthLayoutErrorTypes | undefined>(undefined);
  return <AuthLayoutContext.Provider value={{ errorMessage, setErrorMessage }}>{children}</AuthLayoutContext.Provider>;
};

export { AuthLayoutProvider, AuthLayoutContext };
