/* eslint-disable react/require-default-props */
import React, { Children, ReactChild, useState } from 'react';
import Step from '@components/ui/stepper/Step';
import { StepperContext } from './StepperContext';

type StepperType = {
  children: React.ReactChild[] | React.ReactNode[];
  onFinish?: () => void;
};

const Stepper = React.forwardRef((props: StepperType, ref) => {
  const [step, setStep] = useState<number>(1);
  const { children, onFinish } = props;
  const totalSteps = Children.count(
    children.filter((child) => {
      return React.isValidElement(child);
    }),
  );

  const next = React.useCallback(() => {
    if (step === totalSteps) {
      onFinish?.();
    } else {
      setStep((oldStep) => oldStep + 1);
    }
  }, [step, totalSteps]);

  const back = React.useCallback(() => setStep((oldStep) => oldStep - 1), [step, totalSteps]);
  const reset = () => setStep(1);
  const goToStep = (value: number) => setStep(value);

  const value = React.useMemo(() => ({ step, totalSteps, next, back, reset, goToStep }), [step, totalSteps]);
  React.useImperativeHandle(
    ref,
    () => {
      return value;
    },
    [value],
  );

  const amendedChildren = React.Children.toArray(children).reduce((acc: ReactChild[], child, index) => {
    if (!React.isValidElement(child)) return acc;
    acc.push(
      React.cloneElement(child as React.ReactElement<React.ComponentProps<typeof Step>>, {
        step: index + 1,
      }),
    );
    return acc;
  }, []);

  return <StepperContext.Provider value={value}>{amendedChildren}</StepperContext.Provider>;
});

Stepper.displayName = 'Stepper';

export default Stepper;
