import React, { useState } from 'react';
import { Form, Input } from 'antd';
import UiButton from '@components/ui/button/UiButton';
import { CreateEmployeeDto } from '@models/employee';
import useNotification from '@hooks/notification/useNotification';
import { createNewEmployee } from '@services/employee/employee-http.service';
import eventBus, { CustomEventTypes } from '@services/EventBus';
import ErrorMessagesEnum from '@constants/error-messages.enum';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import styles from './CreateEmployeeForm.module.scss';

type CreateEmployeeFormProps = {
  onSubmit?: Function;
  setEmployeeId: (visible: string) => void;
};

const CreateEmployeeForm: React.FC<CreateEmployeeFormProps> = ({ onSubmit, setEmployeeId }) => {
  const { useTypedTranslation } = makeTypedTranslation('common', 'forms', 'notification');
  const { t: translate } = useTypedTranslation('common');
  const { t: translateForms } = useTypedTranslation('forms');
  const { t: translateNotification } = useTypedTranslation('notification');

  const [form] = Form.useForm();
  const [nameField, setNameField] = useState('');
  const [emailField, setEmailField] = useState('');
  const [lastNameField, setLastNameField] = useState('');

  const [successSubmitNotification] = useNotification(
    'success',
    translateNotification('status.success'),
    translate('create_employee.employee_created'),
  );
  const [errorNotification] = useNotification('error', translateNotification('status.error'));

  const onFinish = async (values: CreateEmployeeDto) => {
    if (values) {
      try {
        const employee = await createNewEmployee(
          values.first_name.trim().replace(/\s\s+/g, ' '),
          values.last_name.trim().replace(/\s\s+/g, ' '),
          values.email,
        );
        setEmployeeId(employee.id);
        successSubmitNotification();
        form.resetFields();
        setNameField('');
        setLastNameField('');
        setEmailField('');
        onSubmit?.();
        eventBus.getInstance().dispatch(CustomEventTypes.EMPLOYEES_LIST_UPDATED);
      } catch (e: any) {
        if (e.message === ErrorMessagesEnum.EMAIL_EXISTS) {
          errorNotification(undefined, translate('messages.error.email_exists'));
        } else {
          errorNotification(undefined, translate('messages.error.something_went_wrong'));
        }
      }
    }
  };

  return (
    <>
      <h5>{translate('create_employee.new_employee')}</h5>
      <Form
        form={form}
        name="createEmployee"
        layout="vertical"
        size="small"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        className={styles.wrapModal}
      >
        <div className={styles.formFields}>
          <div className={styles.fullNameFields}>
            <Form.Item
              label={translate('create_employee.first_name')}
              name="first_name"
              rules={[
                { required: true, message: translateForms('required') },
                { whitespace: true, message: translateForms('white_space') },
              ]}
              className={styles.nameField}
            >
              <Input
                placeholder={translate('create_employee.give_name')}
                data-testid="employee-name"
                maxLength={60}
                onChange={(e) => setNameField(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={translate('create_employee.last_name')}
              name="last_name"
              rules={[
                { required: true, message: translateForms('required') },
                { whitespace: true, message: translateForms('white_space') },
              ]}
              className={styles.nameField}
            >
              <Input
                placeholder={translate('create_employee.give_last_name')}
                data-testid="employee-last-name"
                maxLength={60}
                onChange={(e) => setLastNameField(e.target.value)}
              />
            </Form.Item>
          </div>
          <Form.Item
            label={translate('create_employee.account_email')}
            name="email"
            className={styles.emailField}
            rules={[
              {
                type: 'email',
                message: translate('create_employee.please_give_valid_email'),
              },
              {
                required: true,
                message: translate('create_employee.please_give_email'),
              },
            ]}
          >
            <Input
              placeholder={translate('create_employee.give_email')}
              data-testid="employee-email"
              maxLength={60}
              onChange={(e) => setEmailField(e.target.value)}
            />
          </Form.Item>
        </div>
        <Form.Item className={styles.wrapButton}>
          <UiButton
            type="primary"
            size="small"
            htmlType="submit"
            disabled={!nameField || !lastNameField || !emailField}
          >
            {translate('create_employee.create')}
          </UiButton>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateEmployeeForm;
