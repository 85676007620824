// Date format YYYY-MM-DD
const dateFormat: string = 'DD MMM YYYY';
export const dayMonthFormat: string = 'DD MMM';
export const inputDateFormat: string = 'YYYY-MM-DD';

export const emptyDate: string = '--.--.----';

export const emptyBirthday: string = 'DD/MM/YYYY';

export const formatDate = (value: string) => {
  return value.split(' / ').reverse().join('-');
};

export const dateInputFormat = (value: string) => {
  return value.split('-').reverse().join(' / ');
};

export default dateFormat;
