import React from 'react';
import DirectoryIcon from '@assets/svgs/directory-icon.svg';
import RocketIcon from '@assets/svgs/rocket.svg';
import OrgChartIcon from '@assets/svgs/org-chart-icon.svg';
import TimeOffIcon from '@assets/svgs/time-off-type-2.svg';
import UserStarIcon from '@assets/svgs/user-star-icon.svg';
import SmileIcon from '@assets/svgs/smile-icon.svg';
import AuthLayoutError from '@components/layout/auth-layout/auth-layout-error/AuthLayoutError';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import AppFeatureInfo from '@components/layout/auth-layout/account-setup-layout/app-feature-info/AppFeatureInfo';
import { Trans } from 'next-i18next';
import styles from './AccountSetupLayout.module.scss';

type AccountSetupLayoutProps = {
  children: React.ReactNode;
};
const AccountSetupLayout = ({ children }: AccountSetupLayoutProps) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  return (
    <div className={styles.accountSetupContainer}>
      <section className={styles.layoutContentWrap}>
        <div className={styles.layoutContent}>
          <AuthLayoutError />
          {children}
        </div>
      </section>
      <section className={styles.featuresWrap}>
        <h3 className="h3 no-margin primary">
          <Trans t={translate} i18nKey="authLayout.sign_up_main_slogan">
            -
          </Trans>
        </h3>
        <div className={styles.featuresContainer}>
          <AppFeatureInfo icon={DirectoryIcon} title={translate('authLayout.sign_up_app_feature_1')} />
          <AppFeatureInfo icon={OrgChartIcon} title={translate('authLayout.sign_up_app_feature_2')} />
          <AppFeatureInfo icon={UserStarIcon} title={translate('authLayout.sign_up_app_feature_3')} />
          <AppFeatureInfo icon={RocketIcon} title={translate('authLayout.sign_up_app_feature_4')} />
          <AppFeatureInfo icon={TimeOffIcon} title={translate('authLayout.sign_up_app_feature_5')} />
          <AppFeatureInfo icon={SmileIcon} title={translate('authLayout.sign_up_app_feature_6')} />
        </div>
        <p className="medium-caps-bold no-margin primary">{translate('authLayout.sign_up_app_footer')}</p>
      </section>
    </div>
  );
};

export default AccountSetupLayout;
