type MonthsOptionType = { [key: number]: string };

const MONTHS_OPTIONS: MonthsOptionType = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export const monthsOptionsKeys = (month: string) =>
  Object.keys(MONTHS_OPTIONS)[Object.values(MONTHS_OPTIONS).indexOf(month)];

export default MONTHS_OPTIONS;
