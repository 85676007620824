import React, { useCallback, useState } from 'react';
import { Form, Input, Switch } from 'antd';
import StateEnum from '@constants/state.enum';
import UiButton from '@components/ui/button/UiButton';
import useNotification from '@hooks/notification/useNotification';
import eventBus, { CustomEventTypes } from '@services/EventBus';
import dayjs from 'dayjs';
import {
  createAnnouncement,
  getAnnouncement,
  updateAnnouncement,
} from '@services/announcements/announcements-http.service';
import UiDatePicker from '@components/ui/date-picker/UiDatePicker';
import dateFormat, { emptyDate, inputDateFormat } from '@constants/date-format';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import useFetchData from '@hooks/useFetchData';
import { Announcement, AnnouncementStatusEnum } from '@services/announcements/type';
import PageSpin from '@components/ka-ui/page-spin/PageSpin';
import styles from './AnnouncementForm.module.scss';

const { TextArea } = Input;

type AnnouncementFormProps = {
  setVisible: (visible: boolean) => void;
  edit?: boolean;
  id?: string;
};

const PUBLISH_DATE_FORMAT = 'YYYY/MM/DD';

const AnnouncementForm: React.FC<AnnouncementFormProps> = ({ setVisible, edit, id }) => {
  const [form] = Form.useForm();
  const { useTypedTranslation } = makeTypedTranslation('common', 'forms', 'notification');
  const { t: translate } = useTypedTranslation('common');
  const { t: translateForms } = useTypedTranslation('forms');
  const { t: translateNotification } = useTypedTranslation('notification');

  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSubmit(hasErrors);
  };

  const [errorNotification] = useNotification(StateEnum.ERROR, translateNotification('status.error'));
  const [successNotification] = useNotification(StateEnum.SUCCESS, translateNotification('status.success'));

  const announcementFetcher = useCallback(async () => {
    if (!id) return null;
    const announcement = await getAnnouncement(id as string);
    return announcement;
  }, []);

  const { data: initialValues, loading } = useFetchData<Announcement>(announcementFetcher);

  const onFinish = (onFinishAction: any) => async (values: any) => {
    try {
      await onFinishAction(values);
      setVisible(false);
      eventBus.getInstance().dispatch(CustomEventTypes.ANNOUNCEMENTS_UPDATED);
    } catch (e: any) {
      errorNotification(undefined, translate('messages.error.something_went_wrong'));
    }
  };

  const onCreate = onFinish(async (values: any) => {
    await createAnnouncement({
      title: values.title,
      content: values.content,
      tags: [],
      publish_date: values.publish_date
        ? dayjs(values.publish_date).format(inputDateFormat)
        : dayjs().format(inputDateFormat),
      send_email: values.send_email,
    });
    successNotification(undefined, translate('create_announcement.announcement_created'));
  });

  const onUpdate = onFinish(async (values: any) => {
    await updateAnnouncement(id!, {
      title: values.title,
      content: values.content,
      tags: [],
      publish_date: values.publish_date
        ? dayjs(values.publish_date).format(inputDateFormat)
        : dayjs().format(inputDateFormat),
    });
    successNotification(undefined, translate('create_announcement.announcement_updated'));
  });

  const today = dayjs();

  const disabledDate = (current: any) => {
    return current.isBefore(dayjs().subtract(1, 'day'));
  };

  return (
    <>
      {edit && loading ? (
        <PageSpin />
      ) : (
        <Form
          form={form}
          name="holidayForm"
          layout="vertical"
          size="small"
          onFieldsChange={handleFormChange}
          initialValues={{
            ...initialValues,
            publish_date: initialValues?.publish_date
              ? dayjs(initialValues?.publish_date, PUBLISH_DATE_FORMAT)
              : dayjs(today, PUBLISH_DATE_FORMAT),
          }}
          onFinish={edit ? onUpdate : onCreate}
          autoComplete="off"
        >
          <h5 className="h5 uppercase">
            {edit ? translate('create_announcement.edit_post') : translate('create_announcement.new_post')}
          </h5>
          <Form.Item
            label={translate('create_announcement.title')}
            name="title"
            rules={[
              { required: true, message: translateForms('required_minimal') },
              { whitespace: true, message: translateForms('white_space') },
              {
                max: 60,
                message: translateForms('max_60_chars_error'),
              },
            ]}
          >
            <Input placeholder={translate('create_announcement.post_title')} data-testid="post-title" maxLength={60} />
          </Form.Item>
          <Form.Item
            label={translate('create_announcement.content')}
            name="content"
            className={styles.contentField}
            rules={[
              { whitespace: true, message: translateForms('white_space') },
              {
                max: 2000,
                message: translateForms('max_2000_chars_error'),
              },
            ]}
          >
            <TextArea
              rows={10}
              showCount
              maxLength={2000}
              placeholder={translate('create_announcement.type_content')}
              data-testid="post-content"
            />
          </Form.Item>
          <Form.Item label={translate('create_announcement.publish_date')} className={styles.dateField}>
            <p className="small-body-text">{translate('create_announcement.publish_date_subtitle')}</p>
            <Form.Item name="publish_date">
              {/* @ts-ignore */}
              <UiDatePicker
                placeholder={emptyDate}
                format={dateFormat}
                inputReadOnly
                disabledDate={disabledDate}
                showToday={false}
                data-testid="post-publish-date"
                disabled={initialValues?.announcement_type === AnnouncementStatusEnum.PUBLISHED}
              />
            </Form.Item>
          </Form.Item>
          {!edit && (
            <div className={styles.inlineSwitch}>
              <Form.Item className="no-margin" valuePropName="checked" name="send_email">
                <Switch data-testid="email-switch" />
              </Form.Item>
              <span className={styles.switchTitle}>{translate('create_announcement.send_post_via_email')}</span>
            </div>
          )}
          <Form.Item>
            <div className={styles.actionButtons}>
              <UiButton
                type="primary"
                size="small"
                htmlType="submit"
                disabled={disabledSubmit}
                data-testid="submit-button"
              >
                {edit ? translate('create_announcement.save') : translate('create_announcement.create')}
              </UiButton>
              <UiButton size="small" onClick={() => setVisible(false)}>
                {translate('create_announcement.cancel')}
              </UiButton>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default AnnouncementForm;
