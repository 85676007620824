import React, { useContext } from 'react';
import { AuthContext } from '@contexts/AuthContext';
import { SubscriptionType } from '@models/user/auth';
import ExpiredFreeTrialCountdown from '@components/layout/subscription-banner/ExpiredFreeTrialCountdown';
import FreeTrialCountdown from '@components/layout/subscription-banner/FreeTrialCountdown';
import styles from './SubscriptionBanner.module.scss';

const SubscriptionBanner = () => {
  const { user } = useContext(AuthContext);
  return (
    <div className={styles.wrap}>
      {user?.subscription?.type === SubscriptionType.trial && user?.subscription?.days_remaining < 0 ? (
        <ExpiredFreeTrialCountdown />
      ) : (
        <FreeTrialCountdown />
      )}
    </div>
  );
};

export default SubscriptionBanner;
