import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

/**
 * USE_MOCKED_API env variable is set to true on dev:mock npm-script
 * so the FE_APP_URL mock server is used. Otherwise, it connects to
 * the BACKEND_URL
 */
const {
  publicRuntimeConfig: { BACKEND_URL },
} = getConfig();

export const hrmsBackEndUrl = `${BACKEND_URL}/api/v0/hrms`;
export const hrpriorBackendUrl = `${BACKEND_URL}/api/v1/ats`;

const configureAxiosInstance = (baseURL: string): AxiosInstance => {
  const instance = axios.create({
    baseURL,
  });

  const responseInterceptor = (response: AxiosResponse) => response.data;

  const errorInterceptor = (error: AxiosError) => {
    if (error?.response?.status === 404) {
      /** Manual message for not found pages */
      const url = error?.config?.url ? error?.config?.url : '';
      Sentry.captureMessage(`Page not found ${url}`);
    } else if (error?.response?.status !== 422) {
      /** Error status that we do not want the Sentry to capture */
      Sentry.captureException(error);
    }

    /**
     * In case of 403 Forbidden reload window to re-authenticate
     */
    if (error?.response?.status === 403) {
      window.location.reload();
      return new Promise(() => {});
    }

    /**
     * Return the data for all 422, 428, 404, 400 statuses in order to
     * catch the messages from data
     */
    if (
      error?.response?.status === 422 ||
      error?.response?.status === 428 ||
      error?.response?.status === 404 ||
      error?.response?.status === 400
    ) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  };

  instance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return instance;
};

export const hrmsInstance: AxiosInstance = configureAxiosInstance(`${hrmsBackEndUrl}`);
export const hrpriorInstance: AxiosInstance = configureAxiosInstance(`${hrpriorBackendUrl}`);
