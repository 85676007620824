import React from 'react';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import QnaIcon from '@assets/svgs/qna-icon.svg';
import EnvelopeIcon from '@assets/svgs/envelope-icon.svg';
import Link from 'next/link';
import classNameCompose from '@utils/classname-compose';
import styles from './ContactDropdownContent.module.scss';

const CUSTOMER_CARE_EMAIL = 'customercare@kariera.gr';
const QNA_URL = 'https://kpeople.helpscoutdocs.com';

const ContactDropdownContent: React.FC = () => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  return (
    <div className={styles.contentWrap}>
      <h6 className="h6 title-dark">{translate('need_assistance')}</h6>
      <Link href={QNA_URL} target="_blank" data-testid="qna-link">
        <span className={classNameCompose(styles.menuItem, styles.firstItem)}>
          <QnaIcon className={styles.itemIcon} />
          <p className="medium-caps-bold">{translate('qna')}</p>
        </span>
      </Link>
      <Link href={`mailto: ${CUSTOMER_CARE_EMAIL}`}>
        <span className={styles.menuItem}>
          <EnvelopeIcon className={styles.itemIcon} />
          <p className={styles.contactEmail}>{CUSTOMER_CARE_EMAIL}</p>
        </span>
      </Link>
    </div>
  );
};

export default ContactDropdownContent;
