import { hrmsInstance, hrmsBackEndUrl } from '@utils/http-client';
import { UserAuthType } from '@models/user/auth';
import { GetTenantBasicInfo, UpdateAdminDetails, UpdateCompanyInfo } from '@services/tenants/type';
import { RcFile } from 'antd/lib/upload';

const tenants = `${hrmsBackEndUrl}/tenants`;

// eslint-disable-next-line import/prefer-default-export
export const startFresh = () => {
  return hrmsInstance.post(`${tenants}/start-fresh`);
};

export const updateCompanyInfo = (body: UpdateCompanyInfo): Promise<UserAuthType> => {
  return hrmsInstance.put(`${tenants}/company-info`, body);
};

export const updateAdminDetails = (body: UpdateAdminDetails): Promise<UserAuthType> => {
  return hrmsInstance.put(`${tenants}/admin-details`, body);
};

export const uploadTenantPhoto = (file: RcFile): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file);
  return hrmsInstance.post(`${tenants}/upload-photo`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getTenantBasicInfo = (): Promise<GetTenantBasicInfo> => {
  return hrmsInstance.get(`${tenants}/basic-info`);
};
