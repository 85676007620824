import React from 'react';
import PropTypes from 'prop-types';
import styles from './NotificationIcon.module.scss';

type NotificationIconProps = {
  className?: string;
  icon: string;
};

const NotificationIcon: React.FC<NotificationIconProps> = ({ className, icon }) => {
  const Icon = icon as any;

  return (
    <div className={styles.iconContainer}>
      <Icon className={`${styles.icon} ${className}`} data-testid="icon" />
    </div>
  );
};

NotificationIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

NotificationIcon.defaultProps = {
  className: '',
};

export default NotificationIcon;
