import { Step, Stepper } from '@components/ui/stepper';
import { StepperContextProps } from '@components/ui/stepper/StepperContext';
import React, { useCallback, useState } from 'react';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import RoutesEnum from '@constants/routes.enum';
import usePath from '@hooks/usePath';
import { useRouter } from 'next/router';
import CreateEmployeeForm from '../create-employee-form/CreateEmployeeForm';
import HireEmployeeForm from '../hire-employee-form/HireEmployeeForm';
import styles from './CreateEmployeeWizard.module.scss';

const CreateEmployeeWizard = () => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const router = useRouter();
  const { getPathWithPrefix } = usePath();

  const [employeeId, setEmployeeId] = useState<string>('');

  const [stepperContext, setStepperContext] = useState<StepperContextProps>();
  const stepperRef = useCallback((ref: StepperContextProps) => {
    if (ref) setStepperContext(ref);
  }, []);

  const redirectToEmployeeProfile = () => router.push(getPathWithPrefix(`${RoutesEnum.ADMIN_EMPLOYEES}/${employeeId}`));

  return (
    <div className={styles.wizardWrap}>
      <h6 className={`medium-caps-bold uppercase subheading ${styles.steps}`}>
        {translate('create_employee.step', {
          stepNumber: stepperContext?.step!,
          totalSteps: stepperContext?.totalSteps!,
        })}
      </h6>
      <Stepper ref={(ref: StepperContextProps) => stepperRef(ref)} onFinish={() => redirectToEmployeeProfile()}>
        <Step step={1} stepRender={(_, next) => <CreateEmployeeForm onSubmit={next} setEmployeeId={setEmployeeId} />} />
        <Step
          step={2}
          stepRender={(_, next) => <HireEmployeeForm withSteps employeeId={employeeId} onSubmit={next} />}
        />
      </Stepper>
    </div>
  );
};

export default CreateEmployeeWizard;
