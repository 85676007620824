import React, { useContext } from 'react';
import UserIcon from '@assets/svgs/user-icon.svg';
import PropTypes from 'prop-types';
import Image from 'next/legacy/image';
import { getInitialsFromUserFullName } from '@utils/user/user-helpers';
import { AuthContext } from '@contexts/AuthContext';
import styles from './NavAvatar.module.scss';

export type AvatarProps = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  onKeyboard?: React.KeyboardEventHandler<HTMLDivElement>;
};

const NavAvatar: React.FC<AvatarProps> = ({ onClick, onKeyboard }) => {
  const { user } = useContext(AuthContext);

  const getProfileImageComponent = (profileImage: string) => (
    <div className={styles.imageContainer}>
      <Image
        onClick={onClick}
        alt="user-profile-image"
        src={profileImage}
        // @ts-ignore
        width="100%"
        // @ts-ignore
        height="100%"
        objectFit="cover"
        priority
        className={styles.img}
        data-testid="image-icon"
      />
    </div>
  );

  const getUserInitialsComponent = (firstName: string, lastName: string) => (
    <div
      className={`${styles.iconContainer} ${styles.bgTertiary50}`}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyboard}
    >
      <h6 className={`h6 no-margin ${styles.initials}`} data-testid="initials-icon">
        {getInitialsFromUserFullName({ firstName, lastName })}
      </h6>
    </div>
  );

  const getUserDefaultIcon = () => (
    <div
      className={`${styles.iconContainer} ${styles.bgViolet100}`}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyboard}
      data-testid="default-avatar"
    >
      <UserIcon className={styles.icon} data-testid="default-icon" />
    </div>
  );

  const getUserIcon = () => {
    if (user) {
      const { img_url: imgUrl, first_name: firstName, last_name: lastName } = user;
      if (imgUrl) {
        return getProfileImageComponent(imgUrl);
      }
      if (firstName && lastName) {
        return getUserInitialsComponent(firstName, lastName);
      }
    }
    // default icon
    return getUserDefaultIcon();
  };

  return <>{getUserIcon()}</>;
};

NavAvatar.propTypes = {
  onClick: PropTypes.func,
  onKeyboard: PropTypes.func,
};

NavAvatar.defaultProps = {
  onClick: () => {},
  onKeyboard: () => {},
};

export default NavAvatar;
