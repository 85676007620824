import eventBus, { CustomEventTypes } from '@services/EventBus';
import { useEffect } from 'react';

const useEventSubscriber = (event: CustomEventTypes, clb: EventListenerOrEventListenerObject) => {
  useEffect(() => {
    eventBus.getInstance().eventTarget.addEventListener(event, clb, { passive: true });
    return () => {
      eventBus.getInstance().eventTarget.removeEventListener(event, clb);
    };
  }, [clb]);
};

export default useEventSubscriber;
