import React from 'react';
import ArrowDown from '@assets/svgs/caret-icon-down.svg';
import NavAvatar from '@components/ui/avatar/NavAvatar';
import { Dropdown } from 'antd';
import NavMenuOptions from '@components/header/nav-menu-options/NavMenuOptions';
import styles from './NavDropdown.module.scss';

const NavDropdown: React.FC = () => {
  return (
    <div className={styles.dropdownWrap}>
      <Dropdown
        dropdownRender={() => <NavMenuOptions />}
        overlayClassName="dropdown-overlay"
        trigger={['click']}
        placement="bottomRight"
      >
        <div className={styles.compWrap} data-testid="nav-dropdown">
          <NavAvatar />
          <div className={styles.arrow}>
            <ArrowDown />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default NavDropdown;
