import React from 'react';
import { Spin } from 'antd';
import classNameCompose from '@utils/classname-compose';
import PropTypes from 'prop-types';
import SpinnerIcon from '@assets/svgs/spinner.svg';
import styles from './PageSpin.module.scss';

export type PageSpinSize = 'sm' | 'md' | 'lg';

type PageSpinProps = {
  size?: PageSpinSize;
  className?: string;
  loading?: boolean;
};

const PageSpin: React.FC<PageSpinProps> = ({ size = 'md', className, loading }) => (
  <>
    {loading && (
      <div className={classNameCompose(styles.pageSpin, className ?? '')} data-testid="spinner">
        <Spin indicator={<SpinnerIcon className={classNameCompose(styles.spinnerIcon, styles[size])} />} />
      </div>
    )}
  </>
);

PageSpin.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

PageSpin.defaultProps = {
  size: 'md',
  className: '',
  loading: true,
};

export default PageSpin;
