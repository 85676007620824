enum RoutesEnum {
  LOGIN = '/login',
  ADMIN_SIGNUP = '/signup',
  ADMIN_ACCOUNT_SETUP = '/setup',
  ADMIN_FORGOT_PASSWORD = '/forgot-password',
  ADMIN_SET_PASSWORD = '/set-password',
  AMDIN_HOME = '/admin',
  ADMIN_NOTIFICATIONS = '/admin/notifications',
  ADMIN_EMPLOYEES = '/admin/employees',
  ADMIN_EMPLOYEE_SEARCH = '/admin/employee-search',
  ADMIN_ORG_CHART = '/admin/org-chart',
  ADMIN_TIME_OFF_REQUESTS = '/admin/time-off/requests',
  ADMIN_TIME_OFF_CALENDAR = '/admin/time-off/calendar',
  ADMIN_TIME_OFF_BALANCE = '/admin/time-off/balance',
  ADMIN_TIME_OFF_REPORTS = '/admin/time-off/reports',
  ADMIN_POSITION_TITLES = '/admin/position-titles',
  ADMIN_DEPARTMENTS = '/admin/departments',
  ADMIN_LOCATIONS = '/admin/locations',
  ADMIN_SETTINGS_WORKING_HOURS = '/admin/settings/organization/working-hours',
  ADMIN_ANNOUNCEMENTS = '/admin/announcements',
  ADMIN_SETTING_TIME_OFF = '/admin/settings/time-off',
  ADMIN_SETTING_ACCOUNT = '/admin/settings/account',
  ADMIN_SETTING_EMPLOYEE_PROFILE = '/admin/settings/employee-profile',
  ADMIN_SETTING_EMAIL = '/admin/settings/email',
  ADMIN_SETTING_ONBOARDING = '/admin/settings/onboarding',
  ADMIN_SETTING_RECRUITMENT_EMAIL_TEMPLATES = '/admin/settings/recruitment/email-templates',
  ADMIN_SETTING_INTERGRATIONS_APPS = '/admin/settings/integrations/apps',
  ADMIN_SETTINGS = '/admin/settings',
  ADMIN_GETTING_STARTED = '/admin/getting-started',
  ADMIN_EMPLOYEES_IMPORT = '/admin/employees-import',
  ADMIN_MANUAL_ADJUSTMENT_IMPORT = '/admin/manual-adjustment-import',
  ADMIN_COMPANY_DOCUMENTS = '/admin/company-documents',
  ADMIN_PERFORMANCE_GOALS = '/admin/performance-goals',
  ADMIN_RECRUITMENT_POSITIONS = '/admin/recruitment/positions',
  ADMIN_RECRUITMENT_CANDIDATES = '/admin/recruitment/candidates',
  OLD_EMPLOYEE_LOGIN = '/employee/login',
  EMPLOYEE_HOME = '/employee',
  EMPLOYEE_ORG_CHART = '/employee/org-chart',
  EMPLOYEE_CALENDAR = '/employee/calendar',
  EMPLOYEE_COLLEAGUES = '/employee/colleagues',
  EMPLOYEE_PROFILE = '/employee/profile',
  EMPLOYEE_ANNOUNCEMENTS = '/employee/announcements',
  EMPLOYEE_COMPANY_DOCUMENTS = '/employee/company-documents',
  EMPLOYEE_TIME_OFF = '/employee/time-off',
  EMPLOYEE_TEAM_LEAVES = '/employee/team/leaves',
  EMPLOYEE_TEAM_MEMBERS = '/employee/team/members',
  EMPLOYEE_TEAM_CALENDAR = '/employee/team/calendar',
  EMPLOYEE_TEAM_BALANCE = '/employee/team/balance',
  EMPLOYEE_SET_PASSWORD = '/employee/set-password',
  EMPLOYEE_ONBOARDING = '/employee/onboarding',
  EMPLOYEE_NOTIFICATIONS = '/employee/notifications',
  EMPLOYEE_PERFORMANCE_GOALS = '/employee/performance-goals',
  EMPLOYEE_TEAM_PERFORMANCE_GOALS = '/employee/team/performance-goals',
  EMPLOYEE_EMAIL_NOTIFICATION_SETTINGS = '/employee/settings/email',
}

export default RoutesEnum;
