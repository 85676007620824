import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const getHighlightedText = (text: string, highlight: string) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part) => (part.toLowerCase() === highlight.toLowerCase() ? <b key={text}>{part}</b> : part))}
    </span>
  );
};
