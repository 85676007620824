import React, { useState } from 'react';
import { Form, Input } from 'antd';
import UiButton from '@components/ui/button/UiButton';
import useNotification from '@hooks/notification/useNotification';
import { CreateLocationDto, UpdateLocationDto } from '@models/location';
import { createLocation, updateLocation } from '@services/locations/admin-locations-http.service';
import eventBus, { CustomEventTypes } from '@services/EventBus';
import ErrorMessagesEnum from '@constants/error-messages.enum';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import styles from './LocationForm.module.scss';
import { LocationTableDataType } from '../locations-table/type';

export type LocationFormProps = {
  setVisible: (visible: boolean) => void;
  initialValues?: LocationTableDataType;
};

const LocationForm: React.FC<LocationFormProps> = ({ setVisible, initialValues }) => {
  const { useTypedTranslation } = makeTypedTranslation('common', 'notification', 'forms');
  const { t: translate } = useTypedTranslation('common');
  const { t: translateForms } = useTypedTranslation('forms');
  const { t: translateNotification } = useTypedTranslation('notification');
  const [form] = Form.useForm();
  const [titleField, setTitleField] = useState('');

  const [errorNotification] = useNotification('error', translateNotification('status.error'));
  const [successNotification] = useNotification('success', translateNotification('status.success'));

  const onFinish = (onFinishAction: any) => async (values: any) => {
    try {
      await onFinishAction(values);
      setVisible(false);
      eventBus.getInstance().dispatch(CustomEventTypes.LOCATIONS_UPDATED);
    } catch (e: any) {
      if (e.message === ErrorMessagesEnum.LOCATION_EXISTS) {
        errorNotification(undefined, translate('create_location.location_title_exists'));
      } else {
        errorNotification(undefined, translate('messages.error.something_went_wrong'));
      }
    }
  };

  const onCreate = onFinish(async ({ title, address }: CreateLocationDto) => {
    await createLocation({ title, address, active: true });
    successNotification(undefined, translate('create_location.location_created'));
  });

  const onUpdate = onFinish(async ({ title, address }: UpdateLocationDto) => {
    await updateLocation(initialValues?.key!, { title, address, active: initialValues?.active! });
    successNotification(undefined, translate('create_location.location_updated'));
  });

  return (
    <Form
      form={form}
      name="locationForm"
      layout="vertical"
      size="small"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={initialValues}
      onFinish={initialValues ? onUpdate : onCreate}
      autoComplete="off"
    >
      <div className={styles.formFields}>
        <h5 className="h5 uppercase">
          {initialValues ? translate('create_location.edit_location') : translate('create_location.new_location')}
        </h5>
        <Form.Item
          label={translate('create_location.location_title')}
          name="title"
          rules={[{ required: true, message: translateForms('required_minimal') }]}
        >
          <Input
            placeholder={translate('create_location.type_title')}
            data-testid="location-title"
            maxLength={60}
            onChange={(e) => setTitleField(e.target.value)}
          />
        </Form.Item>
        <Form.Item label={translate('create_location.address')} name="address" className={styles.descriptionField}>
          <Input
            placeholder={translate('create_location.type_address')}
            data-testid="location-address"
            maxLength={300}
            onChange={(e) => setTitleField(e.target.value)}
          />
        </Form.Item>
      </div>
      <div className={styles.actionButtons}>
        <UiButton type="primary" size="small" htmlType="submit" disabled={!titleField}>
          {initialValues ? translate('create_location.save') : translate('create_location.create')}
        </UiButton>
        <UiButton size="small" onClick={() => setVisible(false)}>
          {translate('create_location.cancel')}
        </UiButton>
      </div>
    </Form>
  );
};

export default LocationForm;
