import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import SearchIcon from '@assets/svgs/search-icon.svg';
import CaretRight from '@assets/svgs/caret-right.svg';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { AutoComplete, Input } from 'antd';
import useFetchData from '@hooks/useFetchData';
import useDebounce from '@hooks/useDebounce';
import EmployeesModel from '@models/employee/employeesModel';
import { getEmployeesWithQuery } from '@services/employee/employee-http.service';
import EmployeeModel from '@models/employee/model';
import Link from 'next/link';
import RoutesEnum from '@constants/routes.enum';
import usePath from '@hooks/usePath';
import useAccountSuspended from '@hooks/useAccountSuspended';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import EmployeeAutoCompleteResult from './employee-autocomplete-result/EmployeeAutoCompleteResult';
import styles from './EmployeeSearchBar.module.scss';

type EmployeeSearchBarType = {
  className?: string;
  inputSize?: SizeType;
  onItemSelect?: Function;
};

const PAGE = 1;
const LIMIT = 5;
const ENTER_KEY = 'Enter';

const AUTOCOMPLETE_STARTING_LENGTH = 2;

const EmployeeSearchBar = React.forwardRef<HTMLDivElement, EmployeeSearchBarType>(
  ({ className, inputSize = 'middle', onItemSelect }, ref) => {
    const { t: translate } = useTranslation('common');
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearch = useDebounce<string>(searchTerm, 600);
    const router = useRouter();
    const { getPathWithPrefix } = usePath();

    const accountSuspended = useAccountSuspended();

    const onSubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === ENTER_KEY)
        router.push({ pathname: getPathWithPrefix(RoutesEnum.ADMIN_EMPLOYEE_SEARCH), query: { searchTerm } });
    };

    const onSearch = () => {
      router.push({ pathname: getPathWithPrefix(RoutesEnum.ADMIN_EMPLOYEE_SEARCH), query: { searchTerm } });
    };

    const onSelect = (employeeId: string) => {
      onItemSelect?.();
      router.push({ pathname: `${getPathWithPrefix(RoutesEnum.ADMIN_EMPLOYEES)}/${employeeId}` });
    };

    const fetcher = useCallback(async () => {
      if (debouncedSearch.length < AUTOCOMPLETE_STARTING_LENGTH) return [];
      const employees: EmployeesModel = await getEmployeesWithQuery(PAGE, LIMIT, undefined, debouncedSearch);
      return employees;
    }, [debouncedSearch]);

    const { data: employees } = useFetchData<EmployeesModel>(fetcher);

    useEffect(() => {
      if (searchTerm !== '') {
        setSearchTerm('');
      }
    }, [router.asPath]);

    const dropdownRender = (menu: any) => (
      <div ref={ref} className={styles.dropdownWrap}>
        <div className={styles.dropdownTitle}>{translate('search.employees')}</div>
        {menu}
        <div data-testid="show-all" className={`main-body-text ${styles.showAll}`}>
          <Link href={`${getPathWithPrefix(RoutesEnum.ADMIN_EMPLOYEE_SEARCH)}?searchTerm=${searchTerm}`}>
            {`${translate('search.show_all')} (${employees?.total ?? 0} ${
              employees?.total === 1 ? translate('search.result') : translate('search.results')
            })`}
            <CaretRight />
          </Link>
        </div>
      </div>
    );

    return (
      <div ref={ref} className={`${styles.simpleInputContainer} ${className}`}>
        <AutoComplete
          value={searchTerm}
          onSelect={onSelect}
          onKeyDown={accountSuspended ? undefined : onSubmit}
          onSearch={accountSuspended ? undefined : setSearchTerm}
          // @ts-ignore
          notFoundContent={debouncedSearch.length >= AUTOCOMPLETE_STARTING_LENGTH && dropdownRender}
          dropdownRender={dropdownRender}
          options={
            debouncedSearch.length >= AUTOCOMPLETE_STARTING_LENGTH
              ? employees?.data?.map((employee: EmployeeModel) => {
                  return {
                    value: employee.id,
                    key: employee.id,
                    label: <EmployeeAutoCompleteResult employee={employee} searchTerm={searchTerm} />,
                  };
                })
              : []
          }
          data-testid="autocomplete"
        >
          <Input
            data-testid="search-input"
            size={inputSize}
            placeholder={translate('search.placeholder')}
            suffix={<SearchIcon className={styles.searchIcon} onClick={onSearch} />}
          />
        </AutoComplete>
      </div>
    );
  },
);

EmployeeSearchBar.propTypes = {
  className: PropTypes.string,
  onItemSelect: PropTypes.func,
  inputSize: PropTypes.oneOf(['small', 'middle', 'large']),
};

EmployeeSearchBar.displayName = 'EmployeeSearchBar';

EmployeeSearchBar.defaultProps = {
  className: '',
  onItemSelect: () => {},
  inputSize: 'middle',
};

export default EmployeeSearchBar;
