import React from 'react';
import EmployeeNavBar from '@components/header/employee-nav-bar/EmployeeNavBar';
import styles from './PlainLayout.module.scss';

type PlainLayoutProps = {
  children: React.ReactNode;
};

const PlainLayout: React.FC<PlainLayoutProps> = ({ children }) => {
  return (
    <>
      <EmployeeNavBar />
      <div className={styles.main}>{children}</div>
    </>
  );
};

export default PlainLayout;
