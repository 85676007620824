import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { createNewPositionTitle, updatePositionTitle } from '@services/position-titles/position-titles-http.service';
import UiButton from '@components/ui/button/UiButton';
import useNotification from '@hooks/notification/useNotification';
import eventBus, { CustomEventTypes } from '@services/EventBus';
import { CreatePositionTitleDto, PositionsTitlesTableDataType } from '@models/position-titles';
import ErrorMessagesEnum from '@constants/error-messages.enum';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import styles from './PositionTitleForm.module.scss';

export type PositionTitleFormProps = {
  setVisible: (visible: boolean) => void;
  initialFormValues?: PositionsTitlesTableDataType;
};

const PositionTitleForm: React.FC<PositionTitleFormProps> = ({ setVisible, initialFormValues }) => {
  const [form] = Form.useForm();
  const { useTypedTranslation } = makeTypedTranslation('common', 'notification');
  const { t: translate } = useTypedTranslation('common');
  const { t: translateNotification } = useTypedTranslation('notification');
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const [errorNotification] = useNotification('error', translateNotification('status.error'));
  const [successNotification] = useNotification('success', translateNotification('status.success'));

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSubmit(hasErrors);
  };

  const onFinish = (onFinishAction: any) => async (values: any) => {
    try {
      await onFinishAction(values);
      setVisible(false);
      eventBus.getInstance().dispatch(CustomEventTypes.POSITION_TITLES_UPDATED);
    } catch (e: any) {
      if (e?.message === ErrorMessagesEnum.TITLE_EXISTS) {
        errorNotification(undefined, translate('create_position.position_title_exists'));
      } else {
        errorNotification(undefined, translate('messages.error.something_went_wrong'));
      }
    }
  };

  const onCreate = onFinish(async ({ title }: CreatePositionTitleDto) => {
    await createNewPositionTitle({ title });
    successNotification(undefined, translate('create_position.position_title_created'));
  });

  const onUpdate = onFinish(async ({ title }: CreatePositionTitleDto) => {
    await updatePositionTitle(initialFormValues?.id!, { title });
    successNotification(undefined, translate('create_position.position_title_updated'));
  });

  return (
    <Form
      form={form}
      name="createPositionTitle"
      layout="vertical"
      size="small"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFieldsChange={handleFormChange}
      initialValues={{
        title: initialFormValues?.title,
      }}
      onFinish={initialFormValues ? onUpdate : onCreate}
      autoComplete="off"
    >
      <div className={styles.formFields}>
        <h5 className="h5 uppercase">
          {initialFormValues
            ? translate('create_position.edit_position_title')
            : translate('create_position.new_position_title')}
        </h5>
        <Form.Item
          label={translate('create_position.title')}
          name="title"
          rules={[{ required: true, message: translate('create_position.please_give_title') }]}
        >
          <Input
            placeholder={translate('create_position.enter_position_title')}
            data-testid="position-title"
            maxLength={60}
          />
        </Form.Item>
      </div>
      <Form.Item wrapperCol={{ offset: 0, span: 16 }} className={styles.actionButtons}>
        <div className={styles.actionButtons}>
          <UiButton
            type="primary"
            size="small"
            htmlType="submit"
            disabled={disabledSubmit}
            data-testid="action-form-btn"
          >
            {initialFormValues ? translate('create_position.save') : translate('create_position.create')}
          </UiButton>
          <UiButton size="small" onClick={() => setVisible(false)}>
            {translate('create_position.cancel')}
          </UiButton>
        </div>
      </Form.Item>
    </Form>
  );
};

export default PositionTitleForm;
