import React from 'react';
import Link from 'next/link';
import styles from './Linkable.module.scss';

type LinkableProps = {
  children: React.ReactNode;
  redirectTo?: string;
};

const Linkable: React.FC<LinkableProps> = ({ children, redirectTo }) => {
  return (
    <>
      {redirectTo ? (
        <Link href={redirectTo} className={styles.url} data-testid="profile-link">
          {children}
        </Link>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Linkable;
