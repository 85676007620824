/* eslint-disable import/prefer-default-export */
import { hrmsBackEndUrl, hrmsInstance } from '@utils/http-client';
import {
  CreateAnnouncement,
  UpdateAnnouncement,
  Announcement,
  UserAnnouncement,
  GetAnnouncements,
} from '@services/announcements/type';

const announcements = `${hrmsBackEndUrl}/announcements`;
const userAnnouncements = `${hrmsBackEndUrl}/users/announcements`;

/** Admin services */

export const getAnnouncements = (page: number, psize: number): Promise<GetAnnouncements> => {
  return hrmsInstance.get(`${announcements}/list`, {
    params: {
      page,
      psize,
    },
  });
};

export const getAnnouncement = (id: string): Promise<Announcement> => {
  return hrmsInstance.get(`${hrmsBackEndUrl}/announcements/${id}`);
};

export const createAnnouncement = (body: CreateAnnouncement): Promise<void> => {
  return hrmsInstance.post(`${hrmsBackEndUrl}/announcements`, body);
};

export const updateAnnouncement = (id: string, body: UpdateAnnouncement): Promise<void> => {
  return hrmsInstance.put(`${hrmsBackEndUrl}/announcements/${id}`, body);
};

export const sendAnnouncementEmail = (id: string): Promise<void> => {
  return hrmsInstance.post(`${hrmsBackEndUrl}/announcements/${id}/send_email`);
};

/** User services */

export const getUserAnnouncements = (): Promise<UserAnnouncement[]> => {
  return hrmsInstance.get(`${userAnnouncements}/list`);
};

export const deleteAnnouncement = (id: string): Promise<void> => {
  return hrmsInstance.delete(`${hrmsBackEndUrl}/announcements/${id}`);
};
