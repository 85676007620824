import dayjs, { ManipulateType, OpUnitType } from 'dayjs';
import dateFormat from '@constants/date-format';

const dateCounter = (date: string) => {
  const now = dayjs();
  const date2 = dayjs(date);

  const years = now.diff(date2, 'year');
  const months = now.diff(date2, 'month') - years * 12;
  const days = now.diff(date2.add(years, 'year').add(months, 'month'), 'day');

  if (years) return `${years}y ${months}m ${days}d`;
  if (months) return `${months}m ${days}d`;
  return `${days}d`;
};

export const dateDifference = (startDate: string, endDate?: string) => {
  if (!endDate) return `0y 0m 0d`;
  const date1 = dayjs(startDate);
  const date2 = dayjs(endDate);

  const years = date2.diff(date1, 'year');
  const months = date2.diff(date1, 'month') - years * 12;
  const days = date2.diff(date1.add(years, 'year').add(months, 'month'), 'day');

  return `${years}y ${months}m ${days}d`;
};

/**
 * Convert millis to time elapsed string in sec/min/hr or days
 *
 * @param {string} date in millis
 */
export const timeThatPassed = (date: string) => {
  const currentTimestamp = dayjs().unix();
  const dateTimestamp = dayjs(date).unix();

  let timeUnit = 'second(s)';
  let interval = currentTimestamp - dateTimestamp;

  if (interval > 60) {
    interval /= 60;
    timeUnit = 'minute(s)';
  }
  if (interval > 60 && timeUnit === 'minute(s)') {
    interval /= 60;
    timeUnit = 'hour(s)';
  }
  if (interval > 24 && timeUnit === 'hour(s)') {
    interval /= 24;
    timeUnit = 'day(s)';
  }

  return `${Math.round(interval)} ${timeUnit} ago`;
};

export const getStartOfPreviousDateUnit = (
  amount: number,
  subtractUnit: ManipulateType,
  unit: OpUnitType,
  format = '',
) => {
  return dayjs().subtract(amount, subtractUnit).startOf(unit).format(format);
};

export const getEndOfPreviousDateUnit = (
  amount: number,
  subtractUnit: ManipulateType,
  unit: OpUnitType,
  format = '',
) => {
  return dayjs().subtract(amount, subtractUnit).endOf(unit).format(format);
};

export const getStartOfNextDateUnit = (amount: number, addUnit: ManipulateType, unit: OpUnitType, format = '') => {
  return dayjs().add(amount, addUnit).startOf(unit).format(format);
};

export const getEndOfNextDateUnit = (amount: number, addUnit: ManipulateType, unit: OpUnitType, format = '') => {
  return dayjs().add(amount, addUnit).endOf(unit).format(format);
};

export const getFormattedDate = (date: string, format = dateFormat) => {
  return dayjs(date).format(format);
};

export default dateCounter;
