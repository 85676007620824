import React, { useMemo } from 'react';
import { AdminNotification, AdminNotificationTypes, NotificationType } from '@components/notfications-list/type';
import { Trans } from 'next-i18next';
import Avatar from '@components/ka-ui/avatar/Avatar';
import RoutesEnum from '@constants/routes.enum';
import NotificationIcon from '@components/ka-ui/notification-icon/NotificationIcon';
import InfoIcon from '@assets/svgs/info-tooltip-icon.svg';
import dayjs from 'dayjs';
import dateFormat from '@constants/date-format';
import CheersIcon from '@assets/svgs/cheers.svg';
import GiftIcon from '@assets/svgs/gift-icon.svg';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { markAdminNotificationSeen } from '@services/notifications/notification-http.service';
import { useRouter } from 'next/router';
import usePath from '@hooks/usePath';
import DocumentIcon from '@assets/svgs/document.svg';

const useAdminNotifications = (data?: AdminNotification[]) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const router = useRouter();
  const { getPathWithPrefix } = usePath();

  const markAsSeen = (notification: AdminNotification) => {
    if (!notification.seen) {
      markAdminNotificationSeen(notification.id);
    }
  };

  const redirectTo = (path: string) => {
    router.push(getPathWithPrefix(path));
  };

  const notifications = useMemo(
    () =>
      data &&
      data.reduce<NotificationType[]>((acc, notification: AdminNotification) => {
        switch (notification.type) {
          case AdminNotificationTypes.TIME_OFF_REQUEST:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="admin_notifications.time_off_request"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_TIME_OFF_REQUESTS}?approveId=${notification.payload.request_id}`);
              },
            });
            break;
          case AdminNotificationTypes.TIME_OFF_CANCELATION_REQUEST:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="admin_notifications.time_off_cancelaton_request"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_TIME_OFF_REQUESTS}?cancelId=${notification.payload.request_id}`);
              },
            });
            break;
          case AdminNotificationTypes.EMPLOYEE_END_HIRE:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="admin_notifications.employee_end_hire_today"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_EMPLOYEES}/${notification.payload.employee_id}`);
              },
            });
            break;
          case AdminNotificationTypes.EMPLOYEE_END_HIRE_SOON:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="admin_notifications.employee_end_hire_soon"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_EMPLOYEES}/${notification.payload.employee_id}`);
              },
            });
            break;
          case AdminNotificationTypes.EMPLOYEE_COMPLETED_ONBOARDING:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="admin_notifications.employee_completed_onboarding"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_EMPLOYEES}/${notification.payload.employee_id}`);
              },
            });
            break;
          case AdminNotificationTypes.EMPLOYEES_NOT_IN_ORG_CHART:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="admin_notifications.employees_not_in_org_chart">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={InfoIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(RoutesEnum.ADMIN_ORG_CHART);
              },
            });
            break;
          case AdminNotificationTypes.WORK_ANNIVERSARY:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="admin_notifications.work_anniversary"
                  values={{
                    fullname: `${notification.payload.first_name} ${notification.payload.last_name}`,
                    startDate: dayjs(notification.payload.start_date).format(dateFormat),
                  }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={CheersIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_EMPLOYEES}/${notification.payload.employee_id}`);
              },
            });
            break;
          case AdminNotificationTypes.EMPLOYEE_BIRTHDAY:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="admin_notifications.birthday"
                  values={{
                    fullname: `${notification.payload.first_name} ${notification.payload.last_name}`,
                    date: dayjs(notification.payload.date).format(dateFormat),
                  }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={GiftIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_EMPLOYEES}/${notification.payload.employee_id}`);
              },
            });
            break;
          case AdminNotificationTypes.FIRST_PAYMENT_ATTEMPT_FAILED:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="admin_notifications.first_payment_attempt">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={InfoIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=BILLING_INFORMATION`);
              },
            });
            break;
          case AdminNotificationTypes.SECOND_PAYMENT_ATTEMPT_FAILED:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="admin_notifications.second_payment_attempt">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={InfoIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=BILLING_INFORMATION`);
              },
            });
            break;
          case AdminNotificationTypes.EMPLOYEE_HIRING_STARTED:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="admin_notifications.hiring_started"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_EMPLOYEES}/${notification.payload.employee_id}`);
              },
            });
            break;
          case AdminNotificationTypes.EMPLOYEE_UPLOADED_DOCUMENT:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="admin_notifications.employee_uploaded_document"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={DocumentIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.ADMIN_EMPLOYEES}/${notification.payload.employee_id}?active_tab=DOCUMENTS`);
              },
            });
            break;
          default:
            return acc;
        }
        return acc;
      }, []),
    [data],
  );
  return notifications;
};

export default useAdminNotifications;
