/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Card } from 'antd';
import { timeThatPassed } from '@utils/date-utils';
import eventBus, { CustomEventTypes } from '@services/EventBus';
import PropTypes from 'prop-types';
import { NotificationType } from '../type';
import styles from './NotificationCard.module.scss';

type NotificationCardType = {
  notification: NotificationType;
  cardIcon: React.ReactNode;
};

const NotificationCard: React.FC<NotificationCardType> = ({ notification, cardIcon }) => {
  const notificationAction = async () => {
    notification?.action?.();
    eventBus.getInstance().dispatch(CustomEventTypes.CLOSE_ADMIN_NOTIFICATIONS);
  };

  return (
    <Card className={styles.notificationCard} data-testid={notification.id}>
      <button
        type="button"
        className={`no-button-styles ${styles.notificationWrap}`}
        onClick={async () => {
          await notificationAction();
        }}
        data-testid="action-btn"
      >
        <div className={styles.notificationImg}>{notification.img}</div>
        <div className={styles.notificationRightHandSection}>
          <h5 className={`main-body-text title-dark ${styles.notificationMessage}`}>{notification.content}</h5>
          <p className={`main-body-text ${styles.notificationTime}`}>{timeThatPassed(notification.created_at)}</p>
          {cardIcon}
        </div>
      </button>
    </Card>
  );
};

NotificationCard.propTypes = {
  notification: PropTypes.any.isRequired,
};

export default NotificationCard;
