import {
  GetLocations,
  CreateLocationDto,
  UpdateLocationDto,
  GetLocationEmployees,
  LocationName,
} from '@models/location/model';
import { hrmsInstance, hrmsBackEndUrl } from '@utils/http-client';

const baseUrl = `${hrmsBackEndUrl}/locations`;

export const createLocation = (body: CreateLocationDto): Promise<void> => {
  return hrmsInstance.post(baseUrl, body);
};

export const updateLocation = (locationId: string, body: UpdateLocationDto): Promise<void> => {
  return hrmsInstance.put(`${baseUrl}/${locationId}`, body);
};

export const getLocations = (page: number, psize: number): Promise<GetLocations> => {
  return hrmsInstance.get(baseUrl, { params: { page, psize } });
};

export const getLocationsName = (): Promise<LocationName[]> => {
  return hrmsInstance.get(`${baseUrl}/names-list`);
};
export const getLocationEmployees = (locationId: string): Promise<GetLocationEmployees[]> => {
  return hrmsInstance.get(`${baseUrl}/${locationId}/employees`);
};

export const addEmployeesToLocation = (locationId: string, employees: string[]): Promise<void> => {
  return hrmsInstance.post(`${baseUrl}/${locationId}/employees/add`, { employees });
};

export const deleteLocation = (id: string): Promise<void> => {
  return hrmsInstance.delete(`${baseUrl}/${id}`);
};
