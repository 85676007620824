import { ThemeConfig } from 'antd';

export default {
  hashed: false,
  token: {
    colorPrimary: '#1c2964',
    colorPrimaryBg: '#fbfaff',
    colorText: '#596980',
    borderRadius: 8,
    borderRadiusSM: 2,
    lineWidth: 2,
    fontSize: 14,
    fontSizeLG: 16,
    fontSizeSM: 12,
    colorBorder: '#f6f4ff',
    colorSplit: '#f6f4ff',
    fontFamily: 'var(--source-sans-pro)',
  },
  components: {
    Dropdown: {
      controlItemBgActive: '#f0edff',
      itemActiveBg: '#c4cfdd',
      colorPrimary: '#1c2964',
      menuPadding: 0,
    },
    Tabs: {
      itemSelectedColor: '#ff2458',
      itemActiveColor: '#ff2458',
      inkBarColor: '#ff2458',
      cardBg: '#ff2458',
    },
    Table: {
      headerBg: '#f2f2f6',
      headerColor: '#a0a6bd',
      cellPaddingBlock: 12.5,
      cellPaddingInline: 24,
      cellFontSize: 12.003,
    },
    Form: {
      verticalLabelPadding: 0,
      verticalLabelMargin: 0,
      itemMarginBottom: 0,
      labelColor: '#8999af',
      labelFontSize: 14,
    },
    Select: {
      selectorBg: '#fbfaff',
      optionSelectedBg: '#f6f4ff',
      multipleItemHeightLG: 68,
      singleItemHeightLG: 68,
    },
    Timeline: {
      itemPaddingBottom: 0,
    },
    Input: {
      controlHeightSM: 39,
      controlHeight: 58,
      controlHeightLG: 68,
      colorTextPlaceholder: '#8999af',
      colorText: '#3e4b5e',
      colorBorder: '#f6f4ff',
      colorBgContainer: '#fbfaff',
      handleBg: '#fbfaff',
      hoverBorderColor: '#8389a9',
    },
    InputNumber: {
      handleBg: '#fbfaff',
      hoverBorderColor: '#8389a9',
    },
    DatePicker: {
      colorBgContainer: '#f7f9fc',
    },
    Modal: {
      titleColor: '#596980',
    },
    Step: {
      colorSplit: '#a0a6bd',
    },
  },
} as ThemeConfig;
