import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { useRouter } from 'next/router';
import EmployeeIcon from '@assets/svgs/employee.svg';
import HomeIcon from '@assets/svgs/home-icon.svg';
import CalendarIcon from '@assets/svgs/calendar.svg';
import RecruitmentIcon from '@assets/svgs/recruitment-icon.svg';
import SettingsIcon from '@assets/svgs/settings.svg';
import AnnouncementsIcon from '@assets/svgs/megaphone-icon.svg';
import DocumentsIcon from '@assets/svgs/document.svg';
import UiDrawer from '@components/ui/drawer/UiDrawer';
import RoutesEnum from '@constants/routes.enum';
import usePath from '@hooks/usePath';
import SubscriptionBanner from '@components/layout/subscription-banner/SubscriptionBanner';
import { AuthContext } from '@contexts/AuthContext';
import { SubscriptionType } from '@models/user/auth';
import { AppContext } from '@contexts/AppContext';
import mailbox from '@services/Mailbox';
import { CustomEventTypes } from '@services/EventBus';
import useEventSubscriber from '@hooks/useEventSubscriber';
import classNameCompose from '@utils/classname-compose';
import useAccountSuspended from '@hooks/useAccountSuspended';
import AdminNavBar from '@components/header/admin-nav-bar/AdminNavBar';
import { SettingsType, EmployeeProfileSettingsType } from '@models/settings/employee-profile-settings';
import { getViewSettings } from '@services/view-settings/admin-settings.service';
import useFetchData from '@hooks/useFetchData';
import useLocalStorage from '@hooks/useLocalStorage';
import useNotification from '@hooks/notification/useNotification';
import StateEnum from '@constants/state.enum';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import StatusInfoTag from '@components/ka-ui/type-info-tag/TypeInfoTag';
import styles from './Layout.module.scss';
import Footer from './footer/Footer';

const { Content, Sider } = Layout;

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const { useTypedTranslation } = makeTypedTranslation('common', 'notification');
  const { t: translate } = useTypedTranslation('common');
  const { t: translateNotification } = useTypedTranslation('notification');
  const [visible, setVisible] = useState(false);

  const { user } = useContext(AuthContext);
  const accountSuspended = useAccountSuspended();

  const { appState, setAppState } = useContext(AppContext);
  const { bannerIsVisible } = appState;

  const router = useRouter();
  const { appPathPrefix } = usePath();
  const path = router.asPath.replace(appPathPrefix, '');

  const [userSettings, setUserSettings] = useLocalStorage('settings', {} as EmployeeProfileSettingsType);
  const [errorNotification] = useNotification(StateEnum.ERROR, translateNotification('status.error'));

  const goTo = (info: any) => {
    setVisible(false);
    if (accountSuspended) return;
    router.push(`${appPathPrefix}${info.key.toString()}`);
  };

  useEffect(() => {
    const hideBanner = mailbox.get('HIDE_BANNER');
    if (!hideBanner && user?.subscription?.type === SubscriptionType.trial) {
      setAppState({ ...appState, bannerIsVisible: true });
    }
  }, []);

  useEventSubscriber(CustomEventTypes.SHOW_SUBSCRIPTION_BANNER, () =>
    setAppState({ ...appState, bannerIsVisible: true }),
  );

  // eslint-disable-next-line consistent-return
  const settingsFetcher = useCallback(async () => {
    if (!userSettings) return undefined;
    if (user?.view_settings_version === userSettings?.settings_version) return userSettings;

    const employeeSettings: SettingsType = await getViewSettings();

    setUserSettings({
      ...employeeSettings.settings,
      settings_version: employeeSettings.view_settings_version,
    });
  }, [userSettings]);

  useFetchData<SettingsType>(settingsFetcher, () => {
    errorNotification(undefined, translate('messages.error.something_went_wrong'));
  });

  const hrmsMenu = (
    <div className={classNameCompose(styles.menuContainer, accountSuspended ? styles.accountSuspended : '')}>
      <Menu mode="inline" selectedKeys={[path]} className="app-menu-container" onClick={goTo}>
        <Menu.Item icon={<HomeIcon />} key={RoutesEnum.AMDIN_HOME} className="small-body-text" data-testid="home-route">
          {translate('menu.home')}
        </Menu.Item>
        <Menu.SubMenu
          key="1"
          icon={<EmployeeIcon />}
          className={styles.hrmsSubmenu}
          title={translate('menu.employees')}
          data-testid="employees-submenu"
        >
          <Menu.Item key={RoutesEnum.ADMIN_EMPLOYEES} className="small-body-text" data-testid="employees-list-route">
            {translate('menu.employees_list')}
          </Menu.Item>
          <Menu.Item key={RoutesEnum.ADMIN_ORG_CHART} className="small-body-text" data-testid="org-chart-route">
            {translate('menu.organization_chart')}
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="2"
          icon={<CalendarIcon />}
          className={styles.hrmsSubmenu}
          title={translate('menu.time_off')}
          data-testid="time-off-submenu"
        >
          <Menu.Item key={RoutesEnum.ADMIN_TIME_OFF_REQUESTS} className="small-body-text" data-testid="requests-route">
            {translate('menu.requests')}
          </Menu.Item>
          <Menu.Item key={RoutesEnum.ADMIN_TIME_OFF_CALENDAR} className="small-body-text" data-testid="calendar-route">
            {translate('menu.calendar')}
          </Menu.Item>
          <Menu.Item key={RoutesEnum.ADMIN_TIME_OFF_BALANCE} className="small-body-text" data-testid="balances-route">
            {translate('menu.balance')}
          </Menu.Item>
          <Menu.Item key={RoutesEnum.ADMIN_TIME_OFF_REPORTS} className="small-body-text" data-testid="reports-route">
            {translate('menu.reports')}
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="3"
          icon={<RecruitmentIcon />}
          className={styles.submenuWithTag}
          title={
            <div className={styles.menuItemWithTag}>
              {translate('menu.recruitment')}
              <StatusInfoTag status="beta" />
            </div>
          }
          data-testid="recruitment-submenu"
          disabled={!user?.ats_enabled}
        >
          <Menu.Item
            key={RoutesEnum.ADMIN_RECRUITMENT_POSITIONS}
            className="small-body-text"
            data-testid="recruitment-positions-route"
          >
            {translate('menu.recruitment_positions')}
          </Menu.Item>
          <Menu.Item
            key={RoutesEnum.ADMIN_RECRUITMENT_CANDIDATES}
            className="small-body-text"
            data-testid="recruitment-candidates-route"
          >
            {translate('menu.recruitment_candidates')}
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item
          icon={<AnnouncementsIcon />}
          key={RoutesEnum.ADMIN_ANNOUNCEMENTS}
          className={styles.hrmsSubmenu}
          data-testid="announcements-route"
        >
          {translate('menu.announcements')}
        </Menu.Item>
        <Menu.Item
          icon={<DocumentsIcon />}
          key={RoutesEnum.ADMIN_COMPANY_DOCUMENTS}
          className={styles.hrmsSubmenu}
          data-testid="company-documents-route"
        >
          {translate('menu.company_documents')}
        </Menu.Item>
        <Menu.Item
          key={RoutesEnum.ADMIN_SETTINGS}
          icon={<SettingsIcon />}
          className={styles.hrmsSubmenu}
          data-testid="settings-route"
        >
          {translate('menu.settings')}
        </Menu.Item>
      </Menu>
    </div>
  );
  return (
    <>
      {bannerIsVisible && <SubscriptionBanner />}
      <Layout>
        <AdminNavBar setVisibleMenu={setVisible} />
        <Layout className={styles.layout}>
          <Sider
            className={`scroll-bar ${styles.sider} ${bannerIsVisible ? styles.withBanner : ''}`}
            collapsible
            breakpoint="lg"
            collapsedWidth={0}
            trigger={null}
          >
            {hrmsMenu}
          </Sider>
          <UiDrawer
            closable={false}
            placement="left"
            onClose={() => setVisible(false)}
            open={visible}
            rootClassName="side-menu"
          >
            {hrmsMenu}
          </UiDrawer>
          <Layout className={classNameCompose(styles.main, bannerIsVisible ? styles.withBanner : '')}>
            <Content>{children}</Content>
          </Layout>
        </Layout>
        <Footer />
      </Layout>
    </>
  );
};

export default PageLayout;
