import React, { useCallback, useMemo, useState } from 'react';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import useFetchData from '@hooks/useFetchData';
import { getAllPositions } from '@services/position/position-http.service';
import { AutoComplete, AutoCompleteProps, Input } from 'antd';
import UiTag from '@components/ui/tag/UiTag';
import PlusIcon from '@assets/svgs/plus-icon.svg';
import { getHighlightedText } from '@utils/text-highlighter';
import styles from './PositionTitlesSelect.module.scss';

type CustomSelectProps = Omit<AutoCompleteProps, 'defaultValue' | 'size'> &
  React.RefAttributes<any> & { defaultValue?: string };

const PositionTitlesSelect = ({ defaultValue, value, onChange, ...rest }: CustomSelectProps) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showTag, setShowTag] = useState<boolean>(false);

  const fetcher = useCallback(async () => {
    const positionTitles = await getAllPositions(1, 1000);
    const { data } = positionTitles;
    return data.map((positionTitle) => ({
      value: positionTitle.title,
      label: positionTitle.title,
    }));
  }, []);

  const { data: entries } = useFetchData<{ label: string; value: string }[]>(fetcher);

  const positionTitles = useMemo(() => {
    if (!entries || searchTerm?.length <= 2) {
      return [];
    }

    const filteredEntries = entries
      .filter((entry) => entry.label.toLowerCase().includes(searchTerm.toLowerCase()))
      .map((entry) => ({
        value: entry.value,
        label: getHighlightedText(entry.label, searchTerm),
      }));

    const isSearchTermInEntries = entries.some((position) => position.label.toLowerCase() === searchTerm.toLowerCase());

    if (!isSearchTermInEntries) {
      filteredEntries.push({
        value: searchTerm,
        label: (
          <section className="main-body-text" data-testid="add-new-tag">
            <PlusIcon className={styles.plusIcon} /> {translate('position_title_select.add_tag')} “
            <strong>{searchTerm}</strong>”
          </section>
        ),
      });
    }

    return filteredEntries;
  }, [entries, searchTerm]);

  const handleOnChange = (optionLabel: string, option: any) => {
    if (entries?.find((position) => position.label === optionLabel)) setShowTag(false);
    else setShowTag(true);
    if (onChange) onChange(optionLabel, option);
  };

  const handleOnSearch = (searchValue: string) => {
    if (!searchValue) setShowTag(false);
    setSearchTerm(searchValue);
  };

  return (
    <div className={styles.compWrap}>
      <AutoComplete
        {...rest}
        popupClassName="position-autocomplete"
        allowClear
        showSearch
        options={positionTitles}
        onChange={handleOnChange}
        value={value}
        notFoundContent={
          searchTerm?.length > 2
            ? translate('position_title_select.no_positions', { title: searchTerm })
            : translate('position_title_select.type_at_least_3_letters')
        }
        onSearch={handleOnSearch}
        onClear={() => setShowTag(false)}
        // @ts-ignore
        filterOption={(inputValue, option) => option!.children?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1}
      >
        <Input
          size="small"
          placeholder={!rest.disabled ? translate('position_title_select.give_position_title') : ''}
          suffix={
            showTag ? (
              <UiTag className={styles.newTag} type="success">
                {translate('position_title_select.new_tag')}
              </UiTag>
            ) : (
              <span />
            )
          }
          data-testid="position-input"
        />
      </AutoComplete>
    </div>
  );
};

export default PositionTitlesSelect;
