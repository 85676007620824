import React from 'react';
import PlusIcon from '@assets/svgs/plus-icon.svg';
import DownIcon from '@assets/svgs/caret-icon-down.svg';
import { Dropdown } from 'antd';
import classNameCompose from '@utils/classname-compose';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import styles from './QuickActions.module.scss';
import QuickActionsMenu from './quick-actions-menu/QuickActionsMenu';

const QuickActions: React.FC = () => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');
  const [open, setOpen] = React.useState(false);

  return (
    <div className={styles.dropDownWrap}>
      <Dropdown
        dropdownRender={() => <QuickActionsMenu onItemClick={() => setOpen(false)} />}
        overlayClassName="dropdown-overlay"
        trigger={['click']}
        placement="bottomRight"
        onOpenChange={setOpen}
        open={open}
      >
        <div
          className={classNameCompose(styles.compWrap, open ? styles.open : '')}
          data-testid="quick-actions-dropdown"
        >
          <div className={styles.desktopQuickActions}>
            <span className={`medium-caps-bold ${styles.btnTitle}`}>{translate('quick_actions.btn_title')}</span>
            <DownIcon className={styles.downIcon} />
          </div>
          <PlusIcon className={classNameCompose(styles.icon, styles.mobileQuickActions)} />
        </div>
      </Dropdown>
    </div>
  );
};

export default QuickActions;
