import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Drawer, Modal } from 'antd';
import CloseIcon from '@assets/svgs/cross-icon.svg';
import UiModalSizesEnum from '@constants/modal/ui-modal.sizes.enum';
import classNameCompose from '@utils/classname-compose';
import useWindowDimensions from '@hooks/useWindowDimentions';
import { WindowSizeEnum } from '@constants/window-size';
import styles from './ModalContext.module.scss';

type ModalContextProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  renderModal: (content: JSX.Element, width?: UiModalSizesEnum, modalStyles?: string) => void;
  modalView?: JSX.Element;
};

const ModalContext = React.createContext<ModalContextProps>({
  visible: false,
  setVisible: () => {},
  renderModal: () => {},
  modalView: <></>,
});

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const { width: windowWidth } = useWindowDimensions();

  const [visible, setVisible] = useState<boolean>(false);
  const [className, setClassName] = useState<string>('');
  const [modalWidth, setModalWidth] = useState<UiModalSizesEnum>(UiModalSizesEnum.SMALL);
  const [modalView, setModalView] = useState<JSX.Element | undefined>(undefined);

  const modalContentRef = useRef<HTMLDivElement>(null);

  const renderModal = (
    content: JSX.Element,
    width: UiModalSizesEnum = UiModalSizesEnum.SMALL,
    modalStyles?: string,
  ) => {
    if (width) setModalWidth(width);
    if (modalStyles) setClassName(modalStyles!);
    setModalView(content);
    setVisible(true);
  };

  return (
    <ModalContext.Provider value={{ visible, setVisible, renderModal, modalView }}>
      {windowWidth! > WindowSizeEnum.Mobile ? (
        <Modal
          open={visible}
          className={styles.modal}
          bodyStyle={{ padding: 0 }}
          footer={null}
          closeIcon={<CloseIcon className={styles.closeIcon} />}
          modalRender={(node) => (
            <div ref={modalContentRef} className={classNameCompose(styles.modalContentWrap, className)}>
              {node}
            </div>
          )}
          onCancel={() => setVisible(false)}
          width={modalWidth}
          destroyOnClose
        >
          {modalView}
        </Modal>
      ) : (
        <Drawer
          rootClassName={styles.drawerContent}
          closeIcon={<CloseIcon className={styles.closeIcon} />}
          placement="bottom"
          height="top"
          destroyOnClose
          onClose={() => setVisible(false)}
          open={visible}
        >
          {modalView}
        </Drawer>
      )}

      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
