import React, { useCallback, useContext, useState } from 'react';
import { Form, Select, Switch } from 'antd';
import UiButton from '@components/ui/button/UiButton';
import StateEnum from '@constants/state.enum';
import useNotification from '@hooks/notification/useNotification';
import { getDepartmentNames } from '@services/department/department-http.service';
import { DepartmentName } from '@models/department/departmentsModel';
import { sendOnboardingEmail } from '@services/onboarding/onboarding-http.service';
import { createNewHire } from '@services/hire/hire-http.service';
import eventBus, { CustomEventTypes } from '@services/EventBus';
import useFetchData from '@hooks/useFetchData';
import dateFormat, { emptyDate, inputDateFormat } from '@constants/date-format';
import UiDatePicker from '@components/ui/date-picker/UiDatePicker';
import dayjs from 'dayjs';
import ErrorMessagesEnum from '@constants/error-messages.enum';
import { getEmployeeAccount } from '@services/employee/employee-http.service';
import { ModalContext } from '@contexts/ModalContext';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import PositionTitlesSelect from '@components/positions-select/PositionTitlesSelect';
import ReportingLinesSelect from '@components/reporting-lines-select/ReportingLinesSelect';
import styles from './HireEmployeeForm.module.scss';

type HireEmployeeFormProps = {
  employeeId: string;
  onSubmit?: Function;
  withSteps?: boolean;
};

const HireEmployeeForm: React.FC<HireEmployeeFormProps> = ({ employeeId, onSubmit, withSteps }) => {
  const { useTypedTranslation } = makeTypedTranslation('common', 'notification');
  const { t: translate } = useTypedTranslation('common');
  const { t: translateNotification } = useTypedTranslation('notification');

  const { setVisible } = useContext(ModalContext);
  const [form] = Form.useForm();
  const [hiring, setHiring] = useState<boolean>(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const { Option } = Select;

  const [successNotification] = useNotification(StateEnum.SUCCESS, translateNotification('status.success'));
  const [errorNotification] = useNotification(StateEnum.ERROR, translateNotification('status.error'));

  const departmentsFetcher = useCallback(async () => {
    const departmentNames = await getDepartmentNames();
    return departmentNames;
  }, []);

  const { data: departments } = useFetchData<Array<DepartmentName>>(departmentsFetcher);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSubmit(hasErrors);
  };

  const onFinish = async (values: any) => {
    const startDate = values.start_day && dayjs(values.start_day).format(inputDateFormat);
    const endDate = values.end_day && dayjs(values.end_day).format(inputDateFormat);

    try {
      setHiring(true);
      await createNewHire(values.position_title, employeeId!, startDate, values.reports_to, endDate, values.department);
      successNotification(undefined, translate('hire_employee_form.hire_created'));
      if (values.send_onboarding_email) {
        try {
          const account = await getEmployeeAccount(employeeId);
          await sendOnboardingEmail(employeeId);
          successNotification(
            undefined,
            translate('hire_employee_form.onboarding_sent', { employeeEmail: account.account_email }),
          );
        } catch (e: any) {
          errorNotification(undefined, translate('hire_employee_form.could_not_send_onboarding'));
        }
      }
      setHiring(false);
      form.resetFields();
      setVisible(false);
      onSubmit?.();
      eventBus.getInstance().dispatch(CustomEventTypes.EMPLOYEES_LIST_UPDATED);
      eventBus.getInstance().dispatch(CustomEventTypes.EMPLOYEE_BASIC_INFO_UPDATED);
      eventBus.getInstance().dispatch(CustomEventTypes.ORG_CHART_UPDATED);
    } catch (e: any) {
      setHiring(false);
      if (e.message === ErrorMessagesEnum.DATES_CONFLICT) {
        errorNotification(undefined, translate('messages.error.dates_conflict'));
      } else {
        errorNotification(undefined, translate('messages.error.something_went_wrong'));
      }
    }
  };

  const onSkip = () => {
    onSubmit?.();
    setVisible(false);
  };

  return (
    <>
      <h5>{translate('hire_employee_form.hire_employee')}</h5>
      <Form
        form={form}
        name="hireEmployee"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        size="small"
        onFieldsChange={handleFormChange}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className={styles.wrapModal}
      >
        <div className={styles.formFields}>
          <Form.Item
            label={translate('hire_employee_form.position_title')}
            name="position_title"
            rules={[{ required: true, message: translate('hire_employee_form.please_give_position_title') }]}
          >
            <PositionTitlesSelect />
          </Form.Item>
          <Form.Item
            label={translate('hire_employee_form.department')}
            name="department"
            className={styles.departmentField}
          >
            <Select placeholder={translate('hire_employee_form.select_department')} allowClear disabled={hiring}>
              {departments?.map((department) => (
                <Option key={department.id} value={department.id}>
                  {department.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={translate('hire_employee_form.reports_to')} name="reports_to">
            <ReportingLinesSelect data-testid="hire-employee-reports-to" />
          </Form.Item>
          <div className={styles.dateFields}>
            <Form.Item
              label={translate('hire_employee_form.start_day')}
              name="start_day"
              rules={[{ required: true, message: translate('hire_employee_form.please_give_start_day') }]}
            >
              {/* @ts-ignore */}
              <UiDatePicker
                placeholder={emptyDate}
                allowClear
                inputReadOnly
                showToday={false}
                format={dateFormat}
                data-testid="position-start-day"
                disabled={hiring}
              />
            </Form.Item>
            <Form.Item label={translate('hire_employee_form.end_day')} name="end_day">
              {/* @ts-ignore */}
              <UiDatePicker
                placeholder={emptyDate}
                allowClear
                inputReadOnly
                showToday={false}
                format={dateFormat}
                disabled={hiring}
                disabledDate={
                  (current) =>
                    (current && current.valueOf() < Date.now() - 2) ||
                    current.valueOf() <= form.getFieldsValue().start_day
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            </Form.Item>
          </div>
          <div className={styles.inlineSwitch}>
            <Form.Item className="no-margin" valuePropName="checked" name="send_onboarding_email">
              <Switch data-testid="onboarding-switch" />
            </Form.Item>
            <span className={styles.switchTitle}>{translate('hire_employee_form.onboarding_email')}</span>
          </div>
        </div>
        <Form.Item wrapperCol={{ offset: 0, span: 24 }} className={styles.wrapButton}>
          <div className={styles.wrapButton}>
            {withSteps && (
              <UiButton
                className={styles.skipBtn}
                type="link"
                size="small"
                onClick={onSkip}
                data-testid="position-hire"
              >
                {translate('hire_employee_form.skip_now')}
              </UiButton>
            )}
            <div className={styles.wrapButtonsWithoutSteps}>
              <UiButton
                type="primary"
                size="small"
                htmlType="submit"
                data-testid="position-hire-button"
                disabled={disabledSubmit}
                loading={hiring}
              >
                {translate('hire_employee_form.hire')}
              </UiButton>
              {!withSteps && (
                <UiButton size="small" className={styles.cancelButton} onClick={() => setVisible(false)}>
                  {translate('hire_employee_form.cancel')}
                </UiButton>
              )}
            </div>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default HireEmployeeForm;
