import * as React from 'react';

// Don't overuse this

type AppStateType = {
  bannerIsVisible: boolean;
};

type AppContextType = {
  appState: AppStateType;
  setAppState: React.Dispatch<React.SetStateAction<AppStateType>>;
};

const AppContext = React.createContext<AppContextType>({ appState: { bannerIsVisible: false }, setAppState: () => {} });

const AppProvider = ({ children }: { children: JSX.Element }) => {
  const [appState, setAppState] = React.useState<AppStateType>({ bannerIsVisible: false });

  return <AppContext.Provider value={{ appState, setAppState }}>{children}</AppContext.Provider>;
};

export { AppProvider, AppContext };
