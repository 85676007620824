import React from 'react';
import AuthLayout from '@components/layout/auth-layout/AuthLayout';
import EmployeeLayout from './employee-layout/EmployeeLayout';
import Layout from './admin-layout/Layout';
import PlainLayout from './plain-layout/PlainLayout';

export enum LayoutType {
  DEFAULT = 'default',
  AUTH = 'authLayout',
  EMPLOYEE = 'employeeLayout',
  PLAIN = 'plainLayout',
}

const LAYOUTS = {
  [LayoutType.DEFAULT]: Layout,
  [LayoutType.AUTH]: AuthLayout,
  [LayoutType.EMPLOYEE]: EmployeeLayout,
  [LayoutType.PLAIN]: PlainLayout,
};

const LayoutWrapper = (props: { children: React.ReactNode }) => {
  // @ts-ignore
  const ComponentLayout = LAYOUTS[props.children.type.layout];

  if (ComponentLayout != null) {
    return <ComponentLayout {...props}>{props.children}</ComponentLayout>;
  }
  return <Layout {...props}>{props.children}</Layout>;
};

export default LayoutWrapper;
