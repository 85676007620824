// @ts-nocheck
/* eslint-disable */

const self = this || {};

try {
  self.EventTarget = new EventTarget().constructor;
} catch (EventTarget) {
  ((Object, wm) => {
    const { create, defineProperty } = Object;
    const { prototype: proto } = EventTarget;
    define(proto, 'addEventListener', function (type, listener, options) {
      for (
        var secret = wm.get(this), listeners = secret[type] || (secret[type] = []), i = 0, { length } = listeners;
        i < length;
        i++
      ) {
        if (listeners[i].listener === listener) return;
      }
      listeners.push({ target: this, listener, options });
    });
    define(proto, 'dispatchEvent', function (event) {
      const secret = wm.get(this);
      const listeners = secret[event.type];
      if (listeners) {
        define(event, 'target', this);
        define(event, 'currentTarget', this);
        listeners.slice(0).some(dispatch, event);
        delete event.currentTarget;
        delete event.target;
      }
      return true;
    });
    define(proto, 'removeEventListener', function (type, listener) {
      for (
        let secret = wm.get(this),
          /* istanbul ignore next */
          listeners = secret[type] || (secret[type] = []),
          i = 0,
          { length } = listeners;
        i < length;
        i++
      ) {
        if (listeners[i].listener === listener) {
          listeners.splice(i, 1);
          return;
        }
      }
    });
    self.EventTarget = EventTarget;
    function EventTarget() {
      wm.set(this, create(null));
    }
    function define(target, name, value) {
      defineProperty(target, name, {
        configurable: true,
        writable: true,
        value,
      });
    }
    function dispatch(info) {
      const { options } = info;
      if (options && options.once) info.target.removeEventListener(this.type, info.listener);
      if (typeof info.listener === 'function') info.listener.call(info.target, this);
      else info.listener.handleEvent(this);
      return this._stopImmediatePropagationFlag;
    }
  })(Object, new WeakMap());
}

export default self.EventTarget;
