import React from 'react';
import EmployeeStatus from '@constants/employer-status/employer-status.enum';
import Avatar from '@components/ka-ui/avatar/Avatar';
import classNameCompose from '@utils/classname-compose';
import { useRouter } from 'next/router';
import styles from './EmployeeSummary.module.scss';

type EmployeeSummaryProps = {
  data: {
    fullName: string;
    status: EmployeeStatus;
    imgUrl?: string;
    subheading?: string | React.ReactNode;
  };
  // TODO merge redirectTo and onClick into one prop - onClick: string | Function
  redirectTo?: string;
  onClick?: Function;
  inline?: boolean;
  className?: string;
};

const EmployeeSummary: React.FC<EmployeeSummaryProps> = ({
  data,
  redirectTo,
  onClick,
  inline,
  className,
}: EmployeeSummaryProps) => {
  const router = useRouter();

  const onItemClick = () => {
    if (onClick) {
      onClick();
    } else if (redirectTo) {
      router.push(redirectTo);
    }
  };

  return (
    <button type="button" className={`no-button-styles ${className}`} onClick={onItemClick} data-testid="profile-link">
      <div className={classNameCompose(styles.summaryWrap, inline ? styles.inline : '')}>
        <div className={styles.avatarWrap}>
          <Avatar status={data.status} image={data.imgUrl} />
        </div>
        <div className={styles.textWrap}>
          <span className={`large-caps-bold primary-light h5 ${styles.name}`}>{data.fullName}</span>
          <span className={styles.subheading}>{data.subheading}</span>
        </div>
      </div>
    </button>
  );
};

export default EmployeeSummary;
