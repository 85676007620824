import React from 'react';
import CloseIcon from '@assets/svgs/cross-icon.svg';
import { Drawer } from 'antd';
import { UserColleaguesProvider } from '@contexts/UserColleaguesContext';
import classNameCompose from '@utils/classname-compose';
import useWindowDimensions from '@hooks/useWindowDimentions';
import { WindowSizeEnum } from '@constants/window-size';
import styles from './ColleagueDrawer.module.scss';
import ColleagueSummary from '../colleague-summary/ColleagueSummary';

type ColleagueDrawerProps = {
  employeeId: string;
  visible: boolean;
  toggleDrawer: Function;
};

const ColleagueDrawer: React.FC<ColleagueDrawerProps> = ({ employeeId, visible, toggleDrawer }) => {
  const { width } = useWindowDimensions();
  const isMobile = width! < WindowSizeEnum.Mobile;

  return (
    <>
      <UserColleaguesProvider>
        <>
          <Drawer
            rootClassName={classNameCompose(styles.drawerContent, isMobile ? styles.mobile : styles.desktop)}
            closeIcon={<CloseIcon className={styles.closeIcon} />}
            placement={isMobile ? 'bottom' : 'right'}
            onClose={() => toggleDrawer()}
            open={visible}
            destroyOnClose
          >
            <ColleagueSummary employeeId={employeeId} className={styles.colleagueSummaryDrawerBody} />
          </Drawer>
        </>
      </UserColleaguesProvider>
    </>
  );
};

export default ColleagueDrawer;
