import React, { useEffect, useState } from 'react';
import { Form, Input, Divider } from 'antd';
import UiButton from '@components/ui/button/UiButton';
import useNotification from '@hooks/notification/useNotification';
import { createDepartment, updateDepartment } from '@services/department/department-http.service';
import { CreateDepartmentDto, UpdateDepartmentDto } from '@models/department';
import eventBus, { CustomEventTypes } from '@services/EventBus';
import ErrorMessagesEnum from '@constants/error-messages.enum';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { DepartmentsTableDataType } from '@models/department/departmentsModel';
import styles from './DepartmentForm.module.scss';

const { TextArea } = Input;

export type CreateDepartmentFormProps = {
  setVisible: (visible: boolean) => void;
  initialFormValues?: DepartmentsTableDataType;
};

const DepartmentForm: React.FC<CreateDepartmentFormProps> = ({ setVisible, initialFormValues }) => {
  const [form] = Form.useForm();
  const { useTypedTranslation } = makeTypedTranslation('common', 'notification', 'forms');
  const { t: translate } = useTypedTranslation('common');
  const { t: translateForms } = useTypedTranslation('forms');
  const { t: translateNotification } = useTypedTranslation('notification');
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const [titleField, setTitleField] = useState(initialFormValues ? initialFormValues?.title : '');

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSubmit(hasErrors);
  };

  const [errorNotification] = useNotification('error', translateNotification('status.error'));
  const [successNotification] = useNotification('success', translateNotification('status.success'));

  const onFinish = (onFinishAction: any) => async (values: any) => {
    try {
      await onFinishAction(values);
      eventBus.getInstance().dispatch(CustomEventTypes.DEPARTMENTS_UPDATED);
      setVisible(false);
    } catch (e: any) {
      if (e.message === ErrorMessagesEnum.DEPARTMENT_TITLE_EXISTS) {
        errorNotification(undefined, translate('create_department.department_title_exists'));
      } else {
        errorNotification(undefined, translate('messages.error.something_went_wrong'));
      }
    }
  };

  const onCreate = onFinish(async ({ title, description }: CreateDepartmentDto) => {
    await createDepartment({ title, description, active: true });
    successNotification(undefined, translate('create_department.department_created'));
  });

  const onUpdate = onFinish(async ({ title, description }: UpdateDepartmentDto) => {
    await updateDepartment(initialFormValues?.key!, { title, description, active: initialFormValues?.active! });
    successNotification(undefined, translate('create_department.department_updated'));
  });

  useEffect(() => {
    const capitalizeTitleField = () => {
      setTitleField(
        titleField
          .toUpperCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      );
      form.setFieldsValue({
        title: titleField,
      });
    };

    const timeoutId = setTimeout(capitalizeTitleField, 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [titleField]);

  return (
    <Form
      form={form}
      name="department"
      layout="vertical"
      size="small"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFieldsChange={handleFormChange}
      initialValues={{
        description: initialFormValues?.description,
      }}
      onFinish={initialFormValues ? onUpdate : onCreate}
      autoComplete="off"
    >
      <h5 className="h5 uppercase">
        {initialFormValues
          ? translate('create_department.edit_department')
          : translate('create_department.new_department')}
      </h5>
      <Form.Item
        label={translate('create_department.department_title')}
        name="title"
        rules={[{ required: true, message: translateForms('required_minimal') }]}
      >
        <Input
          placeholder={translate('create_department.give_title')}
          data-testid="department-title"
          maxLength={40}
          onChange={(e) => setTitleField(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label={translate('create_department.department_description')}
        name="description"
        className={styles.descriptionField}
        rules={[{ max: 300, message: translateForms('max_300_chars_error') }]}
      >
        <TextArea
          showCount
          rows={6}
          placeholder={translate('create_department.give_description')}
          maxLength={300}
          data-testid="department-description"
        />
      </Form.Item>
      <Divider />
      <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
        <div className={styles.actionButtons}>
          <UiButton
            type="primary"
            size="small"
            htmlType="submit"
            disabled={disabledSubmit}
            className={styles.submitButton}
            data-testid="action-form-btn"
          >
            {initialFormValues ? translate('create_department.save') : translate('create_department.create')}
          </UiButton>
          <UiButton size="small" onClick={() => setVisible(false)}>
            {translate('create_department.cancel')}
          </UiButton>
        </div>
      </Form.Item>
    </Form>
  );
};

export default DepartmentForm;
