import RoutesEnum from '@constants/routes.enum';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

const usePath = () => {
  const router = useRouter();
  const { domain } = router.query;

  const getPathWithPrefix = useCallback(
    (route: RoutesEnum | string) => {
      return `/client/${domain}${route}`;
    },
    [domain],
  );

  const appPathPrefix = useMemo(() => {
    return `/client/${domain}`;
  }, [domain]);

  return { getPathWithPrefix, appPathPrefix };
};

export default usePath;
