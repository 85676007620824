import { hrmsBackEndUrl, hrmsInstance } from '@utils/http-client';

const hires = `${hrmsBackEndUrl}/hires`;

export const createNewHire = (
  position_title: string,
  employee_id: string,
  start_date: string,
  reports_to?: string,
  end_date?: string,
  department_id?: string,
): Promise<string> => {
  return hrmsInstance.post(`${hires}/start`, {
    position_title,
    start_date,
    reports_to,
    end_date,
    department_id,
    employee_id,
  });
};

export const createNewEndHire = (
  employee_id: string,
  end_date: string,
  reason_of_termination: string,
  termination_description?: string,
): Promise<string> => {
  return hrmsInstance.post(`${hires}/end`, {
    employee_id,
    end_date,
    reason_of_termination,
    termination_description,
  });
};
