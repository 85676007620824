export enum AnnouncementStatusEnum {
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
}

export type GetAnnouncements = {
  data: AnnouncementSummary[];
  total: number;
};

export type Announcement = {
  id: string;
  title: string;
  announcement_type: AnnouncementStatusEnum;
  publish_date: string;
  content: string;
  created_at: string;
  tags: string[];
};

export type AnnouncementSummary = {
  title: string;
  id: string;
  created_at: string;
  announcement_type: AnnouncementStatusEnum;
  publish_date: string;
  email_sent: boolean;
};

export type CreateAnnouncement = {
  title: string;
  content?: string;
  tags: string[];
  publish_date: string;
  send_email: boolean;
};

export type UpdateAnnouncement = {
  title: string;
  content?: string;
  tags: string[];
  publish_date: string;
};

export type UserAnnouncement = {
  id: string;
  title: string;
  publish_date: string;
  content: string;
  created_at: string;
  tags: string[];
};
