import { useTranslation } from 'next-i18next';
import { TOptions, StringMap } from 'i18next';
import { UseTranslationOptions } from 'react-i18next';
import { translations } from './translations';

type TranslationType = typeof translations;
type TranslationTypeKey = keyof TranslationType;
type TranslationPicker<T extends TranslationTypeKey[]> = Pick<TranslationType, T[number]>;

const makeTypedTranslation = <T extends TranslationTypeKey[]>(...files: T) => {
  const useTypedTranslation = <K extends T[number]>(v: K | K[], p?: UseTranslationOptions<undefined>) => {
    const { t: translate, i18n } = useTranslation(v, p);
    const t = (s: TranslationPicker<T>[K][number], c?: string | TOptions<StringMap>) => translate(s as any, c);
    // @ts-ignore
    t.ns = translate.ns;
    return {
      t,
      i18n,
    };
  };
  return {
    useTypedTranslation,
    files,
  };
};

export default makeTypedTranslation;
