/* eslint-disable import/prefer-default-export */
/**
 * Regex that accepts following phone number formats
 * (123) 456-7890
 * (123)456-7890
 * 123-456-7890
 * 123.456.7890
 * 1234567890
 * +31636363634
 * 075-63546725
 */

export const PHONE_REGEX = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s'\\.]?[0-9]{4,6}$/im;

/**
 * A regex that limits the email to 254 chacters.
 * The prefix can have letters (a-z), numbers, underscores,
 * periods, and dashes. An underscore, period, or dash must
 * be followed by one or more letters or numbers.
 * The domain can have letters, numbers, dashes. The last
 * portion of the domain must be at least two characters.
 */
export const EMAIL_REGEX =
  /^(?=[A-Z0-9][A-Z0-9@._-]{5,253}$)[A-Z0-9._-]{1,64}[A-Z0-9]@(?:(?=[A-Z0-9-]{1,63}\.)[A-Z0-9]+(?:-[A-Z0-9]+)*\.){1,8}[A-Z]{2,63}$/iu;

export const PASSWORD_LENGTH_REGEX = /[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;
export const PASSWORD_LETTERS_REGEX = /(?=.*[a-zA-Z])/;
export const PASSWORD_NUMBERS_REGEX = /^(?=.*\d)/;
export const PASSWORD_SPECIAL_CHARACTERS_REGEX = /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;

export const PAST_DATE_INPUT_REGEX = /(((0|1)[0-9]|2[0-9]|3[0-1]) \/ (0[1-9]|1[0-2]) \/ ((19|(2[20-29]))\d\d))$/;

export const ADMIN_URL_REGEX = /client\/([a-zA-Z0-9-]+)\/admin/;
export const EMPLOYEE_URL_REGEX = /client\/([a-zA-Z0-9-]+)\/employee/;

export const ONLY_SMALL_LETTERS = /^[a-z0-9]+$/;

export const IBAN_REGEX =
  /^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{,16}n$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/;
