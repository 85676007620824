import React from 'react';
import styles from './AppFeatureInfo.module.scss';

type AppFeatureInfoProps = {
  icon: React.ReactNode;
  title: string;
};
const AppFeatureInfo = ({ icon, title }: AppFeatureInfoProps) => {
  const Icon = icon as any;

  return (
    <div className={styles.featureContainer}>
      <Icon className="icon-xlarge" />
      <h5 className="h5 no-margin primary">{title}</h5>
    </div>
  );
};

export default AppFeatureInfo;
