import { hrmsBackEndUrl, hrmsInstance } from '@utils/http-client';
import {
  CreateHolidayRequestDto,
  GetHolidaysList,
  GetCalendarHolidays,
  UpdateHolidayRequestDto,
} from '@services/holidays/type';

const baseUrl = `${hrmsBackEndUrl}/holidays`;

export const getHolidays = (year: number): Promise<GetHolidaysList[]> => {
  return hrmsInstance.get(`${baseUrl}`, { params: { year } });
};

export const createHoliday = (body: CreateHolidayRequestDto): Promise<void> => {
  return hrmsInstance.post(`${baseUrl}`, body);
};

export const updateHoliday = (id: string, body: UpdateHolidayRequestDto): Promise<void> => {
  return hrmsInstance.put(`${baseUrl}/${id}`, body);
};

export const getCalendarHolidays = (props: { dateFrom: string; dateTo: string }): Promise<GetCalendarHolidays[]> => {
  const params = Object.entries({
    from: props.dateFrom,
    to: props.dateTo,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (typeof value !== 'number' && !value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${baseUrl}/calendar`, {
    params,
  });
};

export const deleteHoliday = (id: string): Promise<void> => {
  return hrmsInstance.delete(`${baseUrl}/${id}`);
};
