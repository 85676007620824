import React from 'react';

export interface StepperContextProps {
  step: number;
  totalSteps: number;
  next: Function;
  back: Function;
  reset: Function;
  goToStep: Function;
}

export const StepperContext = React.createContext<StepperContextProps>({
  step: 0,
  totalSteps: 0,
  next: () => {},
  back: () => {},
  reset: () => {},
  goToStep: () => {},
});

export const useStepperContext = () => {
  const context = React.useContext(StepperContext);
  if (!context) {
    throw new Error(`Stepper compound components cannot be rendered outside the Stepper component`);
  }
  return context;
};
