import React, { useCallback, useRef, useState } from 'react';
import { getAdminNotifications } from '@services/notifications/notification-http.service';
import useFetchDataInfinite from '@hooks/useFetchDataInfinite';
import NotificationsList from '@components/notfications-list/NotificationsList';
import { AdminNotification } from '@components/notfications-list/type';
import useAdminNotifications from '@hooks/notification/useAdminNotifications';
import styles from './AdminNotificationsList.module.scss';

const REQUEST_LIMIT = 5;

const AdminNotificationsList: React.FC = () => {
  const [lastNotficationId, setLastNotficationId] = useState<string>();
  const [reachedEnd, setReachedEnd] = useState<boolean>(false);

  const fetcher = useCallback(async () => {
    const announcements = await getAdminNotifications(REQUEST_LIMIT, lastNotficationId, false);
    if (announcements && announcements.length) {
      const lastItem = announcements[announcements?.length - 1];
      setLastNotficationId(lastItem.id);
    }
    if (REQUEST_LIMIT > announcements?.length) setReachedEnd(true);
    return announcements;
  }, [lastNotficationId]);

  const { data, loading, toggleNextFetch } = useFetchDataInfinite<AdminNotification>(fetcher, reachedEnd);

  const notifications = useAdminNotifications(data);

  const observer = useRef<IntersectionObserver>();
  const lastElRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          toggleNextFetch();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, toggleNextFetch],
  );

  return (
    <>
      <NotificationsList
        className={styles.notificationsList}
        notifications={notifications}
        loading={loading}
        reachedEnd={reachedEnd}
      />
      <div className={styles.lastEl} ref={lastElRef} />
    </>
  );
};

export default AdminNotificationsList;
