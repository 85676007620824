class Mailbox {
  data: any = {};

  get(key: string) {
    const value = this.data[key];
    delete this.data[key];
    return value;
  }

  set(key: string, value: any): void {
    this.data[key] = value;
  }
}
const mailbox = new Mailbox();

export default mailbox;
