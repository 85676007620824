import React from 'react';
import UiButton from '@components/ui/button/UiButton';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import RoutesEnum from '@constants/routes.enum';
import { useRouter } from 'next/router';
import usePath from '@hooks/usePath';

const ExpiredFreeTrialCountdown = () => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');
  const router = useRouter();
  const { getPathWithPrefix } = usePath();

  return (
    <>
      <h5 className="h5">{translate('subscription.free_trial_ended')}</h5>
      <UiButton
        type="primary"
        size="xsmall"
        data-testid="activate-btn"
        onClick={() => router.push(getPathWithPrefix(`${RoutesEnum.ADMIN_SETTING_ACCOUNT}?active_tab=SUBSCRIPTION`))}
      >
        {translate('subscription.activate')}
      </UiButton>
    </>
  );
};

export default ExpiredFreeTrialCountdown;
