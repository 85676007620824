import UiAlert from '@components/ui/alert/UiAlert';
import { AuthLayoutContext, AuthLayoutErrorTypes } from '@contexts/AuthLayoutContext';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import React, { useContext, useEffect, useState } from 'react';
import styles from './AuthLayoutError.module.scss';

const AuthLayoutError = () => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const { errorMessage, setErrorMessage } = useContext(AuthLayoutContext);

  const [message, setMessage] = useState<string | null>();

  const handleClose = () => {
    setMessage(null);
    setErrorMessage();
  };

  useEffect(() => {
    switch (errorMessage) {
      case AuthLayoutErrorTypes.EXPIRED_TOKEN:
        setMessage(translate('messages.error.expired_token'));
        break;
      case AuthLayoutErrorTypes.PORTAL_NAME_EXISTS:
        setMessage(translate('messages.error.portal_name_exists'));
        break;
      case AuthLayoutErrorTypes.GENERIC_ERROR:
        setMessage(translate('messages.error.something_went_wrong'));
        break;
      case AuthLayoutErrorTypes.INVALID_CREDENTIALS:
        setMessage(translate('messages.error.invalid_credentials'));
        break;
      case AuthLayoutErrorTypes.EMAIL_EXISTS:
        setMessage(translate('messages.error.email_exists'));
        break;
      default:
        setMessage(null);
    }
  }, [errorMessage]);

  return (
    <>
      {errorMessage && (
        <div className={styles.layoutInfoErrorWrap}>
          <UiAlert type="error" message={message} afterClose={handleClose} data-testid={errorMessage} closable />
        </div>
      )}
    </>
  );
};

export default AuthLayoutError;
