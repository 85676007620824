import {
  LeaveRangeInfo,
  CreateUserLeaveRequestDto,
  RejectRequest,
  CreateLeaveTypeDto,
  UpdateLeaveTypeDto,
  CreateLeaveRequestDto,
  CreateLeaveManualAdjustmentDto,
} from '@models/leaves';
import { hrmsInstance, hrmsBackEndUrl } from '@utils/http-client';
import LeavesModel from '@models/leaves/leavesModel';
import LeaveModel, { GetLeaveManualAdjustmentHistory, TimeOffType } from '@models/leaves/model';
import {
  AccrualPolicy,
  GetEmployeeRequestBalances,
  GetPersonalBalance,
  GetPolicyEligibleEmployees,
  GetTimeOffAvailableDays,
  GetTimeOffTypePolicy,
  GetUserCalendarLeaves,
  GetUserLeavesList,
} from '@services/leaves/type';
import dayjs from 'dayjs';
import { RcFile } from 'antd/lib/upload';

const userLeaves = `${hrmsBackEndUrl}/users/leaves`;
const leaves = `${hrmsBackEndUrl}/leaves`;

// eslint-disable-next-line import/prefer-default-export
export const getUserLeaveRangeInfo = (
  start_date: string,
  end_date: string,
  leave_type_id: string,
  half_day_part: string,
): Promise<LeaveRangeInfo> => {
  return hrmsInstance.get(`${userLeaves}/leave-range-info`, {
    params: { start_date, end_date, leave_type_id, half_day_part },
  });
};

export const getLeaveRangeInfo = (
  start_date: string,
  end_date: string,
  leave_type_id: string,
  employee_id: string,
  half_day_part: string,
): Promise<LeaveRangeInfo> => {
  return hrmsInstance.get(`${leaves}/leave-range-info`, {
    params: { start_date, end_date, leave_type_id, employee_id, half_day_part },
  });
};

export const createLeaveRequest = (body: CreateLeaveRequestDto): Promise<void> => {
  return hrmsInstance.post(`${leaves}/request/new`, body);
};

export const getLeaveById = (id: string): Promise<LeaveModel> => {
  return hrmsInstance.get(`${leaves}/${id}`);
};

export const getAllLeaves = (
  page: number,
  psize: number | string,
  status?: string,
  sortBy?: string,
  orderBy?: string,
  locationsIds?: string,
  query?: string,
  dateTo?: string,
  dateFrom?: string,
): Promise<LeavesModel> => {
  const params = Object.entries({
    leavestatus: status,
    page,
    psize,
    sortby: sortBy,
    orderby: orderBy,
    locations: locationsIds,
    name: query,
    to: dateTo,
    from: dateFrom,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (typeof value !== 'number' && !value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${leaves}/list`, {
    params,
  });
};

export const getTimeOffAvailableDays = (
  leave_type_id: string,
  employee_id: string,
): Promise<GetTimeOffAvailableDays> => {
  return hrmsInstance.get(`${leaves}/available-days`, { params: { leave_type_id, employee_id } });
};

export const approveRequest = (id: string): Promise<void> => {
  return hrmsInstance.post(`${leaves}/request/${id}/approve`);
};

export const rejectRequest = (id: string, body: RejectRequest): Promise<void> => {
  return hrmsInstance.post(`${leaves}/request/${id}/decline`, body);
};

export const approveCancelationRequest = (id: string): Promise<void> => {
  return hrmsInstance.post(`${leaves}/request/${id}/cancel/approve`);
};

export const declineCancelationRequest = (id: string): Promise<void> => {
  return hrmsInstance.post(`${leaves}/request/${id}/cancel/decline`);
};

export const getCalendarLeaves = (props: {
  leaveTypeIds?: string;
  dateFrom: string;
  dateTo: string;
  departmentsIds?: string;
  locationsIds?: string;
  query?: string;
}): Promise<GetUserCalendarLeaves> => {
  const params = Object.entries({
    type_ids: props.leaveTypeIds,
    from: props.dateFrom,
    to: props.dateTo,
    deps: props.departmentsIds,
    locations: props.locationsIds,
    name: props.query,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (!value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${leaves}/calendar`, {
    params,
  });
};

export const getTimeOffTypes = (props?: { employeeId?: string }): Promise<Array<TimeOffType>> => {
  const params = Object.entries({
    employee_id: props?.employeeId,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (!value) return acc;
    acc[key] = value;
    return acc;
  }, {});

  return hrmsInstance.get(`${leaves}/types`, {
    params,
  });
};

export const activateTimeOffType = (id: string): Promise<Array<TimeOffType>> => {
  return hrmsInstance.post(`${leaves}/types/${id}/activate`);
};

export const deactivateTimeOffType = (id: string): Promise<Array<TimeOffType>> => {
  return hrmsInstance.post(`${leaves}/types/${id}/deactivate`);
};

export const createLeaveType = (body: CreateLeaveTypeDto): Promise<void> => {
  return hrmsInstance.post(`${leaves}/types`, body);
};

export const updateLeaveType = (id: string, body: UpdateLeaveTypeDto): Promise<void> => {
  return hrmsInstance.put(`${leaves}/types/${id}`, body);
};

export const getTimeOffTypePolicies = (typeId: string): Promise<GetTimeOffTypePolicy> => {
  return hrmsInstance.get(`${leaves}/types/${typeId}/policies`);
};

export const getTimeOffTypePolicy = (id: string): Promise<AccrualPolicy> => {
  return hrmsInstance.get(`${leaves}/policies/${id}`);
};

export const updateTimeOffTypePolicy = (id: string, policy: AccrualPolicy): Promise<AccrualPolicy> => {
  return hrmsInstance.put(`${leaves}/policies/${id}`, policy);
};
export const createTimeOffTypePolicy = (typeId: string, policy: AccrualPolicy): Promise<AccrualPolicy> => {
  return hrmsInstance.post(`${leaves}/types/${typeId}/policies`, policy);
};

export const activatePolicy = (policyId: string): Promise<void> => {
  return hrmsInstance.post(`${leaves}/policies/${policyId}/activate`);
};

export const deactivatePolicy = (policyId: string): Promise<void> => {
  return hrmsInstance.post(`${leaves}/policies/${policyId}/deactivate`);
};

export const policySwap = (policyId: string, step: number): Promise<void> => {
  return hrmsInstance.post(`${leaves}/policies/${policyId}/move`, { step });
};

export const getPolicyEligibleEmployees = (props: {
  policyId: string;
  endYear?: boolean;
}): Promise<GetPolicyEligibleEmployees[]> => {
  const params = Object.entries({
    end_year: props.endYear,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (!value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${leaves}/policies/${props.policyId}/eligible`, {
    params,
  });
};

export const getBalanceLeaves = (props: {
  leaveTypeId: string;
  departmentsIds?: string;
  locationsIds?: string;
  page: number;
  limit: number;
  sortBy?: string;
  orderBy?: string;
  cacheKey?: string | null;
  query?: string;
}): Promise<GetEmployeeRequestBalances> => {
  const params = Object.entries({
    deps: props.departmentsIds,
    locations: props.locationsIds,
    page: props.page,
    psize: props.limit,
    sortby: props.sortBy,
    orderby: props.orderBy,
    cache_key: props.cacheKey,
    name: props.query,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (!value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${leaves}/balances/${props.leaveTypeId}`, {
    params,
  });
};

export const createLeaveManualAdjustment = (
  id: string,
  employeeId: string,
  body: CreateLeaveManualAdjustmentDto,
): Promise<void> => {
  return hrmsInstance.post(`${leaves}/types/${id}/employee/${employeeId}/adjustment`, body);
};

export const getLeaveManualAdjustmentHistory = (
  id: string,
  employeeId: string,
): Promise<GetLeaveManualAdjustmentHistory[]> => {
  const currentYear = dayjs().year();
  return hrmsInstance.get(`${leaves}/types/${id}/employee/${employeeId}/adjustment/history`, {
    params: {
      year: currentYear,
    },
  });
};

// User leaves api

export const createUserLeaveRequest = (body: CreateUserLeaveRequestDto): Promise<void> => {
  return hrmsInstance.post(`${userLeaves}/request`, body);
};

export const getTeamLeaveById = (id: string): Promise<LeaveModel> => {
  return hrmsInstance.get(`${userLeaves}/${id}`);
};

export const getTeamLeaves = (
  page: number,
  psize: number | string,
  status?: string,
  sortBy?: string,
  orderBy?: string,
  locationsIds?: string,
  query?: string,
): Promise<LeavesModel> => {
  const params = Object.entries({
    leavestatus: status,
    page,
    psize,
    sortby: sortBy,
    orderby: orderBy,
    locations: locationsIds,
    name: query,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (typeof value !== 'number' && !value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${userLeaves}/team-list`, {
    params,
  });
};

export const getEmployeeTimeOffTypes = (includeTeam?: boolean): Promise<Array<TimeOffType>> => {
  const params = Object.entries({
    include_team: includeTeam,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (typeof value !== 'number' && !value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${userLeaves}/types`, {
    params,
  });
};

export const getUserTimeOffAvailableDays = (leave_type_id: string): Promise<GetTimeOffAvailableDays> => {
  return hrmsInstance.get(`${userLeaves}/available-days`, { params: { leave_type_id } });
};

export const approveTeamMemberRequest = (id: string): Promise<void> => {
  return hrmsInstance.post(`${userLeaves}/request/${id}/approve`);
};

export const rejectTeamMemberRequest = (id: string, body: RejectRequest): Promise<void> => {
  return hrmsInstance.post(`${userLeaves}/request/${id}/decline`, body);
};

export const getUserLeavesList = (
  year: number,
  sortBy?: string,
  orderBy?: string,
): Promise<Array<GetUserLeavesList>> => {
  const params = Object.entries({ sortby: sortBy, orderby: orderBy, year }).reduce(
    (acc: { [key: string]: any }, [key, value]) => {
      if (typeof value !== 'number' && !value) return acc;
      acc[key] = value;
      return acc;
    },
    {},
  );
  return hrmsInstance.get(`${userLeaves}/list`, {
    params,
  });
};

export const cancelLeave = (id: string): Promise<void> => {
  return hrmsInstance.post(`${leaves}/request/${id}/cancel`);
};

export const cancelUserLeave = (id: string): Promise<Array<GetUserLeavesList>> => {
  return hrmsInstance.post(`${userLeaves}/request/${id}/cancel`);
};

export const getUserCalendarLeaves = (props: {
  leaveTypeIds?: string;
  dateFrom: string;
  dateTo: string;
  departmentsIds?: string;
  locationsIds?: string;
  showOnlyTeam?: boolean;
  query?: string;
}): Promise<GetUserCalendarLeaves> => {
  const params = Object.entries({
    type_ids: props.leaveTypeIds,
    from: props.dateFrom,
    to: props.dateTo,
    deps: props.departmentsIds,
    locations: props.locationsIds,
    only_team: props.showOnlyTeam,
    name: props.query,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (!value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${userLeaves}/calendar`, {
    params,
  });
};

export const getUserBalanceLeaves = (props: {
  leaveTypeId: string;
  departmentsIds?: string;
  locationsIds?: string;
  page: number;
  limit: number;
  sortBy?: string;
  orderBy?: string;
  cacheKey?: string | null;
  query?: string;
}): Promise<GetEmployeeRequestBalances> => {
  const params = Object.entries({
    deps: props.departmentsIds,
    locations: props.locationsIds,
    page: props.page,
    psize: props.limit,
    sortby: props.sortBy,
    orderby: props.orderBy,
    cache_key: props.cacheKey,
    name: props.query,
  }).reduce((acc: { [key: string]: any }, [key, value]) => {
    if (!value) return acc;
    acc[key] = value;
    return acc;
  }, {});
  return hrmsInstance.get(`${userLeaves}/balances/${props.leaveTypeId}`, {
    params,
  });
};

export const getPersonalBalances = (): Promise<GetPersonalBalance[]> => {
  return hrmsInstance.get(`${userLeaves}/balance`);
};

export const validateManualAdjustmentFile = (file: RcFile): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  return hrmsInstance.post(`${hrmsBackEndUrl}/imports/validate/manual-adjustments`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const importManualAdjustment = (file: RcFile, skips: number): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  return hrmsInstance.post(`${hrmsBackEndUrl}/imports/manual-adjustments?skips=${skips}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
