import React from 'react';
import UserIcon from '@assets/svgs/user-icon.svg';
import Image from 'next/legacy/image';
import EmployeeStatus, { employeeStatusKey } from '@constants/employer-status/employer-status.enum';
import styles from './Avatar.module.scss';

type AvatarProps = {
  status: EmployeeStatus;
  className?: string;
  image?: string;
};

const Avatar: React.FC<AvatarProps> = ({ className, status, image }) => {
  const statusKey = employeeStatusKey(status);

  return (
    <div className={`${styles.profileImageContainer}`}>
      {image ? (
        <Image
          className={`${styles.profileImage} ${className} ${styles[statusKey]}`}
          alt="profile-image"
          src={image}
          priority
          // @ts-ignore
          width="100%"
          // @ts-ignore
          height="100%"
        />
      ) : (
        <UserIcon
          className={`${styles.profileImage} ${className} ${styles[statusKey]} ${statusKey}`}
          data-testid="avatar-user-icon"
        />
      )}
    </div>
  );
};

export default Avatar;
