import { UserAuthType } from '@models/user/auth';

/**
 * A functions that gets the first and last name of a user
 * and returns a string with the initial letters capitalized
 * @param firstName
 * @param lastName
 * @returns string
 */
export const getInitialsFromUserFullName = ({ firstName, lastName }: { firstName: string; lastName: string }): string =>
  `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}`;

/**
 * A function that checks if the user is logged in
 * @param user
 * @returns boolean
 */
export const isUserLoggedIn = (user: UserAuthType | {}): boolean => Object.keys(user).length > 0;
