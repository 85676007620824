enum ErrorMessagesEnum {
  DATES_CONFLICT = 'DATES_CONFLICT',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  DEPARTMENT_TITLE_EXISTS = 'DEPARTMENT_TITLE_EXISTS',
  EMPLOYEE_NUMBER_EXISTS = 'EMPLOYEE_NUMBER_EXISTS',
  LOCATION_EXISTS = 'LOCATION_EXISTS',
  TITLE_EXISTS = 'TITLE_EXISTS',
  EXCEEDS_MAX_DAYS = 'EXCEEDS_MAX_DAYS',
  EXCEEDS_MAX_YEARLY_DAYS = 'EXCEEDS_MAX_YEARLY_DAYS',
  PAST_DAYS_NOT_ALLOWED = 'PAST_DAYS_NOT_ALLOWED',
  LEAVE_TYPE_NAME_EXISTS = 'LEAVE_TYPE_NAME_EXISTS',
  HOLIDAY_NAME_EXISTS = 'HOLIDAY_NAME_EXISTS',
  DUPLICATE_DATE = 'DUPLICATE_DATE',
  INVALID_TOKEN = 'INVALID_TOKEN',
  FILE_TOO_LARGE = 'FILE_TOO_LARGE',
  INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
  IMPORT_INVALID_EXCEL_TEMPLATE = 'IMPORT_INVALID_EXCEL_TEMPLATE',
  POSITION_DATES_OVERLAP = 'POSITION_DATES_OVERLAP',
  WORKING_HOURS_TITLE_EXISTS = 'WORKING_HOURS_TITLE_EXISTS',
  TEMPLATE_ALREADY_EXISTS = 'TEMPLATE_ALREADY_EXISTS',
  CANDIDATE_ALREADY_EXISTS = 'CANDIDATE_ALREADY_EXISTS',
}

export default ErrorMessagesEnum;
