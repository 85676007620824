/* eslint-disable no-nested-ternary */
import ColleagueDrawer from '@components/user-colleagues/colleague-drawer/ColleagueDrawer';
import * as React from 'react';

type ColleagueDrawerContextProps = {
  renderDrawer: (employeeId: string) => void;
  toggleDrawer: () => void;
};

const ColleagueDrawerContext = React.createContext<ColleagueDrawerContextProps>({
  renderDrawer: () => {},
  toggleDrawer: () => {},
});

const ColleagueDrawerProvider = ({ children }: { children: JSX.Element }) => {
  const [drawerState, setDrawerState] = React.useState<{ visible: boolean; employeeId: string }>({
    visible: false,
    employeeId: '',
  });
  const toggleDrawer = () => setDrawerState({ ...drawerState, visible: !drawerState.visible });
  const renderDrawer = (id: string) => {
    setDrawerState({ employeeId: id, visible: true });
  };

  return (
    <ColleagueDrawerContext.Provider value={{ renderDrawer, toggleDrawer }}>
      <ColleagueDrawer employeeId={drawerState.employeeId} visible={drawerState.visible} toggleDrawer={toggleDrawer} />
      {children}
    </ColleagueDrawerContext.Provider>
  );
};

export { ColleagueDrawerProvider, ColleagueDrawerContext };
