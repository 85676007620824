import React from 'react';
import styles from './QuickActionBtn.module.scss';

type QuickActionBtnProps = {
  icon: React.ReactNode;
  name: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const QuickActionBtn = ({ icon, name, onClick, ...rest }: QuickActionBtnProps) => {
  const Icon = icon as any;

  return (
    <li className={`no-list-styles ${styles.listItem}`}>
      <button {...rest} type="button" className={`no-button-styles ${styles.actionBtn}`} onClick={onClick}>
        <Icon className={`icon-medium ${styles.icon}`} data-testid="action-icon" />
        <p className={`main-body-text title-dark ${styles.actionName}`}>{name}</p>
      </button>
    </li>
  );
};

export default QuickActionBtn;
