import React from 'react';
import PropTypes from 'prop-types';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import EmptyStateBellImg from '@assets/svgs/empty-notifications-bell.svg';
import styles from './NotificationsListEmptyState.module.scss';

type NotificationsListEmptyStateType = {
  className?: string;
};

const NotificationsListEmptyState: React.FC<NotificationsListEmptyStateType> = ({ className = '', ...props }) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  return (
    <div {...props} className={`${styles.noMore} ${className}`}>
      <EmptyStateBellImg className={styles.noMoreImg} />
      <h5 className={`h5 subheading ${styles.noMoreText}`}>{translate('notifications.thats_all')}</h5>
    </div>
  );
};

NotificationsListEmptyState.propTypes = {
  className: PropTypes.string,
};

NotificationsListEmptyState.defaultProps = {
  className: '',
};

export default NotificationsListEmptyState;
