import { useEffect, useState } from 'react';

function useLocalStorage<T>(key: string, fallbackValue: T) {
  const [value, setValue] = useState<T | null>(null);

  const setLocalStorageValue = (v: T) => {
    setValue(v);
    localStorage.setItem(key, JSON.stringify(v));
  };

  useEffect(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      setValue(JSON.parse(storedValue));
    } else {
      setValue(fallbackValue);
    }
  }, []);

  return [value, setLocalStorageValue] as const;
}

export default useLocalStorage;
