import React from 'react';
import PropTypes from 'prop-types';
import { useStepperContext } from './StepperContext';

interface StepProps {
  step?: number;
  next?: Function;
  back?: Function;
  goToStep?: Function;
}

const Step = ({
  step,
  stepRender,
}: {
  step?: number;
  stepRender: (
    step: number,
    next: Function,
    back: Function,
    reset: Function,
    goToStep: Function,
  ) => React.ReactElement<StepProps>;
}): JSX.Element => {
  const { step: currentStep, next, back, reset, goToStep } = useStepperContext();

  if (step !== currentStep) return <></>;

  return <>{stepRender(currentStep, next, back, reset, goToStep)}</>;
};

Step.propTypes = {
  step: PropTypes.number,
};

Step.defaultProps = {
  step: undefined,
};

export default Step;
