import { hrmsInstance, hrmsBackEndUrl } from '@utils/http-client';
import {
  EmployeeChangePasswordDto,
  EmployeeForgotPasswordDto,
  EmployeeLoginDto,
  EmployeeValidateTokenDto,
  ValidationTokenModel,
} from '@models/user';
import { UserAuthType } from '@models/user/auth';
import requestRecaptchaToken from '@services/recaptcha/recaptcha-service';
import RecaptchaActionsEnum from '@constants/recaptcha-actions.enum';
import {
  AccountSetupDto,
  ChangePasswordDto,
  DomainAvailabilityDto,
  ForgotPasswordDto,
  LoginDto,
  SignUpDto,
  ValidateTokenDto,
  ValidateTokenResponseDto,
} from './type';

const api = `${hrmsBackEndUrl}/creds`;
const usersApi = `${hrmsBackEndUrl}/users/creds`;

export const userLogin = async (body: EmployeeLoginDto): Promise<EmployeeLoginDto> => {
  const recaptchaToken = await requestRecaptchaToken(RecaptchaActionsEnum.LOGIN);
  return hrmsInstance.post(`${usersApi}/login`, { ...body, recaptchaToken });
};

export const userForgotPassword = async (body: EmployeeForgotPasswordDto): Promise<EmployeeForgotPasswordDto> => {
  const recaptchaToken = await requestRecaptchaToken(RecaptchaActionsEnum.FORGOT_PASS);
  return hrmsInstance.post(`${usersApi}/forgot-password`, { ...body, recaptchaToken });
};

export const userChangePassword = async (body: EmployeeChangePasswordDto): Promise<EmployeeChangePasswordDto> => {
  const recaptchaToken = await requestRecaptchaToken(RecaptchaActionsEnum.CHANGE_PASS);
  return hrmsInstance.post(`${usersApi}/token-change-password`, { ...body, recaptchaToken });
};

export const validateUserToken = (token: EmployeeValidateTokenDto): Promise<ValidationTokenModel> => {
  return hrmsInstance.post(`${usersApi}/validate-token`, token);
};

export const userLogout = (): Promise<void> => {
  return hrmsInstance.post(`${usersApi}/user-logout`);
};

// Admin

export const adminAuth = (): Promise<{ admin: UserAuthType }> => {
  return hrmsInstance.get(`${hrmsBackEndUrl}/auth/state`);
};

export const signup = async (body: SignUpDto): Promise<SignUpDto> => {
  const recaptchaToken = await requestRecaptchaToken(RecaptchaActionsEnum.SIGNUP);
  return hrmsInstance.post(`${api}/signup`, { ...body, recaptchaToken });
};

export const setup = (body: AccountSetupDto): Promise<UserAuthType> => {
  return hrmsInstance.post(`${api}/setup`, body);
};

export const login = async (body: LoginDto): Promise<LoginDto> => {
  const recaptchaToken = await requestRecaptchaToken(RecaptchaActionsEnum.LOGIN);
  return hrmsInstance.post(`${api}/login`, { ...body, recaptchaToken });
};

export const logout = (): Promise<void> => {
  return hrmsInstance.post(`${api}/logout`);
};

export const forgotPassword = async (body: ForgotPasswordDto): Promise<ForgotPasswordDto> => {
  const recaptchaToken = await requestRecaptchaToken(RecaptchaActionsEnum.FORGOT_PASS);
  return hrmsInstance.post(`${api}/forgot-password`, { ...body, recaptchaToken });
};

export const changePassword = async (body: ChangePasswordDto): Promise<ChangePasswordDto> => {
  const recaptchaToken = await requestRecaptchaToken(RecaptchaActionsEnum.CHANGE_PASS);
  return hrmsInstance.post(`${api}/token-change-password`, { ...body, recaptchaToken });
};

export const validateToken = (body: ValidateTokenDto): Promise<ValidateTokenResponseDto> => {
  return hrmsInstance.post(`${api}/validate-token`, body);
};

export const checkDomainAvailability = (domain: string): Promise<DomainAvailabilityDto> => {
  return hrmsInstance.get(`${api}/setup/check-domain`, { params: { domain } });
};
