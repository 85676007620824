export type UpdateCompanyInfo = {
  company_name: string;
  timezone: string;
};

export type UpdateAdminDetails = {
  first_name: string;
  last_name: string;
  email: string;
};

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  FREE = 'FREE',
  CANCELED = 'CANCELED',
  SUSPENDED = 'SUSPENDED',
  TRIAL_SUSPENDED = 'TRIAL_SUSPENDED',
}

export type GetTenantBasicInfo = {
  sub_status: SubscriptionStatus;
  active_count: number;
  onboarding_count: number;
  billing_info_set: boolean;
  payment_failure_count: number;
  start_at: string;
  end_at: string;
};
