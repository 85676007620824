/* eslint-disable react/jsx-no-bind */
import React, { useContext, useState } from 'react';
import HomeIcon from '@assets/svgs/home-icon.svg';
import MegaphoneIcon from '@assets/svgs/megaphone-icon.svg';
import BurgerMenuIcon from '@assets/svgs/full-burger-menu.svg';
import CalendarIcon from '@assets/svgs/calendar-icon.svg';
import EmployeeIcon from '@assets/svgs/employee.svg';
import DirectoryIcon from '@assets/svgs/directory-icon.svg';
import EmployeeTeamIcon from '@assets/svgs/employee-team.svg';
import PlusIcon from '@assets/svgs/plus-icon.svg';
import OrgChartIcon from '@assets/svgs/org-chart-icon.svg';
import DocumentIcon from '@assets/svgs/document.svg';
import UserCalendarIcon from '@assets/svgs/time-off-calendar.svg';
import { Divider, Menu } from 'antd';
import { useRouter } from 'next/router';
import RoutesEnum from '@constants/routes.enum';
import UserRequestTimeOff from '@components/employee-dashboard/request-time-off/UserRequestTimeOff';
import UiDrawer from '@components/ui/drawer/UiDrawer';
import { AuthContext } from '@contexts/AuthContext';
import usePath from '@hooks/usePath';
import { ModalContext } from '@contexts/ModalContext';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import PerformanceIcon from '@assets/svgs/performance-icon.svg';
import styles from './BottomBar.module.scss';

const OPEN_SUBMENU = 'openSubMenu';
const REQUEST_TIMEOFF = 'requestTimeOff';

const BottomBar = () => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const { renderModal, setVisible } = useContext(ModalContext);

  const router = useRouter();
  const { appPathPrefix } = usePath();
  const path = router.asPath.replace(appPathPrefix, '');

  const drawerActive = [
    RoutesEnum.EMPLOYEE_PROFILE,
    RoutesEnum.EMPLOYEE_ORG_CHART,
    RoutesEnum.EMPLOYEE_TEAM_CALENDAR,
    RoutesEnum.EMPLOYEE_TEAM_LEAVES,
    RoutesEnum.EMPLOYEE_TEAM_BALANCE,
    RoutesEnum.EMPLOYEE_COLLEAGUES,
    RoutesEnum.EMPLOYEE_TEAM_MEMBERS,
    RoutesEnum.EMPLOYEE_ORG_CHART,
    RoutesEnum.EMPLOYEE_COMPANY_DOCUMENTS,
    RoutesEnum.EMPLOYEE_PERFORMANCE_GOALS,
  ].includes(path as RoutesEnum);

  const { user } = useContext(AuthContext);

  const toggleDrawer = () => {
    setOpenSubmenu(!openSubmenu);
  };

  function goTo(info: any) {
    setOpenSubmenu(false);
    if (info.key === REQUEST_TIMEOFF) {
      renderModal(<UserRequestTimeOff setVisible={setVisible} />);
    } else if (info.key === OPEN_SUBMENU) {
      toggleDrawer();
    } else {
      router.push(`${appPathPrefix}${info.key.toString()}`);
    }
  }

  const menu = (
    <Menu
      data-testid="bottom-bar"
      mode="horizontal"
      selectedKeys={[path]}
      className="bottom-bar-container"
      onClick={goTo}
    >
      <Menu.Item key={RoutesEnum.EMPLOYEE_HOME} icon={<HomeIcon />} data-testid="bottom-home-route">
        {translate('bottom.home')}
      </Menu.Item>
      <Menu.Item key={RoutesEnum.EMPLOYEE_ANNOUNCEMENTS} icon={<MegaphoneIcon />} data-testid="bottom-news-route">
        {translate('bottom.news')}
      </Menu.Item>
      <Menu.Item key="requestTimeOff" data-testid="request-time-off-button">
        <div className={styles.fabButton}>
          <PlusIcon />
        </div>
      </Menu.Item>
      <Menu.Item key={RoutesEnum.EMPLOYEE_TIME_OFF} icon={<CalendarIcon />} data-testid="bottom-timeoff-route">
        {translate('bottom.time_off')}
      </Menu.Item>
      <Menu.Item
        key={OPEN_SUBMENU}
        icon={<BurgerMenuIcon />}
        data-testid="bottom-more-route"
        className={drawerActive ? 'ant-menu-item-selected' : ''}
      >
        {translate('bottom.more')}
      </Menu.Item>
    </Menu>
  );

  const subMenu = (
    <Menu
      mode="inline"
      selectedKeys={[path, `/${path.split('/')[1]}`]}
      className="employee-submenu-container"
      defaultOpenKeys={['1', '2']}
      onClick={goTo}
    >
      <Menu.Item
        icon={<EmployeeIcon />}
        key={RoutesEnum.EMPLOYEE_PROFILE}
        className="small-body-text"
        data-testid="employee-profile-route"
      >
        {translate('bottom.profile')}
      </Menu.Item>
      <Divider className={styles.divider} />
      {user?.has_team && (
        <>
          <Menu.Item
            key={RoutesEnum.EMPLOYEE_TEAM_CALENDAR}
            className="small-body-text"
            data-testid="team-calendar-route"
            icon={<UserCalendarIcon />}
          >
            {translate('bottom.team_calendar')}
          </Menu.Item>
          <Menu.SubMenu
            key="1"
            icon={<EmployeeTeamIcon />}
            className={styles.submenuItem}
            title={translate('bottom.my_team')}
            data-testid="employees-submenu"
          >
            <Menu.Item
              key={RoutesEnum.EMPLOYEE_TEAM_MEMBERS}
              className="small-body-text"
              data-testid="team-members-route"
            >
              {translate('bottom.members')}
            </Menu.Item>
            <Menu.Item
              key={RoutesEnum.EMPLOYEE_TEAM_LEAVES}
              className="small-body-text"
              data-testid="team-time-off-route"
            >
              {translate('bottom.team_time_off')}
            </Menu.Item>
            <Menu.Item
              key={RoutesEnum.EMPLOYEE_TEAM_BALANCE}
              className="small-body-text"
              data-testid="team-balance-route"
            >
              {translate('bottom.team_balance')}
            </Menu.Item>
          </Menu.SubMenu>
          <Divider className={styles.divider} />
        </>
      )}
      {!user?.has_team && (
        <>
          <Menu.Item
            icon={<UserCalendarIcon />}
            key={RoutesEnum.EMPLOYEE_CALENDAR}
            className="small-body-text"
            data-testid="calendar-route"
          >
            {translate('bottom.calendar')}
          </Menu.Item>
          <Divider className={styles.divider} />
        </>
      )}
      <Menu.SubMenu
        key="2"
        icon={<PerformanceIcon />}
        className={styles.submenuItem}
        title={translate('menu.performance')}
        data-testid="performance-submenu"
      >
        <Menu.Item
          key={RoutesEnum.EMPLOYEE_PERFORMANCE_GOALS}
          className="small-body-text"
          data-testid="performance-goals-route"
        >
          {translate('menu.my_goals')}
        </Menu.Item>
      </Menu.SubMenu>
      <Divider className={styles.divider} />
      <Menu.Item
        icon={<OrgChartIcon />}
        key={RoutesEnum.EMPLOYEE_ORG_CHART}
        className="small-body-text"
        data-testid="org-chart-route"
      >
        {translate('bottom.org_chart')}
      </Menu.Item>
      <Menu.Item
        icon={<DocumentIcon />}
        key={RoutesEnum.EMPLOYEE_COMPANY_DOCUMENTS}
        className="small-body-text"
        data-testid="company-documents-route"
      >
        {translate('bottom.company_documents')}
      </Menu.Item>
      <Divider className={styles.divider} />
      <Menu.Item
        icon={<DirectoryIcon />}
        key={RoutesEnum.EMPLOYEE_COLLEAGUES}
        className="small-body-text"
        data-testid="directory-route"
      >
        {translate('bottom.colleagues')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <section className={styles.compWrap}>
        {menu}
        <UiDrawer
          open={openSubmenu}
          bodyStyle={{ backgroundColor: 'white', padding: '12px 20px' }}
          closable={false}
          placement="bottom"
          onClose={toggleDrawer}
          height="auto"
          zIndex={5}
          rootClassName={`${styles.drawer} ${openSubmenu ? styles.openSubmenu : ''}`}
          data-testid="mobile-drawer"
        >
          <div>{subMenu}</div>
        </UiDrawer>
      </section>
    </>
  );
};

export default BottomBar;
