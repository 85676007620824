const escapeRegex = (str: string) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');

export function matchRule(str: string, rule: string): boolean {
  return new RegExp(`^${rule.split('*').map(escapeRegex).join('.*')}$`).test(str);
}

export function matchAnyRule(str: string, ...rules: string[]): boolean {
  const conditions = rules.map((rule) => new RegExp(`^${rule.split('*').map(escapeRegex).join('.*')}$`));
  return conditions.some((regex) => regex.test(str));
}
