/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';

export const startedYear = dayjs().year(2022);
export const currentYear = dayjs().year();

const yearsOptions = () => {
  const years = Array.from(new Array(5), (v, idx) => startedYear.year() + idx);
  return years;
};

export default yearsOptions;
