/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { Skeleton } from 'antd';
import { NotificationType } from './type';
import NotificationCard from './notification-card/NotificationCard';
import styles from './NotificationsList.module.scss';
import NotificationsListEmptyState from './empty-state/NotificationsListEmptyState';

type NotificationsListType = {
  notifications?: NotificationType[];
  loading: boolean;
  reachedEnd: boolean;
  className?: string;
};

const NotificationsList: React.FC<NotificationsListType> = ({ notifications, loading, reachedEnd, className = '' }) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const { todaysNotifications, olderNotifications } = useMemo(
    () =>
      notifications?.reduce<{
        todaysNotifications: NotificationType[];
        olderNotifications: NotificationType[];
      }>(
        (acc, notification) => {
          if (dayjs().isSame(dayjs(notification.created_at), 'day')) {
            acc.todaysNotifications.push(notification);
          } else {
            acc.olderNotifications.push(notification);
          }
          return acc;
        },
        { todaysNotifications: [], olderNotifications: [] },
      ),
    [notifications],
  ) || { todaysNotifications: [], olderNotifications: [] };

  if (!todaysNotifications && !olderNotifications) return <></>;

  return (
    <>
      <div className={className}>
        {!!todaysNotifications.length && (
          <section className={styles.todaysNotifications}>
            <p className={`subheading uppercase ${styles.label}`}>{translate('notifications.today')}</p>
            {todaysNotifications.map((notification: NotificationType) => (
              <NotificationCard
                cardIcon={!notification.seen && <span className={styles.unreadBagde} data-testid="seen-badge" />}
                key={notification.id}
                notification={notification}
              />
            ))}
          </section>
        )}
        {!!olderNotifications.length && (
          <section className={styles.olderNotifications}>
            {!!todaysNotifications.length && (
              <p className={`subheading uppercase ${styles.label}`}>{translate('notifications.older')}</p>
            )}
            {olderNotifications.map((notification: NotificationType) => (
              <NotificationCard
                cardIcon={!notification.seen && <span className={styles.unreadBagde} data-testid="seen-badge" />}
                key={notification.id}
                notification={notification}
              />
            ))}
          </section>
        )}
        {!loading && reachedEnd && <NotificationsListEmptyState data-testid="empty-state" />}
        {loading && (
          <div className={styles.skeleton} data-testid="skeleton">
            <Skeleton active avatar paragraph={{ rows: 1 }} loading={loading} />
            <Skeleton active avatar paragraph={{ rows: 1 }} loading={loading} />
          </div>
        )}
      </div>
    </>
  );
};

NotificationsList.propTypes = {
  notifications: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  reachedEnd: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

NotificationsList.defaultProps = {
  className: '',
  notifications: undefined,
};

export default NotificationsList;
