import React, { useCallback, useState } from 'react';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import useDebounce from '@hooks/useDebounce';
import useFetchData from '@hooks/useFetchData';
import { AutocompleteItem } from '@models/position';
import { reportToAutocomplete } from '@services/position/position-http.service';
import { Select } from 'antd';
import { SelectValue, SelectProps } from 'antd/lib/select';
import EmployeeStatus from '@constants/employer-status/employer-status.enum';

type CustomSelectProps = Omit<SelectProps<SelectValue>, 'defaultValue' | 'size'> &
  React.RefAttributes<any> & { defaultValue?: string; reportsToStatus?: EmployeeStatus };

const { Option } = Select;

const ReportingLinesSelect = ({ defaultValue, value, onChange, reportsToStatus, ...rest }: CustomSelectProps) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const [reportingLineSearch, setReportingLineSearch] = useState<string>(defaultValue || '');
  const debouncedReportingLineSearch = useDebounce<string>(reportingLineSearch, 600);

  const reportsToFetcher = useCallback(async () => {
    if (debouncedReportingLineSearch.length < 3 || debouncedReportingLineSearch.length > 60) return [];
    const reportingLines = await reportToAutocomplete(debouncedReportingLineSearch);
    return reportingLines;
  }, [debouncedReportingLineSearch]);

  const { data: reportingLines } = useFetchData<Array<AutocompleteItem>>(reportsToFetcher);

  return (
    <Select
      {...rest}
      allowClear
      showSearch
      onChange={onChange}
      value={reportingLines?.length && reportsToStatus !== EmployeeStatus.inactive ? value : defaultValue}
      placeholder={!rest.disabled && translate('reports_to_select.give_report_position_title')}
      notFoundContent={
        reportingLineSearch?.length > 2
          ? translate('reports_to_select.no_match')
          : translate('reports_to_select.type_at_least_3_letters')
      }
      onSearch={(term) => setReportingLineSearch(term)}
      filterOption={(input, option) => String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {reportingLines?.map((position) => (
        <Option key={position.id} value={position.id}>
          {position.option_name}
        </Option>
      ))}
    </Select>
  );
};

export default ReportingLinesSelect;
