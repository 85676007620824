/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import type { AppContext, AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { AuthProvider } from '@contexts/AuthContext';
import { UserAuthType, UserTypeEnum } from '@models/user/auth';
import { hrmsBackEndUrl } from '@utils/http-client';
import { NextComponentType } from 'next';
import { Source_Sans_3 } from 'next/font/google';
import localFont from 'next/font/local';
import * as Sentry from '@sentry/nextjs';
import App from 'next/app';
import LayoutWrapper from '@components/layout/LayoutWrapper';
import RoutesEnum from '@constants/routes.enum';
import { ModalProvider } from '@contexts/ModalContext';
import { ADMIN_URL_REGEX, EMPLOYEE_URL_REGEX } from '@utils/regex';
import { AppProvider } from '@contexts/AppContext';
import ThirdParty from '@utils/third-party/ThirdParty';
import { SubscriptionStatus } from '@services/tenants/type';
import { ConfigProvider } from 'antd';
import { matchRule } from '@utils/match-string';
import Head from 'next/head';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import configTheme from '../styles/antd-config-theme';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const sourceSansPro = Source_Sans_3({
  subsets: ['latin'],
  weight: ['400', '700', '600'],
  preload: true,
  display: 'swap',
});

const ceraPro = localFont({
  src: [
    { path: '../../public/fonts/Cera-Pro-Regular.otf', weight: '400' },
    { path: '../../public/fonts/Cera-Pro-Bold.otf', weight: '700' },
  ],
  preload: true,
  display: 'swap',
});

require('../styles/global.scss');

interface AppPageProps extends AppProps {
  error: any;
  Component: NextComponentType & { authType: UserTypeEnum };
}

const MyApp = (appProps: AppProps) => {
  const router = useRouter();
  const { Component, pageProps, error } = appProps as AppPageProps;
  const { userProfile } = pageProps as { userProfile: UserAuthType };

  const setUpSentryUser = () => {
    if (userProfile?.id) {
      Sentry.setUser({ id: userProfile.id, email: userProfile.email });
    }
  };

  useEffect(() => {
    setUpSentryUser();
    if (error) router.push('/custom500', '/');
  }, []);

  return (
    <>
      {/* Assign font families to root element */}
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        :root {
          --source-sans-pro: ${sourceSansPro.style.fontFamily};
          --cera-pro: ${ceraPro.style.fontFamily};
        }
      `}</style>
      <ThirdParty userProfile={userProfile} />
      <Head>
        <title>k.people</title>
      </Head>
      <AuthProvider userProfile={userProfile}>
        <ConfigProvider theme={configTheme}>
          <AppProvider>
            <ModalProvider>
              <LayoutWrapper {...pageProps}>
                <Component {...pageProps} />
              </LayoutWrapper>
            </ModalProvider>
          </AppProvider>
        </ConfigProvider>
      </AuthProvider>
    </>
  );
};

MyApp.getInitialProps = async (
  appContext: AppContext & {
    Component: NextComponentType & {
      authType: UserTypeEnum;
      skipAuth: boolean;
      onboardingPage: boolean;
      accountSetupPage: boolean;
      setPasswordPage: boolean;
      userAuthPage: boolean;
      homePage: boolean;
      authPage: boolean;
    };
  },
) => {
  const { req, res, query, asPath } = appContext.ctx;
  const { skipAuth, onboardingPage, accountSetupPage, setPasswordPage, userAuthPage, homePage, authPage } =
    appContext.Component;
  /**
   *  Determine if it the first time the app loads.
   *  Navigating internally after the first load always
   *  results in a url with the prefix -> /_next/data
   */
  const firstLoad = !req?.url?.startsWith('/_next/data');

  const cookie = req?.headers.cookie;

  if (firstLoad && !skipAuth) {
    let domain: string = '';
    const requestUrl = req?.url!;
    let employeeCreds: UserAuthType | null = null;
    let adminCreds: UserAuthType | null = null;

    const redirectTo = (url: string) => {
      res?.writeHead(307, {
        Location: url,
      });
      res?.end();
      return {};
    };

    if (appContext.router.pathname === RoutesEnum.OLD_EMPLOYEE_LOGIN) return redirectTo(RoutesEnum.LOGIN);

    const loginPath = `${RoutesEnum.LOGIN}${
      asPath === RoutesEnum.OLD_EMPLOYEE_LOGIN ? '' : `?from=${encodeURIComponent(asPath!)}`
    }`;

    if (cookie) {
      const adminAuth = await fetch(`${hrmsBackEndUrl}/auth/state`, {
        headers: {
          'Content-Type': 'application/json',
          cookie,
        },
      });
      const { admin }: { admin: UserAuthType } = await adminAuth.json();
      adminCreds = admin && { ...admin, type: UserTypeEnum.EMPLOYER };

      const employeeAuth = await fetch(`${hrmsBackEndUrl}/users/auth/state`, {
        headers: {
          'Content-Type': 'application/json',
          cookie,
        },
      });
      const { employee }: { employee: UserAuthType } = await employeeAuth.json();
      employeeCreds = employee && { ...employee, type: UserTypeEnum.EMPLOYEE };
    }

    const pageAuthType = (() => {
      const employeeMatch = requestUrl.match(EMPLOYEE_URL_REGEX);
      if (employeeMatch) {
        [, domain] = employeeMatch;
        return UserTypeEnum.EMPLOYEE;
      }
      const adminMatch = requestUrl.match(ADMIN_URL_REGEX);
      if (adminMatch) {
        [, domain] = adminMatch;
        return UserTypeEnum.EMPLOYER;
      }
      return undefined;
    })();

    if (pageAuthType !== UserTypeEnum.EMPLOYEE && pageAuthType !== UserTypeEnum.EMPLOYER) {
      /**
       *  Redirect to setup if the account is not setup yet.
       *  Moreover avoid redirect if user is already in /setup
       */
      if (adminCreds) {
        if (!adminCreds.has_tenant && !accountSetupPage) {
          return redirectTo(RoutesEnum.ADMIN_ACCOUNT_SETUP);
        }
        if (!adminCreds.has_tenant && accountSetupPage) {
          return {
            pageProps: {
              userProfile: adminCreds,
            },
          };
        }
        /** Redirect to admin home page if already logged in. */
        if (adminCreds.has_tenant) {
          return redirectTo(`/client/${adminCreds.domain}${RoutesEnum.AMDIN_HOME}`);
        }
      }
      /** Redirect to employee home page if already logged in. */
      if (employeeCreds) {
        if (accountSetupPage) return redirectTo(RoutesEnum.LOGIN);
        if (employeeCreds?.onboarding_step && employeeCreds?.onboarding_step < 6 && !onboardingPage) {
          return redirectTo(`/client/${employeeCreds.domain}${RoutesEnum.EMPLOYEE_ONBOARDING}`);
        }
        /* if (authPage) return {}; */
        return redirectTo(`/client/${employeeCreds.domain}${RoutesEnum.EMPLOYEE_HOME}`);
      }
      /** Non logged in user accessing set password page */
      if (setPasswordPage) {
        if (!query.token) {
          return redirectTo(loginPath);
        }
      }
      /** Non logged in user accessing auth pages */
      if (authPage && !accountSetupPage) return {};
      /** Non logged in user accessing any other page */
      return redirectTo(loginPath);
    }
    if (pageAuthType === UserTypeEnum.EMPLOYER) {
      const appProps = await App.getInitialProps(appContext);
      if (!adminCreds) {
        return redirectTo(loginPath);
      }
      if (!adminCreds.domain) {
        return redirectTo(RoutesEnum.ADMIN_ACCOUNT_SETUP);
      }
      if (adminCreds.domain !== domain) {
        return redirectTo(`/client/${adminCreds.domain}${RoutesEnum.AMDIN_HOME}?companyMatch=false`);
      }
      /** In case account is suspended redirect to account settings if not already there */
      const isAccountSettingsPage = matchRule(requestUrl, '*/admin/settings/account*');
      if (adminCreds.sub_status === SubscriptionStatus.SUSPENDED) {
        if (!isAccountSettingsPage) {
          return redirectTo(`/client/${adminCreds.domain}${RoutesEnum.ADMIN_SETTING_ACCOUNT}`);
        }
      }
      /** If user is anywhere else than the home page while on trial & trial period has ended redirect to home page */
      if (!homePage && !isAccountSettingsPage && adminCreds?.sub_status === SubscriptionStatus.TRIAL_SUSPENDED) {
        return redirectTo(`/client/${adminCreds.domain}${RoutesEnum.AMDIN_HOME}`);
      }
      return {
        pageProps: {
          ...appProps.pageProps,
          userProfile: adminCreds,
        },
      };
    }
    if (pageAuthType === UserTypeEnum.EMPLOYEE) {
      const appProps = await App.getInitialProps(appContext);
      if (!userAuthPage) {
        if (!employeeCreds) {
          return redirectTo(loginPath);
        }
        if (employeeCreds.domain !== domain) {
          return redirectTo(`/client/${employeeCreds.domain}${RoutesEnum.EMPLOYEE_HOME}?companyMatch=false`);
        }
        if (employeeCreds?.onboarding_step && employeeCreds?.onboarding_step < 6 && !onboardingPage) {
          return redirectTo(`/client/${employeeCreds.domain}${RoutesEnum.EMPLOYEE_ONBOARDING}`);
        }
        if ((!employeeCreds?.onboarding_step || employeeCreds?.onboarding_step === 6) && onboardingPage) {
          return redirectTo(`/client/${employeeCreds.domain}${RoutesEnum.EMPLOYEE_HOME}`);
        }
      }
      if (userAuthPage && employeeCreds && employeeCreds.domain === domain) {
        return redirectTo(`/client/${domain}${RoutesEnum.EMPLOYEE_HOME}`);
      }
      if (setPasswordPage) {
        if (!query.token) {
          return redirectTo(loginPath);
        }
      }
      return {
        pageProps: {
          ...appProps.pageProps,
          userProfile: employeeCreds,
        },
      };
    }
  }
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default appWithTranslation(MyApp);
