import React, { useEffect, useState } from 'react';
import RocketIcon from '@assets/svgs/rocket.svg';
import classNameCompose from '@utils/classname-compose';
import RoutesEnum from '@constants/routes.enum';
import { useRouter } from 'next/router';
import usePath from '@hooks/usePath';
import { Tooltip } from 'antd';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import useAccountSuspended from '@hooks/useAccountSuspended';
import styles from './GettingStartedMenu.module.scss';

const GettingStartedMenu = () => {
  const [open, setOpen] = useState(false);
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const accountSuspended = useAccountSuspended();

  const router = useRouter();
  const { getPathWithPrefix, appPathPrefix } = usePath();
  const path = router.asPath.replace(appPathPrefix, '');

  const goToGettingStartedPage = () => {
    if (accountSuspended) return;
    router.push(getPathWithPrefix(RoutesEnum.ADMIN_GETTING_STARTED));
  };

  useEffect(() => {
    if (path === RoutesEnum.ADMIN_GETTING_STARTED) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [path]);

  return (
    <>
      <Tooltip placement="topRight" mouseEnterDelay={0.3} title={translate('menuOptions.getting_started')}>
        <button
          type="button"
          className={classNameCompose(styles.compWrap, open ? styles.open : '')}
          onClick={goToGettingStartedPage}
          data-testid="getting-started-menu"
        >
          <RocketIcon className={styles.rocketIcon} />
        </button>
      </Tooltip>
    </>
  );
};

export default GettingStartedMenu;
