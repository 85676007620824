import React, { useContext, useMemo } from 'react';
import { NotificationType, UserNotification, UserNotificationTypes } from '@components/notfications-list/type';
import { Trans } from 'next-i18next';
import Avatar from '@components/ka-ui/avatar/Avatar';
import RoutesEnum from '@constants/routes.enum';
import NotificationIcon from '@components/ka-ui/notification-icon/NotificationIcon';
import InfoIcon from '@assets/svgs/info-tooltip-icon.svg';
import dayjs from 'dayjs';
import dateFormat from '@constants/date-format';
import CheersIcon from '@assets/svgs/cheers.svg';
import GiftIcon from '@assets/svgs/gift-icon.svg';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import { markUserNotificationSeen } from '@services/notifications/notification-http.service';
import { useRouter } from 'next/router';
import usePath from '@hooks/usePath';
import MegaphoneIcon from '@assets/svgs/megaphone-icon.svg';
import DocumentIcon from '@assets/svgs/document.svg';
import GoalIcon from '@assets/svgs/goal.svg';
import mailbox from '@services/Mailbox';
import LeaveStatusTabs from '@constants/leaves/leave-status/leave-status.enum';
import { ColleagueDrawerContext } from '@contexts/ColleagueDrawerContext';

const useUserNotifications = (data?: UserNotification[]) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');

  const router = useRouter();
  const { getPathWithPrefix } = usePath();

  const { renderDrawer } = useContext(ColleagueDrawerContext);

  const markAsSeen = (notification: UserNotification) => {
    if (!notification.seen) {
      markUserNotificationSeen(notification.id);
    }
  };

  const redirectTo = (path: string) => {
    router.push(getPathWithPrefix(path));
  };

  const notifications = useMemo(
    () =>
      data &&
      data.reduce<NotificationType[]>((acc, notification: UserNotification) => {
        switch (notification.type) {
          case UserNotificationTypes.TIME_OFF_REQUEST:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="user_notifications.time_off_request"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_TEAM_LEAVES}?approveId=${notification.payload.request_id}`);
              },
            });
            break;
          case UserNotificationTypes.NEW_ANNOUNCEMENT:
            acc.push({
              id: notification.id,
              content: <strong>{notification.payload.title}</strong>,
              created_at: notification.created_at,
              img: <NotificationIcon icon={MegaphoneIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(RoutesEnum.EMPLOYEE_ANNOUNCEMENTS);
              },
            });
            break;
          case UserNotificationTypes.APPROVED_TIME_OFF_REQUEST:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.time_off_approved">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={InfoIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(RoutesEnum.EMPLOYEE_TIME_OFF);
              },
            });
            break;
          case UserNotificationTypes.ADMIN_TIME_OFF_REQUEST_ON_BEHALF:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.admin_time_off_on_behalf">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={InfoIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(RoutesEnum.EMPLOYEE_TIME_OFF);
              },
            });
            break;
          case UserNotificationTypes.CHANGED_REPORTING_MANAGER:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="user_notifications.new_manager"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                renderDrawer(notification.payload.employee_id);
              },
            });
            break;
          case UserNotificationTypes.CANCELED_TIME_OFF_REQUEST:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="user_notifications.employee_canceled_time_off"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                mailbox.set('TEAM_LEAVES_DEFAULT_TAB', LeaveStatusTabs.REVIEWED);
                redirectTo(RoutesEnum.EMPLOYEE_TEAM_LEAVES);
              },
            });
            break;
          case UserNotificationTypes.CANCELATION_REQUEST_APPROVED:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="user_notifications.approved_cancellation_request"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                mailbox.set('TEAM_LEAVES_DEFAULT_TAB', LeaveStatusTabs.REVIEWED);
                redirectTo(RoutesEnum.EMPLOYEE_TEAM_LEAVES);
              },
            });
            break;
          case UserNotificationTypes.MY_TIME_OFF_CANCELED:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.time_off_canceled">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={InfoIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(RoutesEnum.EMPLOYEE_TIME_OFF);
              },
            });
            break;
          case UserNotificationTypes.MY_TIME_OFF_CANCELATION_REJECTED:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.time_off_cancelation_rejected">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={InfoIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(RoutesEnum.EMPLOYEE_TIME_OFF);
              },
            });
            break;
          case UserNotificationTypes.NEW_TEAM_MEMBER:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="user_notifications.new_member"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_PROFILE}/${notification.payload.employee_id}`);
              },
            });
            break;
          case UserNotificationTypes.EMPLOYEE_END_HIRE:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="user_notifications.employee_end_hire_today"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_PROFILE}/${notification.payload.employee_id}`);
              },
            });
            break;
          case UserNotificationTypes.EMPLOYEE_END_HIRE_SOON:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="user_notifications.employee_end_hire_soon"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_PROFILE}/${notification.payload.employee_id}`);
              },
            });
            break;
          case UserNotificationTypes.WORK_ANNIVERSARY:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="user_notifications.work_anniversary"
                  values={{
                    fullname: `${notification.payload.first_name} ${notification.payload.last_name}`,
                    startDate: dayjs(notification.payload.start_date).format(dateFormat),
                  }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={CheersIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_PROFILE}/${notification.payload.employee_id}`);
              },
            });
            break;
          case UserNotificationTypes.EMPLOYEE_BIRTHDAY:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="user_notifications.birthday"
                  values={{
                    fullname: `${notification.payload.first_name} ${notification.payload.last_name}`,
                    date: dayjs(notification.payload.date).format(dateFormat),
                  }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={GiftIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_PROFILE}/${notification.payload.employee_id}`);
              },
            });
            break;
          case UserNotificationTypes.EMPLOYEE_HIRING_STARTED:
            acc.push({
              id: notification.id,
              content: (
                <Trans
                  t={translate}
                  i18nKey="user_notifications.hiring_started"
                  values={{ fullname: `${notification.payload.first_name} ${notification.payload.last_name}` }}
                >
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <Avatar image={notification.payload.img_url} status={notification.payload.status} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                renderDrawer(notification.payload.employee_id);
              },
            });
            break;
          case UserNotificationTypes.COMPANY_DOCUMENTS_CREATE:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.company_documents">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={DocumentIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(RoutesEnum.EMPLOYEE_COMPANY_DOCUMENTS);
              },
            });
            break;
          case UserNotificationTypes.MANAGERS_PERFORMANCE_GOALS_SETTING_STARTED:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.manager_set_goals_start">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={GoalIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_TEAM_PERFORMANCE_GOALS}?cycleId=${notification.payload.cycle_id}`);
              },
            });
            break;
          case UserNotificationTypes.MANAGERS_PERFORMANCE_GOALS_SETTING_ENDING_SOON:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.manager_set_goals_end">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={GoalIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_TEAM_PERFORMANCE_GOALS}?cycleId=${notification.payload.cycle_id}`);
              },
            });
            break;
          case UserNotificationTypes.EMPLOYEES_PERFORMANCE_GOALS_SETTING_STARTED:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.goals_setting">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={GoalIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_PERFORMANCE_GOALS}?cycleId=${notification.payload.cycle_id}`);
              },
            });
            break;
          case UserNotificationTypes.EMPLOYEES_PERFORMANCE_GOALS_SETTING_ENDING_SOON:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.employee_goals_setting_end">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={GoalIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_PERFORMANCE_GOALS}?cycleId=${notification.payload.cycle_id}`);
              },
            });
            break;
          case UserNotificationTypes.ADMIN_UPLOADED_DOCUMENT_IN_YOUR_PROFILE:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.admin_uploaded_document">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={DocumentIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(`${RoutesEnum.EMPLOYEE_PROFILE}`);
              },
            });
            break;
          case UserNotificationTypes.PERFORMANCE_GOAL_ASSIGNED:
            acc.push({
              id: notification.id,
              content: (
                <Trans t={translate} i18nKey="user_notifications.new_goal_assigned">
                  -
                </Trans>
              ),
              created_at: notification.created_at,
              img: <NotificationIcon icon={GoalIcon} />,
              seen: notification.seen,
              action: () => {
                markAsSeen(notification);
                redirectTo(
                  `${RoutesEnum.EMPLOYEE_PERFORMANCE_GOALS}?cycleId=${notification.payload.cycle_id}&goalId=${notification.payload.goal_id}`,
                );
              },
            });
            break;
          default:
            return acc;
        }
        return acc;
      }, []),
    [data],
  );
  return notifications;
};

export default useUserNotifications;
