import Avatar from '@components/ka-ui/avatar/Avatar';
import PageSpin from '@components/ka-ui/page-spin/PageSpin';
import useFetchData from '@hooks/useFetchData';
import { getColleagueDetails } from '@services/employee/employee-http.service';
import { GetColleagueDetails } from '@services/employee/type';
import BuildingIcon from '@assets/svgs/building-icon.svg';
import EmployeeIcon from '@assets/svgs/employee.svg';
import React, { useCallback, useContext, useEffect } from 'react';
import InvoiceIcon from '@assets/svgs/invoice-icon.svg';
import MobileIcon from '@assets/svgs/mobile-icon.svg';
import LocationIcon from '@assets/svgs/location.svg';
import CheersIcon from '@assets/svgs/cheers.svg';
import BackIcon from '@assets/svgs/left-arrow.svg';
import UiTag from '@components/ui/tag/UiTag';
import PropTypes from 'prop-types';
import UiButton from '@components/ui/button/UiButton';
import { Divider } from 'antd';
import classNameCompose from '@utils/classname-compose';
import dayjs from 'dayjs';
import dateFormat from '@constants/date-format';
import dateCounter from '@utils/date-utils';
import EmployeeSummary from '@components/ka-ui/employee-summary/EmployeeSummary';
import RoutesEnum from '@constants/routes.enum';
import { useRouter } from 'next/router';
import { UserColleaguesContext } from '@contexts/UserColleaguesContext';
import { AuthContext } from '@contexts/AuthContext';
import usePath from '@hooks/usePath';
import EmployeeStatus from '@constants/employer-status/employer-status.enum';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import useNotification from '@hooks/notification/useNotification';
import StateEnum from '@constants/state.enum';
import eventBus, { CustomEventTypes } from '@services/EventBus';
import { ColleagueDrawerContext } from '@contexts/ColleagueDrawerContext';
import ColleagueInfo from './atoms/ColleagueInfo';
import styles from './ColleagueSummary.module.scss';

type ColleagueSummaryProps = {
  employeeId: string;
  className?: string;
};

const ColleagueSummary: React.FC<ColleagueSummaryProps> = ({ employeeId, className }) => {
  const { useTypedTranslation } = makeTypedTranslation('colleague-summary', 'notification', 'common');
  const { t: translate } = useTypedTranslation('colleague-summary');
  const { t: commonTranslate } = useTypedTranslation('common');
  const { t: notificationTranslate } = useTypedTranslation('notification');
  const { user } = useContext(AuthContext);
  const { lastOfStack, pushEmployee, popEmployee, clearStack } = useContext(UserColleaguesContext);
  const { toggleDrawer } = useContext(ColleagueDrawerContext);

  const router = useRouter();
  const { getPathWithPrefix } = usePath();
  const [warningNotification] = useNotification(StateEnum.WARNING, notificationTranslate('status.warning'));

  const fetcher = useCallback(() => {
    if (!employeeId) return {};
    const employeeDetails = getColleagueDetails(lastOfStack ?? employeeId);
    return employeeDetails;
  }, [employeeId, lastOfStack]);

  const { data, loading } = useFetchData<GetColleagueDetails>(fetcher);

  useEffect(() => {
    return () => clearStack();
  }, []);

  useEffect(() => {
    if (data?.status === EmployeeStatus.deleted) {
      warningNotification(undefined, commonTranslate('already_deleted'));
      eventBus.getInstance().dispatch(CustomEventTypes.DELETED_EMPLOYEE);
    }
  }, [data]);

  if (loading) return <PageSpin />;
  if (data?.status === EmployeeStatus.deleted || (!data && !loading)) return <></>;

  const isDirectReport = data?.reports_to?.id === user?.id;

  return (
    <>
      <div className={classNameCompose(styles.colleagueInfoCardContainer, styles.headerBg, className!)}>
        {lastOfStack && (
          <button
            type="button"
            onClick={() => popEmployee()}
            className={`${styles.backIconWrap} no-button-styles`}
            data-testid="back-btn"
          >
            <BackIcon className={`${styles.backIcon} icon-medium`} />
          </button>
        )}
        <div className={styles.colleagueAvatarWrap}>
          <Avatar image={data?.img_url} status={data?.status!} />
        </div>
        <div className={`h4 ${styles.colleagueName}`}>
          {data?.first_name} {data?.last_name}
        </div>
        <div className={`large-body-text ${styles.colleaguePositionTitle}`}>
          {data?.position_title ?? translate('n/a')}
        </div>
        <UiTag
          className={styles.departmentTag}
          icon={data?.department_title && BuildingIcon}
          type={data?.department_title ? 'info' : 'default'}
        >
          {data?.department_title || 'N/A'}
        </UiTag>
        {isDirectReport && (
          <UiButton
            className={styles.profileButton}
            onClick={() => {
              router.push(`${getPathWithPrefix(RoutesEnum.EMPLOYEE_PROFILE)}/${lastOfStack ?? employeeId}`);
              toggleDrawer();
            }}
            type="default"
            size="small"
            data-testid="profile-redirect-btn"
          >
            <EmployeeIcon />
            {translate('view_profile')}
          </UiButton>
        )}
      </div>
      <Divider className={styles.divider} />
      <h6 className="h6 title-dark">{translate('info')}</h6>
      <div className={styles.colleagueInfoWrap}>
        {data?.email && <ColleagueInfo icon={InvoiceIcon} content={data?.email} actionUrl={`mailto: ${data?.email}`} />}
        {data?.phone_work && (
          <ColleagueInfo icon={MobileIcon} content={data?.phone_work} actionUrl={`tel: ${data?.phone_work}`} />
        )}
        {data?.location_title && <ColleagueInfo icon={LocationIcon} content={data?.location_title} />}
        {data?.start_hire_date && (
          <span className={styles.hireDateContent}>
            <ColleagueInfo icon={CheersIcon} content={dayjs(data?.start_hire_date).format(dateFormat)} />
            <p className={`${styles.hireDateTimespan} large-body-text`}>{dateCounter(data?.start_hire_date)}</p>
          </span>
        )}
      </div>
      {data?.reports_to && (
        <>
          <Divider className={styles.divider} />
          <h6 className="h6 title-dark">{translate('reports_to')}</h6>
          {data?.reports_to.status !== EmployeeStatus.deleted ? (
            <button
              type="button"
              onClick={() => pushEmployee(data?.reports_to.id)}
              className="no-button-styles"
              data-testid="reporter-summary"
            >
              <EmployeeSummary
                data={{
                  fullName: `${data?.reports_to.first_name} ${data?.reports_to.last_name}`,
                  imgUrl: data?.reports_to.img_url,
                  status: data?.reports_to.status!,
                  subheading: data?.reports_to.position_title,
                }}
                inline
              />
            </button>
          ) : (
            <EmployeeSummary
              data={{
                fullName: `${commonTranslate('deleted_employee')}`,
                imgUrl: data?.reports_to.img_url,
                status: data?.reports_to.status!,
              }}
              inline
            />
          )}
        </>
      )}
      {data?.direct_reports && data?.direct_reports.length > 0 && (
        <>
          <Divider className={styles.divider} />
          <h6 className="h6 title-dark">{translate('direct_reports')}</h6>
          <div className={styles.directReports}>
            {data?.direct_reports.map((direct_report) => (
              <button
                key={direct_report.id}
                type="button"
                onClick={() => pushEmployee(direct_report.id)}
                className="no-button-styles"
                data-testid={`${direct_report.id}-reporting-line`}
              >
                <EmployeeSummary
                  data={{
                    fullName: `${direct_report.first_name} ${direct_report.last_name}`,
                    imgUrl: direct_report.img_url,
                    status: direct_report.status!,
                    subheading: direct_report.position_title,
                  }}
                  inline
                />
              </button>
            ))}
          </div>
        </>
      )}
    </>
  );
};

ColleagueSummary.propTypes = {
  employeeId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ColleagueSummary.defaultProps = {
  className: '',
};

export default ColleagueSummary;
